import { useQuery } from "@tanstack/react-query";
import type { AppTicketDto } from "api/types";
import { Button } from "components/Button/Button";
import { ContentTabs } from "components/ContentTabs/ContentTabs";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormField } from "components/Form/FormField";
import { FormStarInput } from "components/Form/FormStarInput";
import { FormTagSelect } from "components/Form/FormTagSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { parseISO } from "date-fns";
import { useBool } from "hooks/useBool";
import { ticketMutations, useTicketQueries } from "queries/tickets";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TicketResidentRateModalProps = ModalBaseProps & {
  ticket: AppTicketDto;
};

const REMARK_MAX_LENGTH = 1000;

export function TicketResidentRateModal({
  ticket,
  isOpened,
  onOpenChange,
}: TicketResidentRateModalProps): React.ReactNode {
  const [isCommentFieldVisible, commentFieldVisibilityHandlers] = useBool();
  const [activeTab, setActiveTab] = useState<"rating" | "noRating">("rating");

  const { t } = useTranslation();
  const form = useForm<TicketResidentRateModalFormValues>({
    defaultValues: {
      rating: 4,
      remark: "",
      feedbackOptions: [],
      closingDate: ticket.closedAt ? parseISO(ticket.closedAt) : new Date(),
    },
  });

  const currRating = useWatch({ control: form.control, name: "rating" });

  const ticketQueries = useTicketQueries();
  const { data: feedbackOptions } = useQuery(ticketQueries.ratingFeedbackOptions());
  const mutationUpdateRating = ticketMutations.useUpdateRating(ticket.id);

  useEffect(() => {
    if (isOpened) {
      commentFieldVisibilityHandlers.setFalse();
    }
  }, [isOpened, commentFieldVisibilityHandlers]);

  async function onSubmit(values: TicketResidentRateModalFormValues) {
    try {
      await mutationUpdateRating.mutateAsync({
        remark: values.remark,
        rating: activeTab === "rating" ? values.rating : undefined,
        closedAt: values.closingDate.toISOString(),
        feedbackOptions: values.feedbackOptions as any[],
      });
    } finally {
      onOpenChange(false);
    }
  }

  const onChangeActiveTab = (tab: "rating" | "noRating") => {
    if (tab === "rating") {
      commentFieldVisibilityHandlers.setFalse();
    }

    setActiveTab(tab);
  };

  const minDate = useMemo(() => {
    const safeMinDate = parseISO(ticket.postedAt);
    // Set to start of day to avoid trouble with comparison of same date
    safeMinDate.setHours(0, 0, 0, 0);

    return safeMinDate;
  }, [ticket]);
  const maxDate = useMemo(() => new Date(), []);

  const relevantFeedbackOptions = useMemo(
    () =>
      feedbackOptions
        ?.find((option) => {
          return option.rating === currRating;
        })
        ?.options.map((option) => ({
          label: option.value,
          value: option.label,
        })),
    [feedbackOptions, currRating],
  );

  const FormFields = (
    <FormContent maxWidth="none">
      {activeTab === "rating" && (
        <>
          <FormField label={t("page.tickets.rate-modal.form.rating")}>
            <div className="flex w-full justify-center pt-5">
              <FormStarInput<TicketResidentRateModalFormValues, "rating"> name="rating" />
            </div>
          </FormField>
          <FormTagSelect<TicketResidentRateModalFormValues, "feedbackOptions">
            name="feedbackOptions"
            items={relevantFeedbackOptions || []}
            isMultiple
            data-testid="rate-modal-feedback-option-field"
          />
        </>
      )}

      {(activeTab === "rating" && isCommentFieldVisible) || activeTab === "noRating" ? (
        <FormField label={t("page.tickets.rate-modal.form.remark")}>
          <FormTextArea<TicketResidentRateModalFormValues>
            name="remark"
            placeholder={t("page.tickets.rate-modal.form.remark.placeholder")}
            rules={{
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: REMARK_MAX_LENGTH,
                }),
                value: REMARK_MAX_LENGTH,
              },
            }}
          />
        </FormField>
      ) : (
        <Button
          onClick={commentFieldVisibilityHandlers.setTrue}
          className="mx-auto"
          styling="ghostPrimary"
          type="button"
          data-testid="rate-modal-toggle-comment-input-btn"
        >
          {t("page.tickets.rate-modal.form.leave-a-comment.btn")}
        </Button>
      )}

      <FormField label={t("page.tickets.rate-modal.form.closing-date")}>
        <FormDateAndTimePicker<TicketResidentRateModalFormValues>
          name="closingDate"
          min={minDate}
          max={maxDate}
          rules={{
            validate: {
              laterThanMin: (date) => {
                if (!date) {
                  return undefined;
                }

                return date < minDate
                  ? t("page.tickets.rate-modal.form.closing-date.error.must-be-after-open")
                  : undefined;
              },
              soonerThanMax: (date) => {
                if (!date) {
                  return undefined;
                }

                return date > maxDate
                  ? t("page.tickets.rate-modal.form.closing-date.error.must-be-in-past")
                  : undefined;
              },
            },
          }}
        />
      </FormField>
    </FormContent>
  );

  return (
    <Modal.Root
      shouldCloseOnEsc={!mutationUpdateRating.isPending}
      shouldCloseOnClickOutside={!mutationUpdateRating.isPending}
      title={t("page.tickets.rate-modal.title")}
      description={ticket.status.type !== "closed" && t("page.tickets.rate-modal.description")}
      size="md"
      {...{ isOpened, onOpenChange }}
    >
      <Form formMethods={form} onSubmit={onSubmit}>
        <div className="w-full rounded text-left" data-testid="ticket-rate-modal">
          {ticket.status.type === "closed" && FormFields}
          {ticket.status.type !== "closed" && (
            <ContentTabs<"rating" | "noRating">
              activeTabId={activeTab}
              onTabChange={onChangeActiveTab}
              tabs={[
                {
                  id: "rating",
                  name: t("page.tickets.rate-modal.form.tabs.with-rating"),
                },
                {
                  id: "noRating",
                  name: t("page.tickets.rate-modal.form.tabs.without-rating"),
                },
              ]}
            >
              <div className="pt-4">{FormFields}</div>
            </ContentTabs>
          )}
        </div>

        <Modal.Actions>
          <Button
            type="button"
            styling="secondary"
            onClick={() => onOpenChange(false)}
            disabled={mutationUpdateRating.isPending}
            data-testid="rate-modal-cancel"
          >
            {t("common.action.cancel")}
          </Button>
          <Button
            type="submit"
            styling="primary"
            isLoading={mutationUpdateRating.isPending}
            data-testid="rate-modal-submit"
          >
            {t("common.action.ok")}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}

interface TicketResidentRateModalFormValues {
  rating: number;
  remark: string;
  feedbackOptions: string[];
  closingDate: Date;
}
