import type {
  AssetBookingDetailDto,
  BookableAssetDeletedResult,
  BookableAssetDetailDto,
  BookableSlotDto,
  CreatedEntityDto,
} from "api/types";
import iconFile06 from "assets/icons/file-06.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { EntityDetailsCard } from "components/EntityDetailsCard/EntityDetailsCard";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Label } from "components/Label/Label";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { dayOfWeekIndex } from "helpers/date";
import { createRequiredStringRule } from "helpers/rules";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookingTimeslotSelector } from "modules/bookings/components/BookingTimeslotSelector";
import { daysOptions } from "modules/bookings/constants";
import { canManageAnyBookableAsset } from "modules/bookings/permissions";
import { useCallback, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import { BookingSuccessModal } from "./components/BookingSuccessModal";

const MAX_LENGTH_REASON = 256;

export interface LayoutProps {
  assetDetails: BookableAssetDetailDto;
  assetSlots: BookableSlotDto[];
  isLoadingSlots: boolean;
  bookingDetails: AssetBookingDetailDto | undefined;
  minDate: Date;
  maxDate: Date;
  defaultValues: DefaultCreateOrEditBookingFormValues;
  onDateChange: (date: Date | null) => void;
  onSubmit: (data: CreateOrEditBookingFormValues) => Promise<CreatedEntityDto>;
  futureBookings: number;
  isSubmitting: boolean;
  onDelete: (id: string) => Promise<BookableAssetDeletedResult>;
}

export interface CreateOrEditBookingFormValues {
  date: Date;
  bookAllDay: boolean;
  startTime: number | null;
  endTime: number | null;
  reason?: string;
  isRegulationAccepted: boolean;
}

type DefaultCreateOrEditBookingFormValues = Omit<CreateOrEditBookingFormValues, "bookAllDay">;

export function Layout({
  assetDetails,
  assetSlots,
  bookingDetails,
  minDate,
  maxDate,
  defaultValues,
  isLoadingSlots,
  onDateChange,
  onSubmit,
  futureBookings,
  isSubmitting,
  onDelete,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();

  const [isSuccessModalOpened, successModalOpenHandler] = useBool(false);
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");
  const form = useForm<CreateOrEditBookingFormValues>({ defaultValues, mode: "onSubmit" });

  const bookAllDay = useWatch({ control: form.control, name: "bookAllDay" });

  useEffect(() => {
    form.register("startTime", {
      validate: {
        required: (currStartTime, currFormValues) => {
          const currEndTime = currFormValues.endTime;

          if (!bookAllDay && !isDefined(currStartTime))
            return t("page.bookings.book-asset.form.booking-timeslot.error");
          if (isDefined(currStartTime) && !isDefined(currEndTime))
            return t("page.bookings.book-asset.form.booking-timeslot.error");

          return undefined;
        },
      },
    });
  }, [form, t, bookAllDay]);

  const isDayDisabled = useCallback(
    (current: Date) => {
      const disabledDays = assetDetails.bookableDays
        .filter((bookableDay) => !bookableDay.enabled)
        .map((bookableDay) => daysOptions.indexOf(bookableDay.day));

      return disabledDays.includes(dayOfWeekIndex(current));
    },
    [assetDetails.bookableDays],
  );

  const handleDateChange = (date: DatePickerValue) => {
    onDateChange(date === "" ? null : date);
    if (date !== "") {
      form.setValue("date", date);
      form.setValue("startTime", null);
      form.setValue("endTime", null);
      form.setValue("bookAllDay", false);
    }
  };

  const handleSubmit = async () => {
    await onSubmit(form.getValues());

    successModalOpenHandler.setTrue();
  };

  const actions: ContextMenuAction[] = [];
  if (assetDetails.canEdit) {
    actions.push({
      text: t("common.action.edit"),
      callback: () => void navigate(routes.bookings.editAsset({ slug, id: assetDetails.id })),
    });
  }
  if (assetDetails.canDelete) {
    actions.push({
      text: t("common.action.delete"),
      callback: () => openDeleteModal(assetDetails.id),
    });
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={assetDetails.name}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.bookings.list-assets.title"),
              to: routes.bookings.list({ slug }),
            },
            {
              name: assetDetails.name,
            },
          ]}
        />
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="50%">
          <EntityDetailsCard
            title={assetDetails.name}
            description={assetDetails.description}
            images={assetDetails.images}
            renderLabels={() =>
              assetDetails.hasAudience &&
              sessionUser.isAdmin && (
                <Label theme="blue">
                  <Icon size={16} name={iconUsersCheck} />
                </Label>
              )
            }
            renderBody={() =>
              assetDetails.audience &&
              assetDetails.audience.length > 0 &&
              sessionUser.isAdmin &&
              hasPermission(canManageAnyBookableAsset) && <AudiencePreview audience={assetDetails.audience} readOnly />
            }
            {...{ actions }}
          />
        </PageGrid.Item>
        <PageGrid.Item size="50%">
          <div className="flex h-fit flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">
            <span className="text-headline3 leading-old-headline4">
              {t("page.bookings.book-asset.section.book-asset.title")}
            </span>
            {maxDate < minDate && <p>{t("page.bookings.book-asset.section.book-asset.not-available")}</p>}
            {maxDate >= minDate && (
              <Form formMethods={form} onSubmit={handleSubmit}>
                <FormContent maxWidth="4xl">
                  <FormField label={t("page.bookings.book-asset.form.booking-date.label")}>
                    <FormDateAndTimePicker<CreateOrEditBookingFormValues, "date">
                      data-testid="date-picker-input"
                      name="date"
                      min={minDate}
                      max={maxDate}
                      disabledDate={isDayDisabled}
                      onChange={handleDateChange}
                      rules={{
                        validate: {
                          laterThanMin: (date) => {
                            if (!date) {
                              return undefined;
                            }

                            return date < minDate
                              ? t("page.bookings.book-asset.form.booking-date.error.must-be-after-date", {
                                  date: formatDate(i18n, "date", minDate),
                                })
                              : undefined;
                          },
                          soonerThanMax: (date) => {
                            if (!date) {
                              return undefined;
                            }

                            if (!maxDate) {
                              return;
                            }

                            return date > maxDate
                              ? t("page.bookings.book-asset.form.booking-date.error.must-be-before-date", {
                                  date: formatDate(i18n, "date", maxDate),
                                })
                              : undefined;
                          },
                        },
                      }}
                    />
                  </FormField>
                  {isLoadingSlots && (
                    <div className="mx-auto mb-4 flex justify-center">
                      <LoadingIcon className="w-6" />
                    </div>
                  )}
                  {!isLoadingSlots && assetSlots.length === 0 && (
                    <span className="text-body">
                      {t("page.bookings.book-asset.form.booking-timeslot.no-slots.available")}
                    </span>
                  )}
                  {!isLoadingSlots && assetSlots.length > 0 && (
                    <>
                      <FormErrorWrapper name="startTime" encircle>
                        <BookingTimeslotSelector
                          timeslot={assetDetails.timeslot}
                          timeslots={assetSlots}
                          booking={bookingDetails}
                          isBookMultipleTimeslotsAllowed={assetDetails.canBookMultipleSlots}
                        />
                      </FormErrorWrapper>
                      {assetDetails.requireBookingReason && (
                        <FormField label={t("page.bookings.book-asset.form.booking-reason.label")} required>
                          <FormInput<CreateOrEditBookingFormValues, "reason">
                            name="reason"
                            placeholder={t("page.bookings.book-asset.form.booking-reason.placeholder")}
                            rules={{
                              validate: {
                                required: createRequiredStringRule(
                                  t,
                                  "page.bookings.book-asset.form.booking-reason.name",
                                ),
                              },
                              maxLength: {
                                message: t("components.form.error.max-length", {
                                  length: MAX_LENGTH_REASON,
                                }),
                                value: MAX_LENGTH_REASON,
                              },
                            }}
                          />
                        </FormField>
                      )}
                      {assetDetails.regulationDocument && (
                        <FormErrorWrapper name="isRegulationAccepted" encircle>
                          <div className="flex flex-col gap-1">
                            <FormCheckbox
                              name="isRegulationAccepted"
                              label={t("page.bookings.book-asset.form.booking-regulations.label")}
                              alignTop
                              rules={{
                                validate: {
                                  needsChecked: (x) => {
                                    if (!x) {
                                      return t("page.bookings.book-asset.form.booking-regulations.label");
                                    }
                                  },
                                },
                              }}
                            />
                            <Button
                              styling="tertiary"
                              onClick={() => window.open(assetDetails.regulationDocument?.url, "_blank")}
                              icon={<Icon name={iconFile06} size={20} />}
                              className="max-w-full"
                            >
                              <span className="truncate">{assetDetails.regulationDocument?.fileName}</span>
                            </Button>
                          </div>
                        </FormErrorWrapper>
                      )}
                      <Button className="self-end" type="submit" isLoading={isSubmitting}>
                        {t("page.bookings.book-asset.book")}
                      </Button>
                    </>
                  )}
                </FormContent>
              </Form>
            )}
          </div>
        </PageGrid.Item>
      </PageGrid.Grid>
      <BookingSuccessModal isOpened={isSuccessModalOpened} onOpenChange={successModalOpenHandler.set} />
      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          futureBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: futureBookings })
        }
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
