import { Navigate } from "react-router";
import { routes } from "routes";

import { AuthenticationState, useAuthenticationState } from "./AuthenticationStateProvider";

interface Props {
  /**
   * This also keeps all the query parameters in the URL. Useful for registration to get the location code.
   */
  preserveQueryParams?: boolean;
  children: React.ReactNode;
}

export function LoggedInGuard({ preserveQueryParams, children }: Props): React.ReactNode {
  const state = useAuthenticationState();

  function getRedirectUrl(rootUrl: string) {
    const currentUrl = location.pathname + location.search + location.hash;

    const newSearchParams = new URLSearchParams(preserveQueryParams ? location.search : "");
    if (!preserveQueryParams && currentUrl && currentUrl !== "/") {
      newSearchParams.set("redirect", currentUrl);
    }

    if (newSearchParams.size === 0) {
      return rootUrl;
    }

    return rootUrl + "?" + newSearchParams.toString();
  }

  if (state === AuthenticationState.Loading) {
    return null;
  }

  if (state === AuthenticationState.NogLoggedIn) {
    return <Navigate to={getRedirectUrl(routes.authentication.login())} />;
  }

  if (state === AuthenticationState.LoggedInButEmailNotVerified) {
    return <Navigate to={routes.authentication.verifyEmail()} />;
  }

  if (state === AuthenticationState.LoggedInButMFANotCompleted) {
    return <Navigate to={getRedirectUrl(routes.authentication.multiFactorAuthentication())} />;
  }

  return children;
}
