import type { VideoDto } from "api/types";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded } from "components/VideoInput/useVideoInput";
import type { QuickReplyRequestUsage } from "queries/files";
import { fileMutations } from "queries/files";
import { useCallback } from "react";

// TODO: Replace usage of this function with `useUploadVideo` once BE strips out `quick-reply` logic
export function useUploadQuickReplyVideo({
  token,
  usage,
  onProgress,
}: {
  token: string;
  usage: QuickReplyRequestUsage;
  onProgress?: ({ name, progress }: { name: string; progress: number }) => void;
}): {
  uploadFormVideo: UploadFormVideo;
  isUploadingVideo: boolean;
} {
  const { mutateAsync: uploadVideo, isPending: isUploadingVideo } = fileMutations.useAddQuickReplyVideo({
    token,
    usage,
    onProgress: onProgress,
  });

  const uploadFormVideo: UploadFormVideo = useCallback(
    async (formVideo) => {
      if (isVideoUploaded(formVideo)) {
        return formVideo;
      }

      if (formVideo.uploadPromise) {
        return formVideo.uploadPromise;
      }

      return await uploadVideo(formVideo);
    },
    [uploadVideo],
  );

  return { uploadFormVideo, isUploadingVideo };
}

type UploadFormVideo = (image: FormVideo) => Promise<VideoDto | undefined>;
