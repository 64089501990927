import { Tooltip } from "components/Tooltip/Tooltip";
import type React from "react";
import { twJoin } from "tailwind-merge";

type ChartLegendItem = {
  label: string;
  tooltip?: string;
  color: string;
};

type ChartLegendProps = {
  items: ChartLegendItem[];
  layout?: "horizontal" | "vertical";
};

export function ChartLegend({ items, layout = "horizontal" }: ChartLegendProps): React.ReactNode {
  return (
    <div
      className={twJoin(
        "flex max-w-full flex-wrap justify-center md:flex-nowrap",
        layout === "horizontal" && "flex-row items-center gap-4",
        layout === "vertical" && "flex-col items-start gap-2",
      )}
    >
      {items.map((item) => (
        <Tooltip key={item.color} tooltip={item.tooltip}>
          <div className={twJoin("flex items-center gap-2")}>
            <div
              className={twJoin("shrink-0 rounded-sm", items.length > 2 ? "size-3" : "size-4")}
              style={{ backgroundColor: item.color }}
            />
            <span
              className={twJoin(
                "whitespace-pre-line text-left text-black",
                items.length > 2 ? "text-overline" : "text-caption",
              )}
            >
              {item.label}
            </span>
          </div>
        </Tooltip>
      ))}
    </div>
  );
}
