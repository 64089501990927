import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { DocumentsFileRequest, DocumentsFolderRequest, DocumentsItemDto } from "api/types";
import type { Breadcrumb } from "components/Breadcrumbs/Breadcrumbs";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { LayoutProps } from "./Layout";

const ROOT_FOLDER_ID = "root";

interface LoaderProps {
  item: DocumentsItemDto | undefined;
  parentFolderId: string;
  onClose: () => void;
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ item, parentFolderId, onClose, children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const [isSubmitting, submittingHandlers] = useBool();
  const [currentFolderId, setCurrentFolderId] = useState(parentFolderId);
  const queryClient = useQueryClient();
  const isRootFolder = currentFolderId === ROOT_FOLDER_ID;
  const api = useApi();
  const showFlashToast = useFlashToast();
  const {
    data: documentsList,
    isFetching: documentsListIsFetching,
    error: documentsListError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, currentFolderId),
    queryFn: () => api.getDocumentsDetailsV1(currentFolderId),
    select: commonAPIDataSelector,
    enabled: !isRootFolder,
  });
  const {
    data: fileDetails,
    isFetching: fileDetailsIsFetching,
    error: fileDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_FILE_DETAILS(projectId, parentFolderId, item?.id || "-"),
    queryFn: () => api.getDocumentsFileDetailsV1(item!.id),
    select: commonAPIDataSelector,
    enabled: item != null && item.type !== "folder",
  });
  const {
    data: folderDetails,
    isFetching: folderDetailsIsFetching,
    error: folderDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_LIST_DETAILS(projectId, item?.id || "-"),
    queryFn: () => api.getDocumentsFolderDetailsV1(item!.id),
    select: commonAPIDataSelector,
    enabled: item != null && item.type === "folder",
  });
  const {
    data: rootList,
    isFetching: rootListIsFetching,
    error: rootListError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_ROOT_LIST(projectId),
    queryFn: () => api.getDocumentsV1(),
    select: commonAPIDataSelector,
    enabled: isRootFolder,
  });
  const { mutate: updateFolder } = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: DocumentsFolderRequest }) =>
      api.putDocumentsFolderV1(id, payload),

    onMutate() {
      submittingHandlers.setTrue();
    },
    onSuccess() {
      showFlashToast({
        title: t("component.documents.move-item-modal.notification.success"),
        type: "success",
      });

      onClose();
    },
    onError() {
      showFlashToast({
        title: t("component.documents.move-item-modal.notification.error"),
        type: "error",
      });
    },
    onSettled() {
      submittingHandlers.setFalse();
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST_ALL(projectId) });
    },
  });
  const { mutate: updateItem } = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: DocumentsFileRequest }) => api.putDocumentsFileV1(id, payload),

    onMutate() {
      submittingHandlers.setTrue();
    },
    onSuccess() {
      showFlashToast({
        title: t("component.documents.move-item-modal.notification.success"),
        type: "success",
      });

      onClose();
    },
    onError() {
      showFlashToast({
        title: t("component.documents.move-item-modal.notification.error"),
        type: "error",
      });
    },
    onSettled() {
      submittingHandlers.setFalse();
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST_ALL(projectId) });
    },
  });

  useEffect(() => {
    setCurrentFolderId(parentFolderId);
  }, [parentFolderId]);

  const error = rootListError || documentsListError || fileDetailsError || folderDetailsError;

  useEffect(() => {
    if (item != null && error) {
      showFlashToast({
        title: t("component.documents.move-item-modal.notification.error"),
        type: "error",
      });

      onClose();
    }
  }, [error, item, onClose, showFlashToast, t]);

  const items =
    (isRootFolder
      ? rootList?.rootFolders.filter((f) => f.canAddItem)
      : documentsList?.items.filter((x) => x.type === "folder")) ?? [];

  const breadcrumbs = [
    {
      name: t("model.document.breadcrumbs.root"),
      id: ROOT_FOLDER_ID,
    },
    ...(documentsList?.ancestors || []),
    ...(!isRootFolder && documentsList
      ? [
          {
            name: documentsList.name,
            id: currentFolderId,
          },
        ]
      : []),
  ]
    .filter((x) => x)
    .map(
      ({ name, id: folderId }) =>
        ({
          callback: () => setCurrentFolderId(folderId),
          name,
        }) satisfies Breadcrumb,
    );

  function close(submit: boolean) {
    if (isSubmitting) {
      return;
    }

    try {
      if (submit) {
        if (item!.type === "folder") {
          updateFolder({
            id: item!.id,
            payload: {
              nameTranslations: folderDetails!.nameTranslations,
              parentFolderId: currentFolderId,
            },
          });
        } else {
          updateItem({
            id: item!.id,
            payload: {
              nameTranslations: fileDetails!.nameTranslations,
              parentFolderId: currentFolderId,
              uploadId: item!.document!.id,
            },
          });
        }
      } else {
        onClose();
      }
    } catch (error) {
      onClose();
      showFlashToast({
        title: t("component.documents.move-item-modal.notification.error"),
        type: "error",
      });
    }
  }

  const isLoading = rootListIsFetching || documentsListIsFetching || folderDetailsIsFetching || fileDetailsIsFetching;

  return (
    <>
      {children({
        isLoading,
        items,
        breadcrumbs,
        canMove: !isRootFolder && currentFolderId !== parentFolderId,
        isSubmitting,
        item,
        onNavigateToFolder: setCurrentFolderId,
        onSubmit: () => close(true),
        onClose: () => close(false),
      })}
    </>
  );
}
