import type { ImageInputProps } from "components/ImageInput/ImageInput";
import { ImageInput } from "components/ImageInput/ImageInput";
import { useCombinedRefs } from "hooks/useCombinedRef";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import type { FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface FormImageInputProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<ImageInputProps, "onChange" | "value"> {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  className?: string;
  "data-testid"?: string;
  initialFileUrl?: string;
  nOfImages?: number;
  theme?: ImageInputProps["theme"];
  texts?: { add: string };
  isAvatarInput?: boolean;
}

export const FormImageInput = forwardRef(function FormImageInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>(
  { "data-testid": dataTestid, name, rules, ...props }: FormImageInputProps<TFormValues, TName>,
  ref: ForwardedRef<HTMLInputElement>,
): React.ReactNode {
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({
    name,
    rules,
    defaultValue: [] as any,
  });

  const combinedRef = useCombinedRefs(field.ref, ref);

  return (
    <FormErrorWrapper<TFormValues> name={name} className={props.className} data-testid={dataTestid}>
      <ImageInput
        {...props}
        {...field}
        ref={combinedRef}
        aria-invalid={error != null}
        value={field.value}
        onChange={field.onChange}
        onBlur={() => {
          field.onBlur();
          props.onBlur?.();
        }}
      />
    </FormErrorWrapper>
  );
}) as <TFormValues extends FieldValues, TName extends FieldPath<TFormValues> = FieldPath<TFormValues>>(
  p: FormImageInputProps<TFormValues, TName> & { ref?: ForwardedRef<HTMLInputElement> },
) => React.ReactNode;
