import iconX from "assets/icons/x.svg";
import { Icon } from "components/Icon/Icon";

export function MediaXButton(props: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  "data-testid"?: string;
  title: string;
}): React.ReactNode {
  return (
    <button className="flex size-3.5 items-center justify-center rounded-full bg-grey-900" type="button" {...props}>
      <Icon name={iconX} size={12} className="text-white" />
    </button>
  );
}
