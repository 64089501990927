import { addressRoutes } from "modules/addresses/routes";
import { adminRoutes } from "modules/admin/routes";
import { adminNotificationsRoutes } from "modules/admin-notifications/routes";
import { analyticsRoutes } from "modules/analytics/routes";
import { authenticationRoutes } from "modules/authentication/routes";
import { automatedSurveyQueueRoutes } from "modules/automated-surveys/routes";
import { bookingsRoutes } from "modules/bookings/routes";
import { buildingRoutes } from "modules/buildings/routes";
import { calendarRoutes } from "modules/calendar/routes";
import { chatsRoutes } from "modules/chats/routes";
import { helpCategoriesRoutes, interestGroupsRoutes } from "modules/community-groups/routes";
import { companyRoutes } from "modules/companies/routes";
import { documentsRoutes } from "modules/documents/routes";
import { eventsRoutes } from "modules/events/routes";
import { fahHistoryRoutes } from "modules/fah-history/routes";
import { homeRoutes } from "modules/home/routes";
import { messagesRoutes } from "modules/messages/routes";
import { onboardingFlowRoutes } from "modules/onboarding-flow/routes";
import { onboardingScreensRoutes } from "modules/onboarding-screens/routes";
import { portfolioRoutes } from "modules/portfolio/routes";
import { projectRoutes } from "modules/project/routes";
import { registrationRoutes } from "modules/registration/routes";
import { reservationsRoutes } from "modules/reservations/routes";
import { roleRoutes } from "modules/roles/routes";
import { servicePartnersRoutes } from "modules/service-partners/routes";
import { surveyRoutes } from "modules/surveys/routes";
import { alertsRoutes } from "modules/system-settings/alerts/routes";
import { automatedSurveysRoutes } from "modules/system-settings/automated-surveys/routes";
import { platformGroupsRoutes } from "modules/system-settings/platform-groups/routes";
import { projectConnectionsRoutes } from "modules/system-settings/project-connections/routes";
import { ticketCategoriesRoutes } from "modules/ticket-categories/routes";
import { ticketStatusesRoutes } from "modules/ticket-statuses/routes";
import { ticketsRoutes } from "modules/tickets/routes";
import { usersRoutes } from "modules/users/routes";
import { compile } from "path-to-regexp";

export const routes = {
  roles: compileProjectRoutes("/roles", roleRoutes),
  interestGroups: compileProjectRoutes("/interest-groups", interestGroupsRoutes),
  helpCategories: compileProjectRoutes("/help-categories", helpCategoriesRoutes),
  documents: compileProjectRoutes("/documents", documentsRoutes),
  servicePartners: compileProjectRoutes("/servicepartners", servicePartnersRoutes),
  events: compileProjectRoutes("/events", eventsRoutes),
  calendar: compileProjectRoutes("/calendar", calendarRoutes),
  tickets: compileProjectRoutes("/tickets", ticketsRoutes),
  ticketCategories: compileProjectRoutes("/tickets/categories", ticketCategoriesRoutes),
  ticketStatuses: compileProjectRoutes("/ticket-statuses", ticketStatusesRoutes),
  admin: compileProjectRoutes("/admin", adminRoutes),
  adminNotifications: compileProjectRoutes("/admin-notifications", adminNotificationsRoutes),
  analytics: compileProjectRoutes("/analytics", analyticsRoutes),
  addresses: compileProjectRoutes("/addresses", addressRoutes),
  messageFeed: compileProjectRoutes("/messages", messagesRoutes),
  portfolio: compileRoutes("/portfolio", portfolioRoutes),
  projectConnections: compileRoutes("/portfolio/settings/connections", projectConnectionsRoutes),
  automatedSurveys: compileRoutes("/portfolio/settings/automated-surveys", automatedSurveysRoutes),
  platformGroups: compileRoutes("/portfolio/settings/platform-groups", platformGroupsRoutes),
  automatedSurveyQueues: compileProjectRoutes("/automated-surveys", automatedSurveyQueueRoutes),
  alerts: compileRoutes("/portfolio/settings/alerts", alertsRoutes),
  projects: compileProjectRoutes("/projects", projectRoutes),
  buildings: compileProjectRoutes("/buildings", buildingRoutes),
  companies: compileProjectRoutes("/companies", companyRoutes),
  onboardingScreens: compileProjectRoutes("/onboarding-screens", onboardingScreensRoutes),
  surveys: compileProjectRoutes("/surveys", surveyRoutes),
  chats: compileProjectRoutes("/chats", chatsRoutes),
  users: compileProjectRoutes("/users", usersRoutes),
  home: compileProjectRoutes("/home", homeRoutes),
  fahHistory: compileProjectRoutes("/fah-history", fahHistoryRoutes),
  bookings: compileProjectRoutes("/bookings", bookingsRoutes),
  reservations: compileProjectRoutes("/reservations", reservationsRoutes),
  onboardingFlow: compileProjectRoutes("/onboarding", onboardingFlowRoutes),
  registration: compileRoutes("/register", registrationRoutes),
  authentication: compileRoutes("/auth", authenticationRoutes),
  quickReplyMessage: compileRoute(`/quick-message/:token`),
  quickReplyRepairRequest: compileRoute("/quick-repair-request/:token"),
} as const;

function compileRoute<TPath extends string>(path: TPath): RouteFunction<TPath> {
  const result = compile(path) as RouteFunction<TPath>;
  result.PATH = path;

  return result;
}

function compileRoutes<
  TRoot extends RootRoute,
  TRoutes extends {
    [_ in keyof TRoutes]: string;
  },
>(root: TRoot, param: TRoutes): CompiledRoute<TRoot, TRoutes> {
  // @ts-expect-error - Filling happens in the loop
  const definition: CompiledRoute<TRoot, TRoutes> = {
    ROOT: `${root}/*`,
  } as const;

  for (const key in param) {
    // @ts-expect-error - Cant match the key and the value
    definition[key] = compileRoute(`${root}${param[key] === "" ? "" : `/${param[key]}`}`);
  }

  return definition;
}

function compileProjectRoutes<
  TRoot extends RootRoute,
  TRoutes extends {
    [_ in keyof TRoutes]: string;
  },
>(root: TRoot, param: TRoutes): CompiledRoute<`/@/:slug${TRoot extends "/" ? "" : TRoot}`, TRoutes> {
  return compileRoutes(
    `/@/:slug${((root as string) === "/" ? "" : root) as unknown as TRoot extends "/" ? "" : TRoot}`,
    param,
  );
}

type RootRoute = `/${string}`;

type PathParamValue = string | number;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtractPathParams<TPath extends string> = TPath extends `${infer _Prefix}/:${infer Param}/${infer Rest}`
  ? { [K in Param | keyof ExtractPathParams<Rest>]: PathParamValue }
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TPath extends `${infer _Prefix}/:${infer Param}`
    ? { [K in Param]: PathParamValue }
    : {};

type InsertPathParams<
  TPath extends string,
  TParams extends Record<string, any>,
> = TPath extends `${infer Prefix}/:${infer Param}/${infer Rest}`
  ? Param extends keyof TParams
    ? `${Prefix}/${TParams[Param]}/${Rest}`
    : never
  : TPath extends `${infer Prefix}/:${infer Param}`
    ? Param extends keyof TParams
      ? `${Prefix}/${TParams[Param]}`
      : never
    : TPath;

type RouteFunction<TPath extends string> = { PATH: TPath } & (keyof ExtractPathParams<TPath> extends never
  ? () => TPath
  : <TData extends ExtractPathParams<TPath>>(data: TData) => InsertPathParams<TPath, TData>);

type CompiledRoute<
  TRoot extends string,
  TRoutes extends {
    [_ in keyof TRoutes]: string;
  },
> = {
  [K in keyof TRoutes]: RouteFunction<TRoutes[K] extends "" ? TRoot : `${TRoot}/${TRoutes[K]}`>;
} & {
  readonly ROOT: `${TRoot}/*`;
};
