import type { SearchableSelectProps } from "components/SearchableSelect/SearchableSelect";
import { SearchableSelect } from "components/SearchableSelect/SearchableSelect";
import { isDefined } from "helpers/util";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

type FormSearchableSelectProps<TFormValues extends FieldValues, T> = Omit<
  SearchableSelectProps<T>,
  "selected" | "onChange"
> &
  UseControllerProps<TFormValues, FieldPath<TFormValues>>;

export function FormSearchableSelect<TFormValues extends FieldValues, T>({
  name,
  control,
  rules,
  defaultValue,
  ...props
}: FormSearchableSelectProps<TFormValues, T>): React.ReactNode {
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues>({ name, control, rules, defaultValue });

  return (
    <FormErrorWrapper<TFormValues> name={name}>
      <SearchableSelect {...props} selected={field.value} onChange={field.onChange} aria-invalid={isDefined(error)} />
    </FormErrorWrapper>
  );
}
