import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useBool } from "hooks/useBool";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface DeleteModalProps<T> {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  modalContext: T | null;
  setModalContext: (entity: T | null) => void;
  onDelete: (entity: T) => Promise<unknown>;
  rejectBtnProps?: {
    text?: string;
    "data-testid"?: string;
  };
  deleteBtnProps?: {
    text?: string;
    "data-testid"?: string;
  };
  "data-testid"?: string;
}

export function DeleteModal<T>(props: DeleteModalProps<T>): React.ReactNode {
  const { t } = useTranslation();
  const [isLoading, isLoadingHandlers] = useBool();
  const { onClose } = props;
  useEffect(() => {
    if (!isLoading) {
      onClose();
    }
  }, [onClose, isLoading]);

  return (
    <ConfirmModal
      title={props.title}
      description={props.description}
      isLoading={isLoading}
      theme="danger"
      onOpenChange={(state) => {
        if (!state) {
          onClose();
        }
      }}
      onReject={onClose}
      onResolve={async () => {
        isLoadingHandlers.setTrue();
        try {
          if (props.modalContext == null || isLoading) {
            return;
          }
          await props.onDelete(props.modalContext);
        } catch (e) {
          console.error(e);
        } finally {
          isLoadingHandlers.setFalse();
        }
      }}
      resolveBtnProps={{
        text: t("common.action.delete"),
        "data-testid": "modal-confirm-delete",
        ...props.deleteBtnProps,
      }}
      rejectBtnProps={{
        text: t("common.action.cancel"),
        "data-testid": "modal-cancel-delete",
        ...props.rejectBtnProps,
      }}
      isOpened={props.isOpen}
      shouldCloseOnEsc
      onAfterClose={() => props.setModalContext(null)}
      data-testid={props["data-testid"] || "delete-modal"}
    />
  );
}

export function useDeleteModal<T>(id: string): {
  componentProps: Pick<DeleteModalProps<T>, "modalContext" | "setModalContext" | "isOpen" | "onClose" | "id">;
  openDeleteModal: (entity: T) => void;
} {
  const [modalContext, setModalContext] = useState<T | null>(null);
  const [isOpen, { setFalse, setTrue }] = useBool();
  const openDeleteModal = useCallback(
    (entity: T) => {
      setModalContext(entity);
      setTrue();
    },
    [setTrue],
  );

  return {
    componentProps: { modalContext, setModalContext, isOpen, onClose: setFalse, id },
    openDeleteModal,
  };
}
