import type { UserRoleDetailsDto } from "api/types";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconKey02 from "assets/icons/key-02.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { canManageRoles } from "../permissions";

interface RoleDetailsProps {
  roleDetails: UserRoleDetailsDto;
  onDelete: (id: string) => void;
}
export function RoleDetails(props: RoleDetailsProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const {
    roleDetails: { id, name, type, locked, default: isRoleDefault },
  } = props;

  return (
    <div data-testid="role-details">
      <div className="mb-6">
        <h2 className="mb-2 text-headline3 leading-old-headline4">{t("page.role.section.general")}</h2>
        <dl>
          <div className="flex gap-x-1">
            <dt className="text-grey-700">{t("model.role.name")}:</dt>
            <dd>{name}</dd>
          </div>
          <div className="flex gap-x-1">
            <dt className="text-grey-700">{t("model.role.type")}:</dt>
            <dd>{type}</dd>
          </div>
        </dl>
      </div>
      <nav className="text-grey-700">
        {hasPermission(canManageRoles) && (
          <div className="flex flex-wrap gap-x-1 gap-y-2 sm:gap-x-6">
            <Button
              type="link"
              styling="tertiary"
              className="flex gap-1"
              href={routes.roles.edit({ slug, id })}
              state={{ initialStepId: 2 }}
              disabled={locked}
            >
              <Icon name={iconKey02} size={16} />
              {t("model.role.permissions")}
            </Button>
            <Button
              type="link"
              styling="tertiary"
              className="flex gap-1"
              data-testid="role-edit-page-link"
              href={routes.roles.edit({ slug, id })}
              disabled={locked}
            >
              <Icon name={iconEdit05} size={16} />
              {t("common.action.edit")}
            </Button>
            <Button
              type="link"
              styling="tertiary"
              className="flex gap-1"
              data-testid="role-duplicate-page-link"
              href={routes.roles.create({ slug })}
              state={{ roleDetails: props.roleDetails }}
            >
              <Icon name={iconCopy01} size={16} />
              {t("common.action.duplicate")}
            </Button>
            <Button
              className="flex gap-1"
              data-testid="role-delete-button"
              onClick={() => props.onDelete(id)}
              styling="danger"
              disabled={isRoleDefault}
            >
              <Icon name={iconTrash02} size={16} />
              {t("common.action.delete")}
            </Button>
          </div>
        )}
      </nav>
    </div>
  );
}
