import { Badge } from "components/Badge/Badge";
import type { ReactNode } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface BadgeSelectProps<T, TSelected extends T | undefined = T | undefined> {
  id?: string;
  selected: TSelected;
  items: readonly T[];
  renderOption: (item: T, selected: boolean) => ReactNode;
  keySelector: (item: T) => string | number;
  "data-testid"?: string;
  "aria-invalid"?: boolean;
}
type FormBadgeSelectProps<TFormValues extends FieldValues, T, TSelected extends T | undefined> = Omit<
  BadgeSelectProps<T, TSelected>,
  "selected"
> &
  UseControllerProps<TFormValues>;

export function FormBadgeSelect<
  TFormValues extends FieldValues,
  TItem,
  TSelected extends TItem | undefined = TItem | undefined,
>({
  name,
  control,
  rules,
  defaultValue,
  ...props
}: FormBadgeSelectProps<TFormValues, TItem, TSelected>): React.ReactNode {
  const { t } = useTranslation();
  const {
    field: { ref, ...field },
  } = useController<TFormValues>({
    name,
    control,
    rules: {
      ...rules,
      validate: {
        ...rules?.validate,
        oneOf: (selected) => {
          const selectedKey = selected ? props.keySelector(selected as TItem) : undefined;
          if (selected && !props.items.some((item) => props.keySelector(item) === selectedKey)) {
            return t("components.form.error.invalid-option");
          }
        },
      },
    },
    defaultValue,
  });

  const selectedId = field.value ? props.keySelector(field.value) : undefined;

  return (
    <FormErrorWrapper name={name}>
      <div className="flex flex-wrap gap-2">
        {props.items.map((item) => {
          const id = props.keySelector(item);

          return (
            <Badge
              key={id}
              selected={id === selectedId}
              onClick={() => field.onChange(item)}
              onBlur={() => field.onBlur()}
              tabIndex={0}
              as="button"
              type="button"
            >
              {props.renderOption(item, id === field.value)}
            </Badge>
          );
        })}
      </div>
    </FormErrorWrapper>
  );
}
