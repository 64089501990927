import { Analytics } from "components/Analytics";
import { PageGrid } from "components/PageGrid/PageGrid";
import { Paper } from "components/Paper/Paper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useConfig } from "providers/ConfigProvider";
import { useTranslation } from "react-i18next";

import { HomeCommunityPostList } from "../components/HomeCommunityPostList";
import { HomeDataWidgets } from "../components/HomeDataWidgets";
import { HomeFahWidgetLegacy } from "../components/HomeFahWidgetLegacy";

export function Layout(): React.ReactNode {
  const sessionUser = useSessionUser();
  const isAnalyticsV2Available = useConfig("isAnalyticsV2Available");
  const { t } = useTranslation();

  useDocumentTitle(undefined);

  const canViewDataWidgets = sessionUser.isAdmin && sessionUser.role.type !== "maintenance";
  const canViewFahWidget = sessionUser.isAdmin;

  return (
    <Paper theme="no-header-minimal">
      {isAnalyticsV2Available && (
        <PageGrid.Grid>
          <PageGrid.Item size="66%" isDesktopIndiffernt>
            {canViewFahWidget && (
              <div className="flex w-full flex-col gap-4">
                <h1 className="text-headline1">
                  {t("page.home.feel-at-home.description", { name: sessionUser.firstName })} 👋
                </h1>
                <Analytics.FahWidget />
              </div>
            )}
          </PageGrid.Item>
          <PageGrid.Item size="33%" isDesktopIndiffernt>
            {canViewDataWidgets ? <HomeDataWidgets /> : <div />}
          </PageGrid.Item>

          <PageGrid.Item size="66%" isDesktopIndiffernt>
            <PageGrid.Grid>
              <PageGrid.Item size="50%" isDesktopIndiffernt>
                <Analytics.AdoptionWidget />
              </PageGrid.Item>
              {/* TODO(jimmy): Replace this with another new widget */}
              <PageGrid.Item size="50%" isDesktopIndiffernt>
                {/* <Analytics.AdoptionWidget /> */}
                <div className="flex size-full flex-col items-center gap-6 rounded-lg bg-white p-4" />
              </PageGrid.Item>
            </PageGrid.Grid>
          </PageGrid.Item>
          <PageGrid.Item size="66%" isDesktopIndiffernt>
            <HomeCommunityPostList />
          </PageGrid.Item>
        </PageGrid.Grid>
      )}
      {!isAnalyticsV2Available && (
        <div className="max-w-[880px] 2xl:max-w-none">
          <div className="grid grid-cols-1 grid-rows-[auto_auto] gap-4 2xl:grid-cols-[min-content_auto] 2xl:justify-between 2xl:gap-x-16">
            {canViewFahWidget && <HomeFahWidgetLegacy />}
            {canViewDataWidgets ? <HomeDataWidgets /> : <div />}
            <HomeCommunityPostList />
          </div>
        </div>
      )}
    </Paper>
  );
}
