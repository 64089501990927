import type { ImageDto } from "api/types";
import type { FormImage } from "components/ImageInput/useImageInput";
import { isImageUploaded } from "components/ImageInput/useImageInput";
import type { QuickReplyRequestUsage } from "queries/files";
import { fileMutations } from "queries/files";
import { useCallback } from "react";

// TODO: Replace usage of this function with `useUploadImage` once BE strips out `quick-reply` logic
export function useUploadQuickReplyImage({ token, usage }: { token: string; usage: QuickReplyRequestUsage }): {
  uploadImage: UploadImage;
  uploadFormImage: UploadFormImage;
  isUploadingImage: boolean;
} {
  const { mutateAsync: uploadImage, isPending: isUploadingImage } = fileMutations.useAddQuickReplyImage({
    token,
    usage,
  });

  const uploadFormImage: UploadFormImage = useCallback(
    async (formImage) => {
      if (isImageUploaded(formImage)) {
        return formImage;
      }

      if (formImage.uploadPromise) {
        return formImage.uploadPromise;
      }

      return await uploadImage(formImage);
    },
    [uploadImage],
  );

  return { uploadImage, uploadFormImage, isUploadingImage };
}

interface UploadImageRequest {
  file: File;
  description?: string | undefined;
}

type UploadImage = (image: UploadImageRequest) => Promise<ImageDto>;
type UploadFormImage = (image: FormImage) => Promise<ImageDto | undefined>;
