import iconPlayCircle from "assets/icons/play-circle.svg";
import { Icon } from "components/Icon/Icon";
import { MediaXButton } from "components/Media/MediaXButton";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded } from "components/VideoInput/useVideoInput";
import { formatFileSize } from "helpers/file-size";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import type React from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { VideoModal } from "../VideoModal/VideoModal";
import { VideoFileUploadProgressBar } from "./VideoFileUploadProgressBar";

interface VideoFilePreviewProps {
  video: FormVideo;
  isInvalid?: boolean;
  isPlayable?: boolean;
  onDelete?: () => void;
}

export function VideoFilePreview({
  video,
  isInvalid,
  isPlayable = true,
  onDelete,
}: VideoFilePreviewProps): React.ReactNode {
  const { t } = useTranslation();
  const [isVideoModalOpened, videoModalOpenHandler] = useBool(false);

  const getFileMetadataLabel = (size: number, name: string | undefined) => {
    const formattedFileExtension = name?.split(".").pop()?.toUpperCase() ?? "";
    const formattedFileSize = formatFileSize(size, 0);

    return `${formattedFileSize} ${formattedFileExtension}`;
  };

  const Component = isPlayable ? "button" : "div";
  let fileName: string | undefined = "";
  let fileSize: number | undefined = 0;
  let fileSrc: string = "";
  let uploadProgress: undefined | number = undefined;
  let isThumbnailShown = false;
  if (isVideoUploaded(video)) {
    fileName = video.fileName;
    fileSize = video.fileSize;
    fileSrc = video.url;
    uploadProgress = 100;
  } else {
    fileName = video.file.name;
    fileSize = video.file.size;
    fileSrc = video.url;
    isThumbnailShown = true;

    if (isDefined(video.uploadProgress)) {
      uploadProgress = video.uploadProgress;
    }
  }

  return (
    <>
      <div
        className={twJoin(
          "flex max-h-20 w-fit min-w-[320px] max-w-[400px] items-center rounded-lg border bg-grey-100 text-left transition-colors",
          isInvalid ? "border-red-600" : "border-transparent",
          isPlayable ? "hocus:bg-grey-300/50" : undefined,
        )}
      >
        <Component
          className={twJoin("flex w-full items-center gap-4 p-4", isPlayable && "cursor-pointer")}
          type={isPlayable ? "button" : undefined}
          onClick={isPlayable ? videoModalOpenHandler.setTrue : undefined}
        >
          {isThumbnailShown ? (
            <video
              className="aspect-video h-12 shrink-0 overflow-hidden rounded-[4px] bg-black object-contain"
              src={fileSrc}
            />
          ) : (
            <Icon name={iconPlayCircle} size={32} />
          )}
          <div className="flex w-full flex-col justify-center gap-1 text-black">
            <span className="line-clamp-1 max-w-60 break-all text-left text-body-bold leading-[120%]">
              {fileName ?? "..."}
            </span>
            {isDefined(uploadProgress) && uploadProgress < 100 && (
              <VideoFileUploadProgressBar progress={uploadProgress} />
            )}
            {(!isDefined(uploadProgress) || uploadProgress === 100) && isDefined(fileSize) && (
              <p className="text-left leading-none text-grey-600">{getFileMetadataLabel(fileSize, fileName)}</p>
            )}
          </div>
        </Component>

        {onDelete && (
          <div className="ml-2 pr-4">
            <MediaXButton onClick={onDelete} title={t("common.action.delete")} />
          </div>
        )}
      </div>
      <VideoModal
        isOpened={isVideoModalOpened}
        videoName={fileName}
        videoSrc={fileSrc}
        onOpenChange={videoModalOpenHandler.set}
      />
    </>
  );
}
