import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { FeelingAtHomeSurveyQuestionDto } from "api/types";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

export const useFahHistoryQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  const limit = 1;

  return {
    getFahHistoryDetails: () =>
      queryOptions({
        queryKey: QUERY_KEYS.FEELING_AT_HOME_HISTORY_DETAILS(projectId),
        queryFn: () => api.getAnalyticsFeelingAtHomeDetailsV2(),
        select: commonAPIDataSelector,
      }),
    getFahHistoryResponsesInfinite: (questionType: FeelingAtHomeSurveyQuestionDto["category"]) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.FEELING_AT_HOME_HISTORY_RESPONSES(projectId, questionType),
        queryFn: async ({ pageParam = 0 }) => {
          const { data } = await api.getAnalyticsFeelingAtHomeQuestionResponsesV1(questionType, {
            Offset: pageParam * limit,
            Limit: limit,
          });

          return data;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      }),
    getQuestionSummary: (questionType: FeelingAtHomeSurveyQuestionDto["category"]) =>
      queryOptions({
        queryKey: QUERY_KEYS.FEELING_AT_HOME_HISTORY_QUESTION_RESPONSE_SUMMARY(projectId, questionType),
        queryFn: () => api.getAnalyticsFeelingAtHomeSummaryDetailsV1(questionType),
        select: commonAPIDataSelector,
        staleTime: 1000 * 60 * 5,
      }),
  };
};
