import type { FeelingAtHomeWidgetV2Dto } from "api/types";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { differenceInMonths, parseISO } from "date-fns";
import { isDefined } from "helpers/util";
import type React from "react";
import { useTranslation } from "react-i18next";

import { FahWidgetScoreReferenceLabel } from "./FahWidgetScoreDifferenceLabel";
import { Speedometer } from "./Speedometer";
import type { FahWidgetStage } from "./types";

type FahWidgetSpeedometerProps = {
  stage: FahWidgetStage;
  data: FeelingAtHomeWidgetV2Dto;
};

export const FahWidgetSpeedometer = ({ stage, data }: FahWidgetSpeedometerProps): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const getScore = (fahStage: FahWidgetStage) => {
    switch (fahStage) {
      case "average":
        return data.totalFeelingAtHomeScore ? data.totalFeelingAtHomeScore.rating / 10 : 0;
      case "lastMonth":
        return data.lastMonthFeelingAtHomeScore ? data.lastMonthFeelingAtHomeScore.rating / 10 : 0;
      default:
        return 0;
    }
  };

  const getLabel = (fahStage: FahWidgetStage) => {
    switch (fahStage) {
      case "average":
        return t("component.analytics-widget.common.aop-benchmark.label", {
          score: data.benchmarkFeelingAtHomeScore?.rating?.toFixed(1) ?? "-",
        });
      case "lastMonth":
        return t("component.analytics-widget.fah-score.last-month.label", {
          amount: data.lastMonthFeelingAtHomeScore?.answerCount ?? 0,
        });
      default:
        return "";
    }
  };

  const getDescription = (fahStage: FahWidgetStage) => {
    switch (fahStage) {
      case "average":
        return t("component.analytics-widget.fah-score.average.description");
      case "lastMonth":
        if (!data.lastMonthFeelingAtHomeScore?.month) {
          return t("common.label.not-applicable");
        }

        return t("component.analytics-widget.fah-score.last-month.description", {
          date: formatDate(i18n, "monthYear", data.lastMonthFeelingAtHomeScore.month),
        });
      default:
        return "";
    }
  };

  // Show difference against last month if last month is true last month. (e.g. Nov -> Dec)
  const isDifferenceShown =
    stage === "average" &&
    isDefined(data.totalFeelingAtHomeScore?.rating) &&
    isDefined(data.lastFeelingAtHomeScore?.rating) &&
    Math.abs(differenceInMonths(parseISO(data.lastFeelingAtHomeScore?.month), new Date())) === 1;

  return (
    <div className="flex w-[min(376px,100%)] flex-col gap-6">
      <div className="flex w-full flex-col items-center gap-4">
        <Speedometer
          score={getScore(stage)}
          renderCurrentValue={(score) => (score ? (score * 10).toFixed(1) : undefined)}
        />
        <div className="flex flex-col gap-1">
          <p className="text-center text-body">{getLabel(stage)}</p>
          <p className="text-center text-body text-grey-700">{getDescription(stage)}</p>
        </div>
      </div>
      {isDifferenceShown && (
        <div className="w-full [&>*]:w-full">
          <FahWidgetScoreReferenceLabel
            currValue={data.totalFeelingAtHomeScore?.rating ?? 0}
            referenceValue={data.lastFeelingAtHomeScore?.rating ?? 0}
          />
        </div>
      )}
    </div>
  );
};
