import { Popover, Portal } from "@ark-ui/react";
import iconInfo from "assets/icons/info-circle.svg";
import { Icon } from "components/Icon/Icon";
import { twJoin } from "tailwind-merge";

interface InfoIconProps {
  tooltip: NonNullable<React.ReactNode>;
  grayscale?: boolean;
}

export function InfoIcon({ tooltip, grayscale }: InfoIconProps): React.ReactNode {
  return (
    <Popover.Root
      positioning={{
        placement: "top",
        offset: {
          mainAxis: 5,
        },
      }}
    >
      <Popover.Trigger>
        <Icon
          name={iconInfo}
          size={16}
          className={twJoin(
            "shrink-0",
            grayscale ? "text-grey-600 hover:text-grey-700" : "text-aop-basic-blue-500 hover:text-blue-700",
          )}
        />
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner className="!z-[70]">
          <Popover.Content
            className={twJoin(
              "max-w-[80%] whitespace-pre-line rounded-2px bg-grey-900 px-3 py-2 text-caption text-white shadow-md repeat-1 md:max-w-sm",
              "data-[placement=bottom]:origin-top data-[placement=left]:origin-right data-[placement=right]:origin-left data-[placement=top]:origin-bottom",
              "data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:zoom-in",
              "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out",
            )}
          >
            <Popover.Arrow
              style={{
                // @ts-expect-error --css-vars
                "--arrow-size": "8px",
                // bg-grey-darkest
                "--arrow-background": "#4a4a68",
              }}
            >
              <Popover.ArrowTip />
            </Popover.Arrow>

            <Popover.Title asChild>
              <p>{tooltip}</p>
            </Popover.Title>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
}
