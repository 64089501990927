import iconPlay from "assets/icons/play.svg";
import { Icon } from "components/Icon/Icon";
import type { InputFile } from "components/VideoInput/useVideoInput";
import { useRef, useState } from "react";

import { formatVideoDuration } from "./helpers";

interface CommunityItemVideoForAppPreviewProps {
  video: InputFile;
}

export function CommunityItemVideoForAppPreview({ video }: CommunityItemVideoForAppPreviewProps): React.ReactNode {
  const [duration, setDuration] = useState(0);

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const onLoadedMetadata = () => {
    if (refVideo.current) {
      setDuration(refVideo.current.duration);
    }
  };

  return (
    <div className="relative max-h-[574px] w-full cursor-pointer overflow-hidden rounded-md bg-black/20 xs:max-h-[360px] xs:w-auto">
      <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/20">
        <span className="flex size-10 items-center justify-center rounded-full bg-black transition-colors hover:bg-black/90">
          <Icon className="ml-3px fill-white" name={iconPlay} size={20} />
        </span>
        <span className="absolute bottom-2 right-2 rounded-lg bg-black px-2 py-1 text-caption text-white">
          {formatVideoDuration(duration)}
        </span>
      </div>

      <video
        ref={refVideo}
        className="max-h-full max-w-full object-contain"
        src={video.url}
        preload="metadata"
        {...{ onLoadedMetadata }}
      />
    </div>
  );
}
