import { useImageResolver } from "api/hooks/useImageResolver";
import { type FormImage, isImageUploaded } from "components/ImageInput/useImageInput";
import { preloadImage } from "helpers/image";
import type React from "react";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { getAspectRatio } from "./helpers";

// Used when only a single image is present in a single post
type CommunityItemImageBaseProps = {
  image: FormImage;
  shouldLoad: boolean;
  onClick: (image: FormImage) => void;
};

export function CommunityItemSingleImage({ image, shouldLoad, onClick }: CommunityItemImageBaseProps): React.ReactNode {
  const [isLoading, setIsLoading] = useState(true);

  const resolveImage = useImageResolver();
  const isUploaded = isImageUploaded(image);

  const getSrc = (currImage: FormImage) => {
    if (!shouldLoad) return "";

    if (isImageUploaded(currImage)) {
      return resolveImage(currImage, "intrinsic");
    } else {
      return currImage.url;
    }
  };

  const getAltText = (currImage: FormImage) => {
    if ("description" in currImage) {
      return currImage.description ?? "";
    } else {
      return "";
    }
  };

  return (
    <button
      type="button"
      onClick={() => onClick(image)}
      onMouseEnter={isUploaded ? () => preloadImage(image.url) : undefined}
      className="relative max-h-[574px] w-full cursor-zoom-in overflow-hidden rounded-md bg-black/20 xs:max-h-[360px] xs:w-auto"
      style={isUploaded ? { aspectRatio: getAspectRatio(image) } : undefined}
    >
      {isLoading && <CommunityItemImageSkeleton />}
      <img
        className={twJoin(
          "mx-auto max-h-full max-w-full object-contain",
          isLoading ? "opacity-0" : "opacity-100 transition-opacity hover:opacity-90",
        )}
        src={getSrc(image)}
        alt={getAltText(image)}
        onLoad={() => setIsLoading(false)}
        width={isUploaded ? image.metadata.width : undefined}
        height={isUploaded ? image.metadata.height : undefined}
      />
    </button>
  );
}

// Used when multiple images are present in a single post
type CommunityItemMultiImageProps = CommunityItemImageBaseProps & {
  amountImagesAfter?: number;
  isLast?: boolean;
};

export function CommunityItemMultiImage({
  image,
  amountImagesAfter = 0,
  shouldLoad,
  isLast,
  onClick,
}: CommunityItemMultiImageProps): React.ReactNode {
  const [isLoading, setIsLoading] = useState(true);

  const resolveImage = useImageResolver();
  const isUploaded = isImageUploaded(image);

  // Empty `src` in combination with `width` and `height` preserves space to prevent CLS
  let src = "";
  if (shouldLoad) {
    src = isUploaded ? resolveImage(image, "lg") : image.url;
  }

  return (
    <button
      type="button"
      onClick={() => onClick(image)}
      onMouseEnter={isUploaded ? () => preloadImage(image.url) : undefined}
      className={twJoin(
        "relative aspect-square w-full cursor-zoom-in overflow-hidden rounded-lg border border-grey-100",
      )}
    >
      {isLoading && <CommunityItemImageSkeleton />}
      {isLast && amountImagesAfter > 0 && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center bg-black/60 transition-colors hover:bg-black/80">
          <span className="text-headline2 text-white">+{amountImagesAfter}</span>
        </div>
      )}
      {shouldLoad && (
        <img
          className={twJoin(
            "size-full object-cover object-center",
            isLoading ? "opacity-0" : "opacity-100 transition-opacity hover:opacity-90",
          )}
          src={src}
          alt={"description" in image ? (image.description ?? "") : ""}
          width={isUploaded ? image.metadata.width : undefined}
          height={isUploaded ? image.metadata.height : undefined}
          onLoad={() => setIsLoading(false)}
        />
      )}
    </button>
  );
}

export function CommunityItemImageSkeleton(): React.ReactNode {
  return (
    <span
      style={{
        backgroundSize: "200%",
      }}
      className="absolute left-0 top-0 z-10 size-full animate-[background-shift-x_2s_infinite] bg-grey-100 bg-gradient-to-br from-grey-50 via-grey-100 to-grey-50"
    />
  );
}
