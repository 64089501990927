import type { ConnectedProjectDto } from "api/types";
import { ProjectLogo } from "components/ProjectLogo/ProjectLogo";
import { useRecentProjects } from "components/ProjectSwitcher/useRecentProjects";
import { useSidebarManager } from "hooks/useSidebar";
import { unionBy } from "lodash-es";
import { useProjectContext } from "providers/ProjectContext";
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

const inProject = false;

export function PortfolioSidebarRecentProjectsList(): React.ReactNode {
  const { t } = useTranslation();
  const { projectId, changeProject } = useProjectContext();
  const { isCollapsed } = useSidebarManager();
  const { projects, recentProjects, isSearchAvailable } = useRecentProjects(projectId, !inProject, 3);

  const mostRecentProjects = useMemo(
    () =>
      (recentProjects.length < 3 ? unionBy(recentProjects, projects, (project) => project.id) : recentProjects).slice(
        0,
        3,
      ),
    [projects, recentProjects],
  );

  if (mostRecentProjects.length === 0) return null;

  return (
    <div className={twJoin("mt-4 hidden w-full flex-col gap-4 h-lg:flex")}>
      {!isCollapsed && isSearchAvailable && (
        <h3 className="text-overline-bold uppercase tracking-wide text-grey-700">
          {t("navigation.portfolio.projects.last-opened")}
        </h3>
      )}
      <div className="flex w-full flex-col gap-4">
        {mostRecentProjects.map((project) => (
          <PortfolioSidebarProjectCard
            key={project.id}
            onClickProject={() => {
              changeProject(project.id, true);
            }}
            {...{ project }}
          />
        ))}
      </div>
    </div>
  );
}

interface PortfolioSidebarProjectCardProps {
  project: ConnectedProjectDto;
  onClickProject?: () => void;
}

function PortfolioSidebarProjectCard({ project, onClickProject }: PortfolioSidebarProjectCardProps): React.ReactNode {
  const { isCollapsed } = useSidebarManager();

  return (
    <button
      className={twJoin(
        "group relative flex items-center gap-4 rounded-md outline-none hover:bg-aop-basic-blue-100 focus-visible:bg-aop-basic-blue-100",
        isCollapsed ? "p-1" : "p-2",
      )}
      onClick={onClickProject}
    >
      <ProjectLogo className={twJoin("shrink-0", isCollapsed ? "size-8" : "size-10")} src={project.logoUrl} />
      {isCollapsed ? (
        <span className="pointer-events-none absolute left-full top-0 z-30 mb-3 ml-2 flex flex-col whitespace-nowrap rounded-lg bg-black p-2 font-old-semibold text-white opacity-0 transition-opacity delay-300 group-hover:opacity-100 group-focus-visible:opacity-100 group-has-[:focus-visible]/sidebar:delay-0">
          {project.name}
        </span>
      ) : (
        <div className="flex flex-1 flex-col items-start">
          <span className="line-clamp-1 break-all text-left text-body-bold">{project.name}</span>
          {project.city && <span className="text-left text-overline text-grey-700">{project.city}</span>}
        </div>
      )}
    </button>
  );
}
