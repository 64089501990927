import type { DocumentInputProps } from "components/DocumentInput/DocumentInput";
import { DocumentInput } from "components/DocumentInput/DocumentInput";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface FormDocumentInputProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<DocumentInputProps, "onChange" | "value"> {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  "data-testid"?: string;
  className?: string;
}

export function FormDocumentInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  rules,
  "data-testid": dataTestid,
  className,
  ...props
}: FormDocumentInputProps<TFormValues, TName>): React.ReactNode {
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({ name, rules, defaultValue: [] as FieldPathValue<TFormValues, TName> });
  const isError = error != null;

  return (
    <FormErrorWrapper<TFormValues> name={name} className={className} data-testid={dataTestid}>
      <DocumentInput {...props} {...field} value={field.value} aria-invalid={isError} onChange={field.onChange} />
    </FormErrorWrapper>
  );
}
