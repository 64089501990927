import type { ConstraintListItemDto } from "api/types";
import iconChevronRight from "assets/icons/chevron-right.svg";
import iconPlus from "assets/icons/plus.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Icon } from "components/Icon/Icon";
import { getIconByAudienceType, mapAudienceByType, orderAudienceTypes } from "helpers/audience";
import type React from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

type AudienceType = ConstraintListItemDto["type"];

interface AudiencePreviewProps {
  audience: ConstraintListItemDto[];
  readOnly?: boolean;
  truncate?: boolean;
}

export function AudiencePreview({
  audience,
  readOnly,
  truncate,
  ...otherProps
}: AudiencePreviewProps): React.ReactNode {
  const { t } = useTranslation();

  const audienceGroupByType = mapAudienceByType(audience);
  const filteredAudience = Object.fromEntries(
    Object.entries(audienceGroupByType).filter(([, items]) => items.length > 0),
  ) as Record<ConstraintListItemDto["type"], ConstraintListItemDto[]>;
  const availableAudienceTypes = Object.keys(filteredAudience) as AudienceType[];
  const Component = readOnly ? "div" : "button";

  return (
    <Component
      className={twJoin(
        "flex w-full max-w-full items-center justify-between gap-2",
        readOnly
          ? "py-2"
          : "cursor-pointer rounded-lg p-2 ring-1 ring-grey-300 hover:ring-grey-700 focus-visible:outline-none focus-visible:ring-grey-900",
      )}
      {...(!readOnly && { type: "button", ...otherProps })}
    >
      <div className="flex flex-wrap items-center gap-1">
        <div className="flex items-center gap-1">
          <Icon name={iconUsersCheck} size={16} />
          <span>{t("common.entity.audience")}:</span>
        </div>
        {availableAudienceTypes.length === 0 && (
          <CreateOrEditAssetsAudiencePreviewItem label={t("component.audience-selector.default-audience.label")} />
        )}
        {availableAudienceTypes.length > 0 &&
          orderAudienceTypes(availableAudienceTypes).map((type, index) => {
            const items = filteredAudience[type];
            const label = items.map(({ key }) => key).join(", ");
            const icon = getIconByAudienceType(type);

            if (truncate && index === 1) {
              const amountAudienceOmitted = Object.keys(filteredAudience).length - 1;

              return (
                <CreateOrEditAssetsAudiencePreviewItem
                  key={type}
                  icon={iconPlus}
                  label={t("component.audience-selector.ommited-audience.label", {
                    amountOmitted: amountAudienceOmitted,
                  })}
                />
              );
            } else if (truncate && index > 1) {
              return null;
            }

            return <CreateOrEditAssetsAudiencePreviewItem key={type} {...{ icon, label }} />;
          })}
      </div>
      {!readOnly && <Icon name={iconChevronRight} size={16} />}
    </Component>
  );
}

interface CreateOrEditAssetsAudiencePreviewItemProps {
  icon?: string;
  label: string;
}

function CreateOrEditAssetsAudiencePreviewItem({
  icon,
  label,
}: CreateOrEditAssetsAudiencePreviewItemProps): React.ReactNode {
  return (
    <span className="flex items-center gap-1 rounded-[4px] bg-grey-100 px-1 text-caption text-black">
      {icon && <Icon name={icon} size={16} />}
      <span>{label}</span>
    </span>
  );
}
