import { useQuery } from "@tanstack/react-query";
import type { FeelingAtHomeDistributionDto } from "api/types";
import iconEye from "assets/icons/eye.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { useSlug } from "hooks/useSlug";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import type React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { FahWidgetDistributionTable } from "./FahWidgetDistributionTable";
import type { FahWidgetStage } from "./types";

const FALLBACK_DISTRIBUTIONS: FeelingAtHomeDistributionDto[] = [
  {
    type: "community",
    importance: 0,
    score: 0,
  },
  {
    type: "building",
    importance: 0,
    score: 0,
  },
  {
    type: "services",
    importance: 0,
    score: 0,
  },
];

type FahWidgetDistributionSectionProps = {
  stage: FahWidgetStage;
};

export function FahWidgetDistributionSection({ stage }: FahWidgetDistributionSectionProps): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();
  const analyticsQueries = useAnalyticsQueries();
  const { data: fahRecentStats } = useQuery(analyticsQueries.getFah());

  const latestFahSurveyDetailsPath = `${routes.surveys.fah({ slug })}?date=${fahRecentStats?.lastFeelingAtHomeScore?.month.substring(0, 7)}`;

  const getDistributions = (fahStage: FahWidgetStage) => {
    switch (fahStage) {
      case "average":
        return fahRecentStats?.distributions;
      case "lastMonth":
        return fahRecentStats?.lastMonthFeelingAtHomeScore?.distributions;
    }
  };
  const distributions = getDistributions(stage) || FALLBACK_DISTRIBUTIONS;

  return (
    <div className="flex w-full flex-col items-center">
      <h2 className="text-center text-headline2">{t("component.analytics-widget.fah-distribution.title")}</h2>

      <div className="flex w-full flex-1 flex-col items-center p-4 pb-0">
        {(stage === "average" || stage === "lastMonth") && <FahWidgetDistributionTable {...{ distributions }} />}
        {stage === "lastSixMonths" && (
          <div className="flex flex-1 flex-col items-center justify-between gap-4">
            <p className="text-center text-body">
              {t("component.analytics-widget.fah-distribution.last-6-months.description")}
            </p>
            {/* TODO(jimmy): Replace href with a more suitable path */}
            <Button styling="secondary" type="link" href={latestFahSurveyDetailsPath} icon={<Icon name={iconEye} />}>
              {t("component.analytics-widget.fah-distribution.source.btn")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
