import type { SelectProps } from "components/Select/Select";
import { Select } from "components/Select/Select";
import { isDefined } from "helpers/util";
import type React from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormErrorWrapper } from "./FormErrorWrapper";

type FormSelectProps<
  TFormValues extends FieldValues,
  T,
  TSelected extends T | undefined,
  TEmptyItem extends React.ReactNode,
> = Omit<SelectProps<T, TSelected, TEmptyItem>, "selected" | "defaultValue"> & UseControllerProps<TFormValues>;

export function FormSelect<
  TFormValues extends FieldValues,
  TItem,
  TSelected extends TItem | undefined = TItem | undefined,
  TEmptyItem extends React.ReactNode | undefined = React.ReactNode | undefined,
>({
  name,
  control,
  rules,
  onChange,
  defaultValue,
  ...props
}: FormSelectProps<TFormValues, TItem, TSelected, TEmptyItem>): React.ReactNode {
  const { t } = useTranslation();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<TFormValues>({
    name,
    control,
    rules: {
      ...rules,
      validate: {
        ...rules?.validate,
        oneOf: (selected) => {
          const selectedKey = selected ? props.keySelector(selected as TItem) : undefined;
          if (selected && !props.items.some((item) => props.keySelector(item) === selectedKey)) {
            return t("components.form.error.invalid-option");
          }
        },
      },
    },
    defaultValue,
  });

  return (
    <FormErrorWrapper name={name}>
      <Select<TItem, TSelected, TEmptyItem>
        {...props}
        {...field}
        selected={field.value as TSelected}
        aria-invalid={props["aria-invalid"] || isDefined(error)}
        onChange={(item) => {
          field.onChange(item || null);
          onChange?.(item);
        }}
      />
    </FormErrorWrapper>
  );
}
