import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import type { TFunction } from "i18next";
import { isArray } from "lodash-es";

export const MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES = 28;
export const MAXIMUM_DOCUMENT_FILE_SIZE_IN_MEGA_BYTES = 28;
export const MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES = 512;

export function megaBytesToBytes(sizeInMegaBytes: number): number {
  return Math.floor(sizeInMegaBytes * 1024 * 1024);
}

export function validateSize(
  t: TFunction,
  upload: FormImage | FormImage[] | FormDocument | FormDocument[] | FormVideo | FormVideo[],
  maximumSizeInMegaBytes: number,
): string | undefined {
  let isTooBig = false;
  if (isArray(upload)) {
    for (const uploadFile of upload) {
      if (checkFileSize(uploadFile, maximumSizeInMegaBytes)) {
        isTooBig = true;
        break;
      }
    }
  } else if (checkFileSize(upload, maximumSizeInMegaBytes)) {
    isTooBig = true;
  }

  if (isTooBig) {
    return t("components.form.error.file-too-big", {
      sizeInMegaBytes: maximumSizeInMegaBytes,
    });
  }
}

export function checkFileSize(upload: FormImage | FormDocument | FormVideo, maximumSizeInMegaBytes: number): boolean {
  if ("file" in upload && upload.file.size > megaBytesToBytes(maximumSizeInMegaBytes)) {
    return true;
  }

  return false;
}

export function formatFileSize(bytes: number, fractionDigits = 0): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 Byte";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / Math.pow(1024, i)).toFixed(fractionDigits)} ${sizes[i]}`;
}
