import { useInfiniteQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { FeelingAtHomeQuestionResponseDto, FeelingAtHomeSurveyQuestionV2Dto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Table } from "components/Table/Table";
import { isDefined } from "helpers/util";
import { useOnIntersection } from "hooks/useOnIntersection";
import { NoData } from "modules/analytics/components/NoData";
import { useFahHistoryQueries } from "queries/fahHistory/queryOptions";
import type React from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type FahHistoryQuestionResponsesModalProps = ModalBaseProps & {
  order: number;
  question: FeelingAtHomeSurveyQuestionV2Dto;
};

export function FahHistoryQuestionResponsesModal({
  order,
  question,
  isOpened,
  onOpenChange,
}: FahHistoryQuestionResponsesModalProps): React.ReactNode {
  const { i18n, t } = useTranslation();
  const fahHistoryQueries = useFahHistoryQueries();
  const {
    data: responsesData,
    isPending: isPendingResponses,
    hasNextPage: hasMoreResponses,
    isFetchingNextPage: isFetchingMoreResponses,
    fetchNextPage: fetchMoreResponses,
    isError: isErrorResponses,
  } = useInfiniteQuery({
    ...fahHistoryQueries.getFahHistoryResponsesInfinite(question.category),
    enabled: isOpened,
  });
  const responses = useMemo(() => responsesData?.pages.flatMap((x) => x.items ?? []) ?? [], [responsesData]);
  const totalResponses = useMemo(() => responsesData?.pages[0].total, [responsesData?.pages]);

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreResponses && hasMoreResponses) {
        void fetchMoreResponses();
      }
    }, [fetchMoreResponses, hasMoreResponses, isFetchingMoreResponses]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<FeelingAtHomeQuestionResponseDto>();

    return [
      helper.accessor("rating", {
        header: t("component.surveys-question.details.table.header.rating"),
        cell: (cell) => (
          <span className="flex items-center gap-1 text-center">
            <span>{cell.getValue()}</span>
            <Icon name={iconStar01} className="shrink-0 text-yellow-500" />
          </span>
        ),
        maxSize: 32,
      }),
      helper.accessor("answer", {
        header: t("component.surveys-question.details.table.header.open"),
        cell: (cell) => <span className="block py-0.5">{cell.getValue()}</span>,
      }),
      helper.accessor("user.fullName", {
        header: t("component.surveys-question.details.table.header.username"),
        cell: (cell) => <span className="block truncate">{cell.getValue()}</span>,
        maxSize: 100,
      }),
      helper.accessor("answeredAt", {
        header: t("component.surveys-question.details.table.header.date"),
        cell: (cell) => <span>{formatDate(i18n, "datetimeShort", cell.getValue())}</span>,
        maxSize: 80,
      }),
    ];
  }, [i18n, t]);

  const tableInstance = useReactTable<FeelingAtHomeQuestionResponseDto>({
    columns,
    data: responses,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Modal.Root
      size="fullscreen"
      title={`${order}. ${question.ratingQuestionText}`}
      description={question.openQuestionText}
      isScrollable
      {...{ isOpened, onOpenChange }}
    >
      {isPendingResponses && <LoadingIcon className="inset-0 mx-auto my-4 size-full w-10" />}
      {!isPendingResponses && isErrorResponses && (
        <div className="flex size-full items-center justify-center">
          <NoData noDataMessage={t("components.data-widgets.error")} />
        </div>
      )}
      {!isPendingResponses && !isErrorResponses && (
        <>
          <span className="text-caption">
            {t("component.surveys-question.details.responses", { count: totalResponses })}
          </span>
          {isDefined(totalResponses) && totalResponses > 0 && (
            <Table
              table={tableInstance}
              data-testid="survey-answer-list"
              getRowProps={() => ({
                "data-testid": "survey-answer-item",
              })}
            />
          )}
          {hasMoreResponses && (
            <div className="p-4" ref={loaderRef}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </>
      )}
    </Modal.Root>
  );
}
