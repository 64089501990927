import { useImageResolver } from "api/hooks/useImageResolver";
import type { ServicePartnerPageDetailsDto } from "api/types";
import iconMail01 from "assets/icons/mail-01.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconPhone from "assets/icons/phone.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { IqbiDetailsView } from "modules/service-partners/components/IqbiDetails/IqbiDetailsView";
import { MyPupDetailsView } from "modules/service-partners/components/MyPupDetails/MyPupDetailsView";
import { ServiceDetailsBlockCard } from "modules/service-partners/components/ServiceDetails/ServiceDetailsBlockCard";
import { canCreateNewServicePartner } from "modules/service-partners/permissions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

export interface LayoutProps {
  serviceDetails: ServicePartnerPageDetailsDto;
  onDelete: () => Promise<unknown>;
}

export function Layout({ serviceDetails, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } =
    useDeleteModal<DeleteRequestParams>("service-delete-modal");
  const resolveImage = useImageResolver();
  const hasPermission = usePermission();

  const actions = useMemo(() => {
    const result: ContextMenuAction[] = [];

    if (serviceDetails.canEdit) {
      result.push({
        callback: () => {
          void navigate(routes.servicePartners.edit({ slug, id: serviceDetails.id }), { state: { from: "details" } });
        },
        text: t("common.action.edit"),
        dataTestId: "context-menu-edit-btn",
      });
    }
    if (serviceDetails.canDelete) {
      result.push({
        callback: () => {
          openDeleteModal({ id: serviceDetails.id });
        },
        text: t("common.action.delete"),
        dataTestId: "context-menu-delete-btn",
      });
    }

    return result;
  }, [serviceDetails.canEdit, serviceDetails.canDelete, serviceDetails.id, t, navigate, slug, openDeleteModal]);

  const bgImage = serviceDetails.headerImage || sessionUser.project.backgroundImage;
  const isServiceDescriptionShown =
    serviceDetails.type !== "iqbi" || serviceDetails.partnerProperties.iqbi?.status === "noConsent";

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.service-details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.service.list.title"),
              to: routes.servicePartners.list({ slug }),
            },
            {
              name: t("page.service-details.breadcrumbs.self"),
            },
          ]}
        />
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="50%">
          <div className="flex w-full flex-col gap-4">
            <ServiceDetailsBlockCard
              title={serviceDetails.title}
              subtitle={serviceDetails.subtitle}
              backgroundImage={bgImage ? resolveImage(bgImage, "intrinsic") : bgPlaceholder}
              thumbnailImage={serviceDetails.profileImage?.url}
              renderLabels={() => (
                <>
                  <Label key="service-type" theme="blue">
                    {serviceDetails.type}
                  </Label>
                  {serviceDetails.connection && (
                    <Label key="connection-name" theme="gray">
                      <Icon name={iconMarkerPin01} />
                      {serviceDetails.connection?.name}
                    </Label>
                  )}
                  {serviceDetails.hasAudience && sessionUser.isAdmin && (
                    <Label theme="blue">
                      <Icon size={16} name={iconUsersCheck} />
                    </Label>
                  )}
                </>
              )}
            >
              {(serviceDetails.phoneNumber || serviceDetails.email || actions.length > 0) && (
                <div className="flex w-full items-center justify-end gap-4">
                  {serviceDetails.phoneNumber && (
                    <a
                      className="flex cursor-pointer items-center justify-center rounded-full bg-grey-100 p-4 hover:bg-grey-300 [&>svg]:stroke-aop-dark-blue-500 hover:[&>svg]:stroke-blue-800"
                      href={`tel:${serviceDetails.phoneNumber}`}
                    >
                      <Icon name={iconPhone} size={24} />
                    </a>
                  )}
                  {serviceDetails.email && (
                    <a
                      className="flex cursor-pointer items-center justify-around rounded-full bg-grey-100 p-4 hover:bg-grey-300 [&>svg]:stroke-aop-dark-blue-500 hover:[&>svg]:stroke-blue-800"
                      href={`mailto:${serviceDetails.email}`}
                    >
                      <Icon name={iconMail01} size={24} />
                    </a>
                  )}
                  <ContextMenu actions={actions} />
                </div>
              )}
              {serviceDetails.audience.length > 0 &&
                sessionUser.isAdmin &&
                hasPermission(canCreateNewServicePartner) && (
                  <AudiencePreview audience={serviceDetails.audience} readOnly />
                )}
              {isServiceDescriptionShown && (
                <div
                  className="prose whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html: serviceDetails.description!,
                  }}
                />
              )}
            </ServiceDetailsBlockCard>

            {serviceDetails.type === "iqbi" && <IqbiDetailsView {...{ serviceDetails }} />}
            {serviceDetails.type === "myPup" && serviceDetails.partnerProperties.myPup && (
              <MyPupDetailsView {...{ serviceDetails }} />
            )}
          </div>
        </PageGrid.Item>
      </PageGrid.Grid>
      <DeleteModal<DeleteRequestParams>
        title={t("model.service.action.delete.confirmation")}
        description={t("model.service.action.delete.description")}
        onDelete={onDelete}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}

interface DeleteRequestParams {
  id: string;
}
