import { twResolve } from "helpers/tw-resolve";

interface Props {
  "data-testid"?: string;
  theme: "red" | "yellow" | "green" | "aopDarkBlue" | "blue" | "gray" | "purple";
  size?: "normal" | "small" | "big";
  center?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  isUppercase?: boolean;
}

export function Label({
  theme,
  size,
  center = false,
  onClick,
  children,
  "data-testid": dataTestId,
  isUppercase = true,
}: Props): React.ReactNode {
  const Component = onClick ? "button" : "span";

  return (
    <div className="inline-flex size-fit shrink-0 overflow-hidden rounded bg-white">
      <Component
        className={twResolve(
          "inline-flex min-h-8 w-full items-center justify-center gap-1.5 p-2 text-body-bold",
          getLabelStyling(theme),
          onClick ? "transition-all hocus:brightness-75" : undefined,
          size === "small" && "h-auto p-1 text-[10px] font-[600]",
          size === "big" && "h-auto",
          center ? "self-center" : "self-start",
          isUppercase ? "uppercase" : undefined,
        )}
        data-testid={dataTestId}
        onClick={onClick}
        type={onClick ? "button" : undefined}
      >
        {children}
      </Component>
    </div>
  );
}

function getLabelStyling(theme: Props["theme"]) {
  switch (theme) {
    case "red":
      return "bg-red-600/10 text-red-600";
    case "yellow":
      return "bg-yellow-600/10 text-yellow-600";
    case "green":
      return "bg-green-600/10 text-green-600";
    case "aopDarkBlue":
      return "bg-aop-dark-blue-500/10 text-aop-dark-blue-500";
    case "blue":
      return "bg-blue-600/10 text-blue-600";
    case "gray":
      return "bg-grey-700/10 text-grey-900";
    case "purple":
      return "bg-aop-bright-purple-500/10 text-aop-bright-purple-500";
    default:
      throw new Error(`Unknown theme: ${theme}`);
  }
}
