import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessProjectRolesAndPermissions } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import { RoleCreateOrEditPage } from "modules/roles/pages/CreateOrEdit";
import { RoleDetailsPage } from "modules/roles/pages/Details";
import { RoleListPage } from "modules/roles/pages/List";
import type { RouteObject } from "react-router";

import { canManageRoles } from "./permissions";
import { roleRoutes } from "./routes";

export const rolesRoute = [
  {
    path: roleRoutes.list,
    element: (
      <RequireAuth permission={canAccessProjectRolesAndPermissions}>
        <RoleListPage />
      </RequireAuth>
    ),
  },
  {
    path: roleRoutes.create,
    element: (
      <RequireAuth permission={canManageRoles}>
        <RoleCreateOrEditPage />
      </RequireAuth>
    ),
  },

  {
    path: roleRoutes.edit,
    element: (
      <RequireAuth permission={canManageRoles}>
        <RoleCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: roleRoutes.details,
    element: (
      <RequireAuth permission={canManageRoles}>
        <RoleDetailsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
