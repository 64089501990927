import { Switch, type SwitchProps } from "components/Switch/Switch";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

interface FormSwitchProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<SwitchProps, "isChecked" | "onChange"> {
  "data-testid"?: string;
  name: TName;
  defaultValue?: FieldPathValue<TFormValues, TName>;
  rules?: RegisterOptions<TFormValues, TName>;
}

export function FormSwitch<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  "data-testid": dataTestId,
  name,
  rules,
  defaultValue,
  ...props
}: FormSwitchProps<TFormValues, TName>): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, rules, defaultValue });

  return <Switch data-testid={dataTestId} {...field} {...props} isChecked={!!field.value} />;
}
