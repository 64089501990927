import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconCheck from "assets/icons/check.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import messageChatSquareIcon from "assets/icons/message-chat-square.svg";
import iconPlus from "assets/icons/plus.svg";
import iconPower01 from "assets/icons/power-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import award from "assets/images/award.svg";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { StaticButton } from "components/Button/StaticButton";
import { ToggleButton } from "components/Button/ToggleButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { EntityDetailsCard } from "components/EntityDetailsCard/EntityDetailsCard";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Label } from "components/Label/Label";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { UserAvatarList } from "components/UserAvatarList/UserAvatarList";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { orderBy } from "lodash-es";
import { canClone, canCreateInterestGroup } from "modules/community-groups/permissions";
import { CommunityPoll } from "modules/messages/pages/List/components/CommunityPoll";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost/CommunityPost";
import { CommunitySurvey } from "modules/messages/pages/List/components/CommunitySurvey";
import { ResidentCreatePostModal } from "modules/messages/pages/List/components/ResidentCreatePost/ResidentCreatePost";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import type { ApiQueryParams, ApiResponseType } from "types/api-types";

import { GroupMembersModal } from "../GroupMembersModal";

const COMMUNITY_FEED_ITEM_AMOUNT = 20;

export function GroupDetailsPage(): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { id: groupId } = useParams<{ id: string }>();
  const { i18n, t } = useTranslation();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const sessionUser = useSessionUser();
  const slug = useSlug();
  const hasPermission = usePermission();
  const navigate = useNavigate();
  const [isGroupMembersModalOpened, groupMembersModalOpenHandlers] = useBool();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("group-delete-modal");
  const [disableGroupId, setDisableGroupId] = useState<string | null>(null);
  const [residentPostType, setResidentPostType] = useState<"askHelp" | "inform" | "sellItem" | "promote" | null>(null);

  const {
    data: groupDetails,
    isLoading: groupDetailsIsFetching,
    error: groupDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, groupId!),
    queryFn: () => api.getGroupsDetailsV2(groupId!),
    select: commonAPIDataSelector,
  });

  const enableGroup = useMutation({
    mutationFn: api.postGroupsEnableV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.enable.notification.success")
            : t("model.platform.interest-group.action.enable.notification.success"),
        "data-testid": "toast-group-is-enabled",
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.enable.notification.error")
            : t("model.platform.interest-group.action.enable.notification.error"),
      });
    },
  });

  const disableGroup = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.notification.success")
            : t("model.platform.interest-group.action.disable.notification.success"),
        "data-testid": "toast-group-is-disabled",
      });
      void navigate(
        groupDetails?.type === "helpCategory"
          ? routes.helpCategories.list({ slug })
          : routes.interestGroups.list({ slug }),
      );
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.notification.error")
            : t("model.platform.interest-group.action.disable.notification.error"),
      });
    },
  });

  const skipPromote = useMutation({
    mutationFn: () => api.postGroupsSkipPromotionV1(groupId!),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, groupId!) });
    },
  });

  const { mutate: follow } = useMutation({
    mutationFn: (id: string) => api.postGroupsFollowV1(id),
    onMutate: (id) => {
      queryClient.setQueryData<ApiResponseType<"getGroupsDetailsV2"> | undefined>(
        QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, id),
        (data) => {
          if (data) {
            return {
              ...data,
              data: {
                ...data.data,
                isMember: true,
              },
            };
          }
        },
      );
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.follow.notification-error")
            : t("model.interest-group.action.follow.notification-error"),
      });
    },
  });

  const { mutate: unfollow } = useMutation({
    mutationFn: (id: string) => api.postGroupsUnfollowV1(id),
    onMutate: (id) => {
      queryClient.setQueryData<ApiResponseType<"getGroupsDetailsV2"> | undefined>(
        QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, id),
        (data) => {
          if (data) {
            return {
              ...data,
              data: {
                ...data.data,
                isMember: false,
              },
            };
          }
        },
      );
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.unfollow.notification-error")
            : t("model.interest-group.action.unfollow.notification-error"),
      });
    },
  });

  const deleteGroupMutationResult = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.delete.notification.success")
            : t("model.interest-group.action.delete.notification.success"),
        "data-testid": "toast-group-is-deleted",
      });
      void navigate(
        groupDetails?.type === "helpCategory"
          ? routes.helpCategories.list({ slug })
          : routes.interestGroups.list({ slug }),
      );
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title:
          groupDetails?.type === "helpCategory"
            ? t("model.help-category.action.delete.notification.error")
            : t("model.interest-group.action.delete.notification.error"),
      });
    },
  });

  const messageFeedQuery = {
    Tab: "all",
    Connection: "included",
    GroupIds: [groupId!],
    FeedTypes: ["messages", "polls", "surveys"],
  } satisfies ApiQueryParams<"getCommunityFeedV2">;
  const {
    data: messageData,
    hasNextPage: hasMoreMessages,
    fetchNextPage: fetchMoreMessages,
    isPending: isLoadingMessages,
    isFetchingNextPage: isLoadingMoreMessages,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_FEED(projectId, messageFeedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getCommunityFeedV2({
          ...messageFeedQuery,
          Limit: COMMUNITY_FEED_ITEM_AMOUNT,
          Offset: pageParam * COMMUNITY_FEED_ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });
  const messages = useMemo(() => messageData?.pages.flatMap((x) => x.items) ?? [], [messageData]);
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreMessages && hasMoreMessages) {
        void fetchMoreMessages();
      }
    }, [fetchMoreMessages, hasMoreMessages, isLoadingMoreMessages]),
  });

  const residentPostCreationOptions = useMemo(() => {
    const actions: ContextMenuAction[] = [];
    if (!groupDetails?.id || (!groupDetails.canCreatePost && !groupDetails.canCreateAnnouncement)) {
      return actions;
    }

    if (groupDetails.id === sessionUser.marketPlaceGroupId) {
      actions.push({
        text: t("page.group-details.resident-create-message-options.marketplace"),
        callback: () => setResidentPostType("sellItem"),
      });
    } else {
      if (groupDetails.type !== "helpCategory") {
        actions.push({
          text: t("page.group-details.resident-create-message-options.inform"),
          callback: () => setResidentPostType("inform"),
        });
      }

      actions.push({
        text: t("page.group-details.resident-create-message-options.help"),
        callback: () => setResidentPostType("askHelp"),
      });
    }

    return actions;
  }, [
    groupDetails?.canCreateAnnouncement,
    groupDetails?.canCreatePost,
    groupDetails?.id,
    groupDetails?.type,
    sessionUser.marketPlaceGroupId,
    t,
  ]);

  const error = groupDetailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isPageLoading = groupDetailsIsFetching;
  if (isPageLoading || !groupDetails) {
    return <FullSizeLoader withPadding />;
  }

  const image = groupDetails.image ? groupDetails.image : sessionUser.project.backgroundImage;

  const listRoute = groupDetails.type === "helpCategory" ? routes.helpCategories.list : routes.interestGroups.list;
  const editRoute =
    // eslint-disable-next-line no-nested-ternary
    groupDetails.type === "helpCategory"
      ? routes.helpCategories.edit
      : groupDetails.isResidentGroup
        ? routes.interestGroups.editResidentGroup
        : routes.interestGroups.edit;
  const duplicateRoute =
    groupDetails.type === "helpCategory" ? routes.helpCategories.create : routes.interestGroups.create;

  // eslint-disable-next-line no-nested-ternary
  const tag = !sessionUser.isAdmin
    ? undefined
    : // eslint-disable-next-line no-nested-ternary
      groupDetails.type === "realEstate"
      ? t("page.discovery.group-type.real-estate")
      : // eslint-disable-next-line no-nested-ternary
        groupDetails.projectConnectionGroupId
        ? t("page.discovery.group-type.project-connection")
        : // eslint-disable-next-line no-nested-ternary
          groupDetails.systemGroupId
          ? t("page.discovery.group-type.platform")
          : undefined;

  const actions: ContextMenuAction[] = [];

  if (groupDetails.canEdit) {
    actions.push({
      dataTestId: "context-menu-edit-btn",
      text: t("common.action.edit"),
      icon: <Icon name={iconEdit05} size={16} />,
      callback: () => void navigate(editRoute({ slug, id: groupDetails.id })),
    });
  }
  if (
    hasPermission(canClone) &&
    !groupDetails.systemGroupId &&
    !groupDetails.projectConnectionGroupId &&
    !groupDetails.isResidentGroup
  ) {
    actions.push({
      dataTestId: "context-menu-duplicate-btn",
      text: t("common.action.duplicate"),
      icon: <Icon name={iconCopy01} size={16} />,
      callback: () => void navigate(duplicateRoute({ slug }), { state: { groupId: groupDetails.id } }),
    });
  }
  if (groupDetails.canDelete && (groupDetails.systemGroupId || groupDetails.projectConnectionGroupId)) {
    if (groupDetails.disabled) {
      actions.push({
        text: t("common.action.enable"),
        icon: <Icon name={iconPower01} size={16} />,
        callback: () => {
          enableGroup.mutate(groupDetails.id);
        },
      });
    } else {
      actions.push({
        text: t("common.action.disable"),
        icon: <Icon name={iconPower01} size={16} />,
        callback: () => {
          setDisableGroupId(groupDetails.id);
        },
      });
    }
  }
  if (groupDetails.canDelete && !groupDetails.systemGroupId && !groupDetails.projectConnectionGroupId) {
    actions.push({
      dataTestId: "context-menu-delete-btn",
      text: t("common.action.delete"),
      icon: <Icon name={iconTrash02} size={16} />,
      callback: () => {
        openDeleteModal(groupDetails.id);
      },
    });
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.group-details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.group-details.breadcrumbs.discovery"),
            },
            {
              name:
                groupDetails.type === "helpCategory"
                  ? t("page.help-category.list.title")
                  : t("page.interest-group.list.title"),

              to: listRoute({ slug }),
            },
            { name: groupDetails.name },
          ]}
        />
      }
      actions={
        // eslint-disable-next-line no-nested-ternary
        groupDetails.canCreatePost || groupDetails.canCreateAnnouncement ? (
          // eslint-disable-next-line no-nested-ternary
          sessionUser.isAdmin ? (
            <>
              <Button
                type="link"
                href={routes.messageFeed.adminCreatePost({ slug })}
                state={{ groupId: groupDetails.id }}
                className="hidden lg:flex"
                styling="primary"
                data-testid="group-action-create-message"
              >
                {t("page.group-details.action.create-message")}
              </Button>
              <IconButton
                styling="primary"
                className="md:hidden"
                title={t("page.group-details.action.create-message")}
                onClick={() =>
                  void navigate(routes.messageFeed.adminCreatePost({ slug }), { state: { groupId: groupDetails.id } })
                }
                isCircular
              >
                <Icon name={iconPlus} size={24} />
              </IconButton>
            </>
          ) : residentPostCreationOptions.length === 1 ? (
            <>
              <Button
                className="hidden lg:flex"
                data-testid="create-message"
                onClick={residentPostCreationOptions[0].callback}
              >
                {t("page.group-details.action.create-message")}
              </Button>
              <IconButton
                styling="primary"
                className="md:hidden"
                title={t("page.group-details.action.create-message")}
                onClick={residentPostCreationOptions[0].callback}
                isCircular
              >
                <Icon name={iconPlus} size={24} />
              </IconButton>
            </>
          ) : (
            <ContextMenu actions={residentPostCreationOptions}>
              {(props) => (
                <>
                  <Button
                    className="hidden lg:flex"
                    data-testid="create-message"
                    onClick={props.openHandlers.toggle}
                    isPressed={props.isOpen}
                  >
                    {t("page.group-details.action.create-message")}
                  </Button>
                  <IconButton
                    styling="primary"
                    className="md:hidden"
                    title={t("page.group-details.action.create-message")}
                    onClick={props.openHandlers.toggle}
                    isPressed={props.isOpen}
                    isCircular
                  >
                    <Icon name={iconPlus} size={24} />
                  </IconButton>
                </>
              )}
            </ContextMenu>
          )
        ) : null
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="50%">
          <div className="flex w-full flex-col gap-6">
            <EntityDetailsCard
              title={groupDetails.name}
              description={groupDetails.description}
              images={image ? [image] : []}
              actions={actions}
              renderCtaButton={() =>
                groupDetails.isOwner && groupDetails.isResidentGroup ? (
                  <StaticButton>
                    <div data-testid="group-admin-btn" className="flex items-center gap-1">
                      <img className="size-4" src={award} alt="" />
                      <span>{t("model.interest-group.owner")}</span>
                    </div>
                  </StaticButton>
                ) : (
                  <ToggleButton
                    config={{
                      checked: {
                        label:
                          groupDetails.type === "helpCategory"
                            ? t("model.help-category.action.unfollow")
                            : t("model.interest-group.action.unfollow"),
                        icon: <Icon name={iconCheck} />,
                      },
                      unchecked: {
                        label:
                          groupDetails.type === "helpCategory"
                            ? t("model.help-category.action.follow")
                            : t("model.interest-group.action.follow"),
                      },
                    }}
                    className="w-full sm:w-auto sm:max-w-full"
                    data-testid="group-action-follow-unfollow"
                    onClick={() => {
                      if (groupDetails.isMember) {
                        unfollow(groupDetails.id);
                      } else {
                        follow(groupDetails.id);
                      }
                    }}
                    isChecked={groupDetails.isMember}
                  />
                )
              }
              renderLabels={() => (tag ? <Label theme="gray">{tag}</Label> : null)}
              renderBody={() => (
                <div className="flex w-full flex-col items-start gap-4">
                  {groupDetails.audience &&
                    groupDetails.audience.length > 0 &&
                    sessionUser.isAdmin &&
                    hasPermission(canCreateInterestGroup) && (
                      <AudiencePreview audience={groupDetails.audience} readOnly />
                    )}
                  {!groupDetails.disabled && (
                    <div className="flex flex-wrap items-center justify-between gap-1">
                      <UserAvatarList
                        images={orderBy(groupDetails.members.items, (x) => !!x.avatar).map((x) => x.avatar)}
                        limit={3}
                        totalElements={groupDetails.members.total}
                        onClick={groupMembersModalOpenHandlers.toggle}
                      />
                    </div>
                  )}
                </div>
              )}
              renderFooter={() =>
                groupDetails.lastActivityAt && (
                  <span className="text-caption text-grey-500">
                    {t("component.community-post.last-activity", {
                      time: formatDistance(i18n, { start: new Date(groupDetails.lastActivityAt) }),
                    })}
                  </span>
                )
              }
            />
            {groupDetails.isOwner && groupDetails.canPromote && groupDetails.hasPromotionBanner ? (
              <div className="flex flex-col items-center gap-4 rounded-lg bg-white p-4">
                <Icon name={messageChatSquareIcon} size={16} />
                <span className="text-headline3 leading-old-headline4">{t("page.group-details.promote.label")}</span>
                <div className="flex items-center">
                  <Button data-testid="promote-group-button" onClick={() => setResidentPostType("promote")}>
                    {t("page.group-details.promote.button")}
                  </Button>
                </div>
                <Button styling="tertiary" className="underline" onClick={() => skipPromote.mutateAsync()}>
                  {t("common.action.skip")}
                </Button>
              </div>
            ) : null}
            {
              // eslint-disable-next-line no-nested-ternary
              isLoadingMessages ? (
                <FullSizeLoader withPadding size="small" />
              ) : messages.length === 0 ? (
                <p>{t("page.group-details.no-messages")}</p>
              ) : (
                <>
                  <ul className="flex flex-col gap-5">
                    {messages.map((message) =>
                      // eslint-disable-next-line no-nested-ternary
                      message.type === "message" && message.message ? (
                        <li key={message.message.id} data-testid="community-post">
                          <CommunityPost message={message.message} isExpanded={false} />
                        </li>
                      ) : // eslint-disable-next-line no-nested-ternary
                      message.type === "poll" && message.poll ? (
                        <li key={message.poll.id} data-testid="poll">
                          <CommunityPoll poll={message.poll} />
                        </li>
                      ) : message.type === "survey" && message.survey ? (
                        <li key={message.survey.id} data-testid="survey">
                          <CommunitySurvey survey={message.survey} updatedAt={message.updatedAt} />
                        </li>
                      ) : null,
                    )}
                  </ul>
                  {hasMoreMessages && (
                    <div className="p-4" ref={ref}>
                      <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                    </div>
                  )}
                </>
              )
            }
          </div>
        </PageGrid.Item>
      </PageGrid.Grid>
      <DeleteModal<string>
        title={
          groupDetails.type === "helpCategory"
            ? t("model.help-category.action.delete.confirmation")
            : t("model.interest-group.action.delete.confirmation")
        }
        description={
          groupDetails.type === "helpCategory"
            ? t("model.help-category.action.delete.description")
            : t("model.interest-group.action.delete.description")
        }
        onDelete={deleteGroupMutationResult.mutateAsync}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        title={
          groupDetails.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.confirmation")
            : t("model.platform.interest-group.action.disable.confirmation")
        }
        description={
          groupDetails.type === "helpCategory"
            ? t("model.platform.help-category.action.disable.description")
            : t("model.platform.interest-group.action.disable.description")
        }
        theme="danger"
        onOpenChange={(state) => {
          if (!state) {
            setDisableGroupId(null);
          }
        }}
        onReject={() => setDisableGroupId(null)}
        onResolve={async () => {
          await disableGroup.mutateAsync(disableGroupId!);
          setDisableGroupId(null);
        }}
        resolveBtnProps={{
          text: t("common.action.disable"),
          "data-testid": "group-disable-modal-disable",
        }}
        isOpened={disableGroupId != null}
        shouldCloseOnEsc
        isLoading={disableGroup.isPending}
      />
      <ResidentCreatePostModal
        isOpened={!!residentPostType}
        onOpenChange={(state) => {
          if (!state) {
            setResidentPostType(null);
          }
        }}
        postType={residentPostType}
        group={groupDetails}
      />
      <GroupMembersModal
        group={groupDetails}
        isOpened={isGroupMembersModalOpened}
        onOpenChange={groupMembersModalOpenHandlers.set}
      />
    </DocumentPaper>
  );
}
