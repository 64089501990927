import type { FeelingAtHomeDataMonthlyDto, FeelingAtHomeWidgetV2Dto } from "api/types";
import { isDefined } from "helpers/util";

export const isFahLastMonthAvailable = (data: FeelingAtHomeWidgetV2Dto): boolean => {
  return !!data.lastMonthFeelingAtHomeScore && data.lastMonthFeelingAtHomeScore.answerCount > 0;
};

export const isFahLastSixMonthsAvailable = (data: FeelingAtHomeDataMonthlyDto[]): boolean => {
  const monthsWithData = data.filter(
    (stats) => isDefined(stats.totalFeelingAtHomeScore) && stats.totalFeelingAtHomeScore.rating > 0,
  );

  return monthsWithData.length >= 2;
};
