import { VideoFilePreview } from "components/VideoFilePreview/VideoFilePreview";
import { type FormVideo } from "components/VideoInput/useVideoInput";

import type { MediaBaseProps } from "./Media";
import { Media } from "./Media";

export interface VideoMediaProps extends MediaBaseProps {
  video: FormVideo | undefined;
}

export function VideoMedia({ video, ...props }: VideoMediaProps): React.ReactNode {
  if (video) {
    return <VideoFilePreview onDelete={props.onDelete} isInvalid={!!props["aria-invalid"]} {...{ video }} />;
  }

  return <Media type="video" {...props} />;
}
