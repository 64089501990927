import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useSuperTokensUserId } from "authentication/AuthenticationStateProvider";
import { addDays } from "date-fns";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, updateLocalStorage } from "hooks/useLocalStorage";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useState } from "react";

import { commonAPIDataSelector } from "../helpers/Network/selectors";

declare global {
  interface Window {
    Intercom: { (key: string, ...args: any[]): void; q: any[]; c: (args: IArguments) => void };
    intercomSettings: IntercomSettings;
  }
}

interface IntercomSettings {
  app_id: string;
  api_base: string;
  name: string;
  email: string;
  user_id: string;
  user_hash: string;
  company?: {
    id: string;
    name: string;
  };
}

interface IntercomTokenCache {
  expires: number;
  hmac: string;
}

export function IntercomProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const superTokensUserId = useSuperTokensUserId();
  const user = useSessionUser();
  const api = useApi();
  const intercomDashboardAppId = useConfig("intercomDashboardAppId");
  const envLongName = useConfig("envLongName");
  const intercomLocalStorageKey = `${envLongName}.intercom-security-st.${user.email}`;
  const [cachedIntercomToken, setCachedIntercomToken] = useState<string>();
  const [enabled, enabledHandlers] = useBool();

  useEffect(() => {
    if (!envLongName || !isDefined(intercomDashboardAppId) || user.role.type === "resident") {
      return;
    }

    const value = getLocalStorageValue<IntercomTokenCache | undefined>(intercomLocalStorageKey, undefined);
    if (value && new Date(value.expires) < new Date()) {
      setCachedIntercomToken(value.hmac);
    } else {
      enabledHandlers.setTrue();
    }
  }, [enabledHandlers, envLongName, intercomDashboardAppId, intercomLocalStorageKey, user.role.type]);

  const { data } = useQuery({
    queryKey: QUERY_KEYS.INTERCOM_IDENTITY,
    queryFn: api.getIntercomIdentityV1,
    enabled,
    select: commonAPIDataSelector,
  });

  useEffect(() => {
    if (data?.hmac) {
      updateLocalStorage(intercomLocalStorageKey, {
        expires: addDays(new Date(), 3).valueOf(),
        hmac: data.hmac,
      } as IntercomTokenCache);
      enabledHandlers.setFalse();
    }
  }, [data?.hmac, enabledHandlers, intercomLocalStorageKey]);

  const token = cachedIntercomToken ?? data?.hmac;

  useEffect(() => {
    if (intercomDashboardAppId && superTokensUserId && token) {
      initIntercom({
        app_id: intercomDashboardAppId,
        api_base: "https://api-iam.intercom.io",
        name: user.fullName,
        email: user.email,
        user_id: superTokensUserId,
        user_hash: token,
        company: {
          id: user.project.id,
          name: user.project.name,
        },
      });
    }
  }, [intercomDashboardAppId, token, user, superTokensUserId]);

  return children;
}

// Slightly modified instance of default provided script by Intercom
function initIntercom(settings: IntercomSettings): void {
  window.intercomSettings = settings;

  if (typeof window.Intercom === "function") {
    window.Intercom("reattach_activator");
    window.Intercom("update", window.intercomSettings);
  } else {
    window.Intercom = function () {
      // eslint-disable-next-line prefer-rest-params
      window.Intercom.c(arguments);
    } as typeof window.Intercom;
    window.Intercom.q = [];
    window.Intercom.c = function (args) {
      window.Intercom.q.push(args);
    };
    const load = function () {
      setTimeout(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;
        script.src = "https://widget.intercom.io/widget/" + settings.app_id;
        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode!.insertBefore(script, firstScript);
      }, 0);
    };
    if (document.readyState === "complete") {
      load();
    } else {
      window.addEventListener("load", load, false);
    }
  }
}
