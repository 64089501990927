import { Popover as ArkPopover } from "@ark-ui/react";
import iconDotsVertical from "assets/icons/dots-vertical.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import type { UseBoolHandlers } from "hooks/useBool";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface ContextMenuAction {
  text: string;
  dataTestId?: string;
  icon?: React.ReactNode;
  callback: () => void;
  status?: {
    disabled: boolean;
    disabledText?: string;
  };
}

export interface ContextMenuProps {
  actions: ContextMenuAction[];
  children?: (props: { isOpen: boolean; openHandlers: UseBoolHandlers }) => React.ReactNode;
}

export const ContextMenu = memo(function ContextMenu({ actions, ...props }: ContextMenuProps): React.ReactNode {
  const { t } = useTranslation();
  const [isOpen, openHandlers] = useBool();

  useKey("Escape", openHandlers.setFalse, actions.length > 0);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ArkPopover.Root
      open={isOpen}
      onInteractOutside={openHandlers.setFalse}
      positioning={{
        placement: "right-start",
        offset: {
          // For the icon button we want to have the context menu closer to the icon button as it's transparent.
          // All other buttons should have the context menu a bit away from the button.
          mainAxis: props.children ? 5 : -5,
          crossAxis: -25,
        },
      }}
      unmountOnExit
      lazyMount
    >
      <div data-testid="context-menu">
        {props.children == null ? (
          <IconButton
            // When context menu is open we dont want to show the tooltip anymore
            // Otherwise the tooltip shows up after you click to open the context menu
            title={isOpen ? "" : t("component.context-menu.action.open")}
            isPressed={isOpen}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isOpen) {
                openHandlers.setFalse();
              } else {
                openHandlers.setTrue();
              }
            }}
            className="border-none bg-transparent hover:bg-transparent"
          >
            <Icon name={iconDotsVertical} size={24} />
          </IconButton>
        ) : (
          props.children({ isOpen, openHandlers })
        )}
        <ArkPopover.Positioner className="!z-50">
          <ArkPopover.Content asChild>
            <div
              className={twJoin(
                "min-w-36 rounded border border-grey-100 bg-white text-black shadow-md",
                "repeat-1 data-[placement=left-end]:origin-bottom-right data-[placement=left-start]:origin-top-right data-[placement=right-end]:origin-bottom-left data-[placement=right-start]:origin-top-left",
                "data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:slide-in-from-top-0.5",
                "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:slide-out-to-top-0.5",
              )}
            >
              <div data-testid="context-menu-action-list" className="flex flex-col">
                {actions.map((action) => (
                  <button
                    key={action.text}
                    className="flex w-full items-center gap-2 px-3 py-1 text-left transition-colors duration-100 hover:bg-blue-100 focus-visible:bg-blue-100 focus-visible:outline-none disabled:cursor-default disabled:bg-white disabled:text-grey-400 disabled:[&_svg]:stroke-grey-400"
                    type="button"
                    data-testid={action.dataTestId}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      action.callback();
                      openHandlers.setFalse();
                    }}
                    disabled={action.status?.disabled}
                  >
                    {action.icon}
                    {action.text}
                  </button>
                ))}
              </div>
            </div>
          </ArkPopover.Content>
        </ArkPopover.Positioner>
        <ArkPopover.Anchor />
      </div>
    </ArkPopover.Root>
  );
});
