import iconNarrowDown from "assets/icons/arrow-narrow-down.svg";
import iconNarrowRight from "assets/icons/arrow-narrow-right.svg";
import iconArrowsUp from "assets/icons/arrows-up.svg";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useTranslation } from "react-i18next";

type AdoptionWidgetScoreReferenceLabelProps = {
  currValue: number;
  referenceValue: number;
};

export const AdoptionWidgetScoreReferenceLabel = ({
  currValue,
  referenceValue,
}: AdoptionWidgetScoreReferenceLabelProps): React.ReactNode => {
  const { t } = useTranslation();

  const difference = Math.ceil(currValue - referenceValue);
  let label = t("component.analytics-widget.adoption.trend.neutral.description");
  let icon: string = iconNarrowRight;
  let theme: "green" | "yellow" | "blue" = "blue";
  if (difference > 0) {
    icon = iconArrowsUp;
    theme = "green";
    label = t("component.analytics-widget.adoption.trend.positive.description", {
      amount: `${difference}%`,
    });
  } else if (difference < 0) {
    icon = iconNarrowDown;
    theme = "yellow";
    label = t("component.analytics-widget.adoption.trend.negative.description", {
      amount: `${difference}%`,
    });
  }

  return (
    <Label isUppercase={false} {...{ theme }}>
      <div className="flex items-center gap-2 text-center">
        <Icon name={icon} />
        {label}
      </div>
    </Label>
  );
};
