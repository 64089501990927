import iconAlertCircle from "assets/icons/alert-circle.svg";
import { Icon } from "components/Icon/Icon";
import type { ScalingTextAreaProps } from "components/ScalingTextArea/ScalingTextArea";
import { ScalingTextArea } from "components/ScalingTextArea/ScalingTextArea";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import type { ChangeEvent } from "react";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface FormTextAreaProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends ScalingTextAreaProps {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  onChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  "data-testid"?: string;
  compactHeight?: boolean;
  large?: boolean;
  scrollbar?: boolean;
  defaultValue?: FieldPathValue<TFormValues, TName>;
}

export function FormTextArea<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  "data-testid": dataTestid,
  name,
  rules,
  defaultValue,
  className,
  compactHeight,
  large,
  scrollbar,
  readOnly,
  disabled,
  ...props
}: FormTextAreaProps<TFormValues, TName>): React.ReactNode {
  const [isFocused, isFocusHandlers] = useBool(false);
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({ name, rules, defaultValue });
  const maxLengthValue = typeof rules?.maxLength === "object" ? rules.maxLength.value : undefined;
  const isError = error != null;
  const currentValueLength = `${field.value || ""}`.length || 0;

  return (
    <FormErrorWrapper
      name={name}
      className={twResolve("flex", className)}
      subtext={
        maxLengthValue != null &&
        isFocused && (
          <>
            <span className={Number(maxLengthValue) < currentValueLength ? "text-red-600" : undefined}>
              {currentValueLength}
            </span>
            /{maxLengthValue}
          </>
        )
      }
    >
      <ScalingTextArea
        data-testid={dataTestid}
        {...props}
        className={twResolve(
          scrollbar ? "no-scrollbar" : undefined,
          "min-h-[6.75rem] w-full rounded-lg border border-grey-300 p-2 leading-input placeholder:text-grey-500 hover:border-grey-700 focus-visible:border-grey-900 focus-visible:outline-none",
          (disabled || readOnly) && "border-grey-300 bg-grey-100 text-grey-700 hover:border-grey-300",
          isError && "border-red-600  pr-8",
          compactHeight && "min-h-20",
          large && "min-h-72",
          props.icon && "pl-8",
          props.toolbar && "rounded-t-none",
        )}
        {...{ ...field, disabled }}
        value={field.value as any}
        onFocus={(e) => {
          props.onFocus?.(e);
          isFocusHandlers.setTrue();
        }}
        onBlur={(e) => {
          field.onBlur();
          props.onBlur?.(e);
          isFocusHandlers.setFalse();
        }}
        onChange={(e) => {
          field.onChange(e);
          props.onChange?.(e);
        }}
        aria-invalid={isError}
      />
      {isError && <Icon name={iconAlertCircle} className="absolute right-2 top-1/2 -translate-y-1/2 text-red-600" />}
    </FormErrorWrapper>
  );
}
