import { Icon54 } from "components/Icon54/Icon54";
import type { GroupIconType } from "modules/community-groups/components/GroupIcons";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";

import type { MediaBaseProps } from "./Media";
import { Media } from "./Media";

export interface IconMediaProps extends MediaBaseProps {
  charCode: number | undefined;
}

export function IconMedia({ charCode, ...props }: IconMediaProps): React.ReactNode {
  return (
    <Media {...props}>
      {typeof charCode === "number" ? (
        <Icon54
          className="absolute left-0 top-0 flex size-full items-center justify-center object-cover"
          charCode={charCode}
          disableUserSelect
        />
      ) : null}
    </Media>
  );
}

interface GroupIconMediaProps extends MediaBaseProps {
  icon: GroupIconType | undefined;
}

export function GroupIconMedia({ icon, ...props }: GroupIconMediaProps): React.ReactNode {
  return (
    <Media {...props}>
      {icon ? <GroupIcon size={48} className="flex size-full items-center justify-center p-2" icon={icon} /> : null}
    </Media>
  );
}
