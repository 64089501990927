import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { TicketStatusDetailsDto } from "api/types";
import iconArrowRight from "assets/icons/arrow-right.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { orderBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import { canManageStatuses } from "../permissions";
import { getTicketStatusTranslation } from "../util/ticketStatusHelper";

export function TicketStatusListPage(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("ticket-status-delete-modal");
  const [statusToBeReplaced, setStatusToBeReplaced] = useState<TicketStatusDetailsDto>();

  const { data, error, isLoading } = useQuery({
    queryKey: QUERY_KEYS.TICKET_STATUSES(projectId),
    queryFn: () => api.getTicketStatusesV1(),
    select: commonAPIDataSelector,
  });

  const deleteTicketStatus = useMutation({
    mutationFn: ({ id, replacementStatusId }: { id: string; replacementStatusId?: string }) =>
      api.deleteTicketStatusesByIdV1(id, { replacementStatusId }),
    onSuccess() {
      showFlashToast({
        type: "success",
        title: t("page.ticket-status.list.action.delete.notification.success"),
      });
    },
    onError() {
      showFlashToast({
        type: "error",
        title: t("page.ticket-status.list.action.delete.notification.error"),
      });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_STATUSES(projectId) });
    },
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<TicketStatusDetailsDto>();

    return [
      helper.accessor("name", {
        header: t("page.ticket-status.list.name"),
        cell: (cell) => {
          const statusName = cell.getValue();

          return hasPermission(canManageStatuses) ? (
            <Anchor to={routes.ticketStatuses.edit({ slug, id: cell.row.original.id })}>
              <TicketStatus description={statusName} labelColor={cell.row.original.color} />
            </Anchor>
          ) : (
            <TicketStatus description={statusName} labelColor={cell.row.original.color} />
          );
        },
      }),
      helper.accessor("type", {
        header: t("page.ticket-status.list.type"),
        cell: (cell) => <span className="whitespace-nowrap">{getTicketStatusTranslation(t, cell.getValue())}</span>,
      }),
      helper.accessor("isDefault", {
        header: t("page.ticket-status.list.is-default"),
        cell: (cell) => (
          <span className="whitespace-nowrap">
            {cell.getValue()
              ? t("page.ticket-status.list.is-default.true")
              : t("page.ticket-status.list.is-default.false")}
          </span>
        ),
      }),
      helper.accessor("notificationIntervalInHours", {
        header: t("page.ticket-status.list.notification-interval"),
        cell: (cell) => {
          const value = cell.getValue();
          if (!value) {
            return <span />;
          }

          return <span>{t("page.ticket-status.list.notification-interval.value", { count: value })}</span>;
        },
      }),
      helper.accessor("timesUsed", {
        header: t("page.ticket-status.list.times-used"),
        cell: (cell) => <span>{t("page.ticket-status.list.times-used.value", { count: cell.getValue() })}</span>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          if (!hasPermission(canManageStatuses)) {
            return null;
          }

          const id = cell.getValue();

          const actions: ContextMenuAction[] = [
            {
              callback: () => void navigate(routes.ticketStatuses.edit({ slug, id })),
              text: t("common.action.edit"),
            },
            {
              callback: () => {
                if (cell.row.original.timesUsed > 0) {
                  setStatusToBeReplaced(cell.row.original);
                } else {
                  openDeleteModal(id);
                }
              },
              text: t("common.action.delete"),
              status: {
                disabled: !!data && data.items.filter((x) => x.type === cell.row.original.type).length <= 1,
                disabledText: t("page.ticket-status.list.need-at-least-one-of-type"),
              },
            },
          ];

          return (
            <div className="flex justify-end">
              <ContextMenu actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [hasPermission, data, slug, navigate, openDeleteModal, t]);
  const tableInstance = useReactTable<TicketStatusDetailsDto>({
    columns,
    data: data?.items ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.ticket-status.list.title")}
      subTitle={
        <div className="flex flex-col gap-2">
          {t("page.ticket-status.list.subtitle")}
          <Breadcrumbs
            pages={[
              {
                name: t("page.tickets.title"),
                to: routes.tickets.list({ slug }),
              },
              {
                name: t("page.ticket-status.list.title"),
              },
            ]}
          />
        </div>
      }
      actions={
        hasPermission(canManageStatuses) ? (
          <Button type="link" href={routes.ticketStatuses.create({ slug })} data-testid="create-status-link">
            {t("page.ticket-status.list.action.create")}
          </Button>
        ) : null
      }
    >
      <DeleteModal
        title={t("page.ticket-status.list.action.delete.confirmation")}
        description={t("page.ticket-status.list.action.delete.description")}
        onDelete={(id) => deleteTicketStatus.mutateAsync({ id })}
        {...deleteModalProps}
      />
      {data ? (
        <StatusChangeModal
          isOpened={isDefined(statusToBeReplaced)}
          originalStatus={statusToBeReplaced}
          possibleStatuses={data.items}
          onOpenChange={() => setStatusToBeReplaced(undefined)}
          onSubmit={async (replacementStatusId) => {
            if (!statusToBeReplaced) {
              return;
            }

            await deleteTicketStatus.mutateAsync({ id: statusToBeReplaced.id, replacementStatusId });
          }}
        />
      ) : null}
      <div className="overflow-auto">
        <Table
          table={tableInstance}
          data-testid="ticket-status-list"
          getRowProps={() => ({
            "data-testid": "ticket-status-item",
          })}
        />
      </div>
    </DocumentPaper>
  );
}

type StatusChangeModalProps = ModalBaseProps & {
  originalStatus?: TicketStatusDetailsDto;
  possibleStatuses: TicketStatusDetailsDto[];
  onSubmit: (replacementStatusId: string) => Promise<void> | void;
};

function StatusChangeModal({
  isOpened,
  onOpenChange,
  onSubmit,
  originalStatus,
  possibleStatuses,
}: StatusChangeModalProps): React.ReactNode {
  const { t } = useTranslation();
  const [submitting, submittingHandlers] = useBool();
  const [replacementStatus, setReplacementStatus] = useState<TicketStatusDetailsDto>();

  useEffect(() => {
    if (originalStatus) {
      setReplacementStatus(
        orderBy(
          possibleStatuses.filter((x) => x.id !== originalStatus.id),
          // Order by same type first, then by whether its default, otherwise use inProgress status
          [(x) => x.type !== originalStatus.type, (x) => !x.isDefault, (x) => x.type !== "inProgress"],
        )[0],
      );
    }
  }, [originalStatus, possibleStatuses]);

  return (
    <Modal.Root
      title={t("page.ticket-status.list.replacement-modal.title")}
      description={t("page.ticket-status.list.replacement-modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <div className="mb-7 mt-6 flex w-full items-center justify-center gap-6">
        {originalStatus ? (
          <>
            <div className="shrink-0">
              <TicketStatus description={originalStatus.name} labelColor={originalStatus.color} />
            </div>
            <span className="shrink-0 text-grey-600">
              <Icon name={iconArrowRight} size={16} />
            </span>
          </>
        ) : null}
        <div className="shrink-0">
          <Select
            selected={replacementStatus}
            disabled={submitting}
            items={possibleStatuses.filter((x) => x.id !== originalStatus?.id)}
            onChange={setReplacementStatus}
            keySelector={(x) => x.id}
            renderOption={(status) => <TicketStatus description={status.name} labelColor={status.color} />}
          />
        </div>
      </div>
      {submitting ? (
        <LoadingIcon className="mx-auto mb-2 mt-4 w-8" />
      ) : (
        <div className="mt-4">
          <Button
            styling="secondary"
            onClick={() => onOpenChange(false)}
            data-testid="ticket-status-replacement-modal-reject"
          >
            {t("common.action.no-thanks")}
          </Button>
          <span className="mr-4" />
          <Button
            styling="danger"
            onClick={async () => {
              try {
                if (!replacementStatus) {
                  return;
                }

                submittingHandlers.setTrue();
                await onSubmit(replacementStatus.id);
              } finally {
                submittingHandlers.setFalse();
                onOpenChange(false);
              }
            }}
            data-testid="ticket-status-replacement-modal-confirm"
          >
            {t("page.ticket-status.list.replacement-modal.confirm")}
          </Button>
        </div>
      )}
    </Modal.Root>
  );
}
