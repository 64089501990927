import type { VideoMediaProps } from "components/Media/VideoMedia";
import { VideoMedia } from "components/Media/VideoMedia";
import { checkFileSize, MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES } from "helpers/file-size";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import { isDefined } from "helpers/util";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { range } from "lodash-es";
import type { ChangeEvent, ForwardedRef } from "react";
import type React from "react";
import { forwardRef, useRef, useState } from "react";

import type { FormVideo } from "./useVideoInput";
import { isVideoUploaded, useVideoInput } from "./useVideoInput";

export interface VideoInputProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, "id" | "aria-invalid"> {
  ref?: ForwardedRef<HTMLInputElement>;
  value: FormVideo[];
  onChange: (files: FormVideo[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  theme?: "light" | "dark";
  texts?: VideoMediaProps["texts"];
  nOfVideos?: number;
  disabled?: boolean;
  shouldProcessVideo?: boolean;
}

export const VideoInput = forwardRef<HTMLInputElement, VideoInputProps>(function VideoInput(
  { value: videos, onChange, theme = "light", texts, nOfVideos = 1, shouldProcessVideo, ...props },
  ref,
) {
  const [isDragging, setIsDragging] = useState(false);

  const innerRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(innerRef, ref);
  const replacementIndex = useRef<number | undefined>(undefined);

  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name, progress }) => {
      onChange(
        [...videos].map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          }

          return video;
        }),
      );
    },
    shouldProcess: shouldProcessVideo,
  });
  const { addVideos, removeVideo, replaceVideo } = useVideoInput({
    selectedVideos: videos,
    maximumFiles: nOfVideos,
    onChange,
    uploadFn: uploadFormVideo,
  });

  const onOpenFileSelector = () => {
    if (!combinedRef.current) return;

    combinedRef.current.click();
  };

  const onRemoveFile = (idx: number) => {
    if (combinedRef.current) {
      removeVideo(videos[idx]);
      combinedRef.current.value = "";
      props.onBlur?.();
    }
  };

  const onEditFile = (idx: number) => {
    replacementIndex.current = idx;

    onOpenFileSelector();
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    if (isDefined(replacementIndex.current)) {
      replaceVideo(videos[replacementIndex.current], event.target.files[0]);
      replacementIndex.current = undefined;
    } else {
      addVideos(event.target.files);
    }
  };

  return (
    <div
      className="flex flex-wrap gap-2"
      onDrop={(event) => {
        if (event.dataTransfer.files && combinedRef.current) {
          combinedRef.current.files = event.dataTransfer.files;
          addVideos(event.dataTransfer.files);
          setIsDragging(false);
          props.onBlur?.();

          event.preventDefault();
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
      }}
      onDragOverCapture={() => setIsDragging(true)}
      onDragLeaveCapture={() => setIsDragging(false)}
    >
      {[...range(nOfVideos)].map((idx) => {
        const isHidden = videos?.length < idx;

        if (isHidden) return;

        return (
          <VideoMedia
            video={videos[idx]}
            data-testid="video-media"
            key="video-media-fragment"
            aria-invalid={
              props["aria-invalid"] ||
              (videos[idx] && checkFileSize(videos[idx], MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES))
            }
            onAdd={() => onOpenFileSelector()}
            onDelete={() => onRemoveFile(idx)}
            onEdit={() => onEditFile(idx)}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            theme={theme}
            texts={texts}
            {...props}
            className={isDragging ? "border-aop-basic-blue-500" : undefined}
            disabled={props.disabled}
          />
        );
      })}

      <input
        data-testid="video-input"
        tabIndex={-1}
        type="file"
        {...props}
        accept={ALLOWED_FILE_TYPES.VIDEO}
        className="sr-only"
        ref={combinedRef}
        onChange={onChangeFile}
        disabled={props.disabled}
      />
    </div>
  );
});
