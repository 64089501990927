import { useQuery } from "@tanstack/react-query";
import type { FeelingAtHomeSurveyQuestionV2Dto } from "api/types";
import type { AIAnalysisModalState } from "components/AIAnalysisModal/AIAnalysisModal";
import { AIAnalysisModal } from "components/AIAnalysisModal/AIAnalysisModal";
import type { ModalBaseProps } from "components/Modal/Modal";
import { useFahHistoryQueries } from "queries/fahHistory/queryOptions";
import type React from "react";
import { useTranslation } from "react-i18next";

type FahHistoryQuestionGroupAnswerSentimentModalProps = ModalBaseProps & {
  questionType: FeelingAtHomeSurveyQuestionV2Dto["category"];
};

export function FahHistoryQuestionGroupAnswerSentimentModal({
  questionType,
  isOpened,
  onOpenChange,
}: FahHistoryQuestionGroupAnswerSentimentModalProps): React.ReactNode {
  const { t } = useTranslation();

  const fahHistoryQueries = useFahHistoryQueries();
  const { data, isPending, isError } = useQuery({
    ...fahHistoryQueries.getQuestionSummary(questionType),
    enabled: isOpened,
  });

  let state: AIAnalysisModalState = "loading";
  if (!isPending && !isError && data) {
    state = "success";
  } else if (!isPending && isError) {
    state = "error";
  } else if (!isPending && !isError && !data) {
    state = "unavailable";
  }

  return (
    <AIAnalysisModal
      title={t("component.fah-history.sentiment-modal.title")}
      data={{
        sentiment: data?.sentiment || 0,
        summary: data?.summary || "",
        suggestion: data?.suggestion,
        hashtags: data?.hashtags,
      }}
      config={{
        success: {
          title: t("page.automated-surveys.sentiment.modal.title"),
        },
        unavailable: {
          title: t("page.automated-surveys.sentiment.no-data.notification"),
        },
        error: {
          title: t("page.automated-surveys.sentiment.failed.notification"),
        },
      }}
      {...{ state, isOpened, onOpenChange }}
    />
  );
}
