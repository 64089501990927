import type { VictoryThemeDefinition } from "victory";

export const fontStyling = {
  fontFamily:
    "Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  fontSize: 12,
} as const;

export const dataColors = {
  // V1
  primary: "#23175A",
  primaryHoverBackground: "#009fe3",
  benchmark: "#009fe3",
  benchmarkDark: "#0077b0",
  tickColor: "#5f5f86",
  background: {
    chart: "#f1f1f5",
    tooltip: "#4a4a68",
  },
  axis: "#9797B5",
  orange: "#ff9500",
  mint: "#00c7be",
  purple: "#af52de",
} as const;

export const chartTheme: VictoryThemeDefinition = {
  dependentAxis: {
    style: {
      grid: {
        opacity: 0.08,
        background: dataColors.background.chart,
      },
    },
  },
  independentAxis: {
    style: {
      grid: {
        display: "none",
      },
    },
  },
};
