import { queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type { ApiQueryParams } from "types/api-types";

type DateRangeValue = "last6Months" | "lastYear";

export const useAnalyticsQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  return {
    getFah: () =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_FEELING_AT_HOME(projectId),
        queryFn: () => api.getAnalyticsFeelingAtHomeV1(),
        select: commonAPIDataSelector,
        staleTime: Infinity,
        gcTime: Infinity,
      }),
    getFahStats: () =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_FEELING_AT_HOME_STATS(projectId),
        queryFn: () => api.getAnalyticsFeelingAtHomeV1(),
        select: commonAPIDataSelector,
        staleTime: Infinity,
        gcTime: Infinity,
      }),
    getFahMonthlyStats: (dateRange: DateRangeValue) =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_FEELING_AT_HOME_MONTHLY(projectId, dateRange),
        queryFn: () => api.getAnalyticsFeelingAtHomeMonthlyV1({ dateRange }),
        select: commonAPIDataSelector,
        staleTime: Infinity,
        gcTime: Infinity,
      }),
    getFahDetails: (query: ApiQueryParams<"getAnalyticsFeelingAtHomeDetailsV1">) =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_FEELING_AT_HOME_DETAILS(projectId, query),
        queryFn: () => api.getAnalyticsFeelingAtHomeDetailsV1(query),
        select: commonAPIDataSelector,
      }),
    getContribution: (dateRange: DateRangeValue) =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_ACTIVE_CONTRIBUTORS(projectId, dateRange),
        queryFn: () => api.getAnalyticsEngagedResidentsMonthlyV2({ dateRange }),
        select: commonAPIDataSelector,
      }),
    getAdoption: () =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_ADOPTION(projectId),
        queryFn: () => api.getAnalyticsAdoptionRateV1(),
        select: commonAPIDataSelector,
      }),
    getAdoptionDetails: (dateRange: DateRangeValue) =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_DETAILS_ADOPTION(projectId, dateRange),
        queryFn: () => api.getAnalyticsAdoptionRateDetailsDetailsV2("adoption", { dateRange }),
        select: commonAPIDataSelector,
      }),
    getVacantAddresses: (dateRange: DateRangeValue) =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_DETAILS_ADOPTION_VACANT(projectId, dateRange),
        queryFn: () => api.getAnalyticsAdoptionRateDetailsDetailsV2("vacant", { dateRange }),
        select: commonAPIDataSelector,
      }),
    getEngagement: () =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_ENGAGED_RESIDENTS(projectId),
        queryFn: () => api.getAnalyticsResidentEngagementV1(),
        select: commonAPIDataSelector,
      }),
    getEngagementDetails: (dateRange: DateRangeValue) =>
      queryOptions({
        queryKey: QUERY_KEYS.ANALYTICS_DETAILS_ENGAGEMENT(projectId, dateRange),
        queryFn: () => api.getAnalyticsResidentEngagementDetailsV2({ dateRange }),
        select: commonAPIDataSelector,
      }),
    getWidgets: () =>
      queryOptions({
        queryKey: QUERY_KEYS.DATA_WIDGETS_LIST(projectId),
        queryFn: () => api.getWidgetsActiveV1(),
        select: commonAPIDataSelector,
      }),
  };
};
