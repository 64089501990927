import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { FahHistory } from "./pages";
import { fahHistoryRoutes } from "./routes";

export const fahHistoryRoute = [
  {
    path: fahHistoryRoutes.history,
    element: <FahHistory />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
