import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type { ApiQueryParams } from "types/api-types";

export const useTicketQueries = () => {
  const api = useApi();
  const projectId = useProjectId();

  const limit = 30;

  return {
    listPerProject: (query: ApiQueryParams<"getAdminTicketsV1">) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.TICKETS_FEED(projectId, query),
        queryFn: ({ pageParam = 0 }) =>
          api
            .getAdminTicketsV1({
              ...query,
              Limit: limit,
              Offset: pageParam * limit,
            })
            .then(commonAPIDataSelector),
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      }),
    listPerPortfolio: (query: ApiQueryParams<"getAdminPortfolioTicketsV1">) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.PORTFOLIO_TICKETS_LIST(query),
        queryFn: ({ pageParam = 0 }) =>
          api
            .getAdminPortfolioTicketsV1({
              ...query,
              Limit: limit,
              Offset: pageParam * limit,
            })
            .then((items) => commonAPIDataSelector(items)),
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      }),
    details: (ticketId: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
        queryFn: () => api.getAdminTicketsDetailsV1(ticketId),
        select: commonAPIDataSelector,
      }),
    responsibilitySuggestion: (ticketId: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.TICKETS_RESPONSIBILITY_SUGGESTION(projectId, ticketId),
        queryFn: () => api.getAdminTicketsResponsibilitySuggestionV1(ticketId),
        select: commonAPIDataSelector,
        retry: false,
        staleTime: Infinity,
      }),
    denialReplySuggestion: (ticketId: string, data: { additionalInfo?: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.TICKETS_DENIAL_REPLY_SUGGESTION(projectId, ticketId),
        queryFn: () => api.postTicketsSuggestionV1(ticketId, data),
        select: commonAPIDataSelector,
        staleTime: 30 * 60 * 1000,
      }),
    ratingFeedbackOptions: () =>
      queryOptions({
        queryKey: QUERY_KEYS.TICKET_RATING_FEEDBACK_OPTIONS(projectId),
        queryFn: () => api.getTicketsFeedbackOptionsV1(),
        select: commonAPIDataSelector,
        staleTime: Infinity,
      }),
  };
};
