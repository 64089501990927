import { SegmentGroup as ArkSegmentGroup } from "@ark-ui/react";
import type React from "react";

export type Segment<TValue extends string = string> = {
  label: string;
  value: TValue;
};

type SegmentGroupProps<TValue extends string = string> = {
  segments: Segment<TValue>[];
  value: string;
  onChange: (value: TValue) => void;
};

export function SegmentGroup<TValue extends string = string>({
  segments,
  value,
  onChange,
}: SegmentGroupProps<TValue>): React.ReactNode {
  // If no segments or the only segment is the active one, hide component
  if (segments.length === 0 || (segments.length === 1 && segments[0].value === value)) return null;

  return (
    <ArkSegmentGroup.Root
      orientation="horizontal"
      className="flex flex-wrap items-center gap-2"
      value={value}
      onValueChange={(details) => onChange(details.value as TValue)}
    >
      {segments.map((item) => (
        <ArkSegmentGroup.Item
          className="cursor-pointer rounded-full bg-grey-100 px-4 py-2 text-center text-body text-black transition-colors hover:bg-grey-300/60 data-[focus]:bg-grey-300/60 data-[state=checked]:bg-aop-dark-blue-500 data-[state=checked]:text-white"
          key={item.value}
          value={item.value}
        >
          <ArkSegmentGroup.ItemText>{item.label}</ArkSegmentGroup.ItemText>
          <ArkSegmentGroup.ItemHiddenInput />
        </ArkSegmentGroup.Item>
      ))}
    </ArkSegmentGroup.Root>
  );
}
