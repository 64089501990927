export function addOrRemoveFromArray<T>(array: T[], item: T): T[] {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  }

  return [...array, item];
}

export function isDefined<T>(x: T | undefined | null): x is T {
  return x != null;
}

const ELLIPSIS = "...";

export function isLongerThanCharLimit(text: string, limit: number): boolean {
  return text.length > limit;
}

export function limitTextByChar(text: string, limit: number): string {
  if (isLongerThanCharLimit(text, limit)) {
    return `${text.slice(0, limit)}${ELLIPSIS} `;
  }

  return text;
}

export function isLongerThanWordLimit(text: string, limit: number): boolean {
  return text.split(" ").length > limit;
}

export function limitTextByWord(text: string, limit: number): string {
  return `${text.split(" ").splice(0, limit).join(" ")}${ELLIPSIS} `;
}

export function parseAsNumber(value: string | undefined): number | undefined {
  if (value == null) {
    return undefined;
  }

  const result = Number(value);

  return isNaN(result) ? undefined : result;
}

export function sortAlphabetically<T extends { name: string }>(a: T, b: T): number {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  if (aName < bName) {
    return -1;
  } else if (aName > bName) {
    return 1;
  }

  return 0;
}
