import iconBell01 from "assets/icons/bell-01.svg";
import iconMail01 from "assets/icons/mail-01.svg";
import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import iconTool02 from "assets/icons/tool-02.svg";
import { Icon } from "components/Icon/Icon";
import type { NotificationPayload } from "components/Notification/Notification";

export function NotificationIcon({ type }: { type: NotificationPayload["type"] }): React.ReactNode {
  switch (type) {
    case "message":
    case "groupMessage":
      return <Icon name={iconMail01} size={20} />;
    case "messageComment":
      return <Icon name={iconMessageSquare02} size={20} />;
    case "ticket":
    case "ticketAssignee":
    case "ticketComment":
      return <Icon name={iconTool02} size={20} />;
    default:
      return <Icon name={iconBell01} size={20} />;
  }
}
