import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { EventDetailsDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconPlus from "assets/icons/plus.svg";
import iconTrash01 from "assets/icons/trash-01.svg";
import { AudiencePreview } from "components/AudienceSelector/AudiencePreview";
import { ToggleButton } from "components/Button/ToggleButton";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { EntityDetailsCard } from "components/EntityDetailsCard/EntityDetailsCard";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { isPast, parseISO } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageEvents } from "modules/events/permissions";
import { getEventCategoryName } from "modules/events/util";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import { EventDetailsInfoSection } from "./EventDetailsInfoSection";

interface EventDetailsProps {
  event: EventDetailsDto;
}

export function EventDetails({ event }: EventDetailsProps): React.ReactNode {
  const projectId = useProjectId();

  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const { componentProps, openDeleteModal } = useDeleteModal<{ id: string }>("event-delete-modal");
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const hasPermission = usePermission();

  const { data: eventCategories = [] } = useQuery({
    queryKey: QUERY_KEYS.EVENT_CATEGORIES(projectId),
    queryFn: () => api.getEventsCategoriesV1(),
    select: commonAPIDataSelector,
  });
  const { mutate: signUp, isPending: isSigningUp } = useMutation({
    mutationFn: () => api.postEventsSignupV1(event.id),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, event.id) });
    },
  });
  const { mutate: signOff, isPending: isSigningOff } = useMutation({
    mutationFn: () => api.postEventsSignoffV1(event.id),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, event.id) });
    },
  });
  const { mutateAsync: deleteEvent } = useMutation({
    mutationFn: () => api.deleteEventsByIdV1(event.id),
    onSuccess: () => {
      void navigate(routes.calendar.list({ slug }));
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId), refetchType: "none" });
    },
  });

  const resolvedImage = event.image ?? sessionUser.project.backgroundImage;
  const categoryDetails = event.category ? eventCategories.find(({ id }) => id === event.category?.id) : undefined;

  const actions: ContextMenuAction[] = useMemo(() => {
    const actions: ContextMenuAction[] = [];
    if (event.canEdit) {
      actions.push({
        dataTestId: "context-menu-edit-btn",
        text: t("common.action.edit"),
        icon: <Icon name={iconEdit05} className="text-black" size={16} />,
        callback: () => {
          if (isPast(parseISO(event.startsAt))) {
            showFlashToast({
              type: "info",
              title: t("model.event.action.edit.notification.is-started"),
            });

            return;
          }

          void navigate(routes.events.edit({ slug, id: event.id }));
        },
      });
    }
    if (hasPermission(canManageEvents)) {
      actions.push({
        dataTestId: "context-menu-duplicate-btn",
        text: t("common.action.duplicate"),
        icon: <Icon name={iconCopy01} size={16} />,
        callback: () => {
          void navigate(routes.events.create({ slug }), { state: { id: event.id } });
        },
      });
    }
    if (event.canDelete) {
      actions.push({
        dataTestId: "context-menu-delete-btn",
        text: t("common.action.delete"),
        icon: <Icon name={iconTrash01} size={16} />,
        callback: () => {
          openDeleteModal({ id: event.id });
        },
      });
    }

    return actions;
  }, [
    event.canDelete,
    event.canEdit,
    event.id,
    event.startsAt,
    hasPermission,
    navigate,
    openDeleteModal,
    showFlashToast,
    slug,
    t,
  ]);

  return (
    <>
      <EntityDetailsCard
        title={event.name}
        description={event.description}
        images={resolvedImage ? [resolvedImage] : []}
        actions={actions}
        renderLabels={() => (
          <>
            {event.deletedAt && <Label theme="red">{t("model.event.details.deleted")}</Label>}
            {categoryDetails && <Label theme="blue">{getEventCategoryName(t, categoryDetails.id)}</Label>}
            {event.projectConnection && (
              <Label key="connection-name" theme="gray">
                <Icon name={iconMarkerPin01} />
                {event.projectConnection.name}
              </Label>
            )}
            {!event.publishedAt && (
              <Label theme="blue">
                <div className="flex gap-1 whitespace-nowrap">
                  {t("model.event.details.scheduled")}
                  <FormattedDate date={event.publishAt} format="datetimeShort" />
                </div>
              </Label>
            )}
          </>
        )}
        renderCtaButton={() => (
          <ToggleButton
            isLoading={isSigningOff || isSigningUp}
            isChecked={event.isSignedUp}
            config={{
              checked: {
                label: t("model.event.details.action.sign-off"),
                icon: <Icon name={iconCheck} size={16} />,
              },
              unchecked: {
                label: t("model.event.details.action.sign-up"),
                icon: <Icon name={iconPlus} size={16} />,
              },
            }}
            disabled={
              !(event.isSignedUp || event.canParticipate) || isPast(parseISO(event.endsAt)) || !!event.deletedAt
            }
            data-testid="event-participation-toggle"
            onClick={() => {
              if (event.isSignedUp) {
                signOff();
              } else {
                signUp();
              }
            }}
          />
        )}
        renderBody={() => (
          <div className="flex w-full flex-col items-start gap-4">
            {event.audience.length > 0 && sessionUser.isAdmin && hasPermission(canManageEvents) && (
              <AudiencePreview audience={event.audience} readOnly />
            )}
            <EventDetailsInfoSection {...{ event }} />
          </div>
        )}
      />

      <DeleteModal
        title={t("model.event.action.delete.confirmation")}
        description={t("model.event.action.delete.description")}
        onDelete={() => deleteEvent()}
        {...componentProps}
      />
    </>
  );
}
