import { twResolve } from "helpers/tw-resolve";
import React from "react";
import { twJoin } from "tailwind-merge";

interface DefaultProps {
  "data-testid"?: string;
  children?: React.ReactNode;
}

interface ContentProps {
  title?: string;
  titleAs?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";
  renderTitle?: (title?: string) => React.ReactNode;
  subTitle?: React.ReactNode;
  actions?: React.ReactNode;
  header?: React.ReactNode;
  warning?: React.ReactNode;
  "data-testid"?: string;
}

type OldPaperProps = DefaultProps & ContentProps & { theme: "no-gaps"; className?: string };
type NewPaperProps = DefaultProps &
  ContentProps & { theme: "minimal" | "minimal-constrained" | "constrained" | "wide" };
type MinimalPaperProps = DefaultProps & { theme: "no-header-minimal" };

export type PaperProps = OldPaperProps | NewPaperProps | MinimalPaperProps;

export const Paper = React.forwardRef<HTMLDivElement, PaperProps>(function Paper(props, ref): React.ReactNode {
  if (props.theme === "no-header-minimal") {
    return <MinimalPaperTheme ref={ref} {...props} />;
  }

  if (props.theme === "no-gaps") {
    return <NoGapsPaperTheme ref={ref} {...props} />;
  }

  return <NewPaperTheme ref={ref} {...props} />;
});

const NoGapsPaperTheme = React.forwardRef<HTMLDivElement, Omit<OldPaperProps, "theme">>(function Paper(
  { children, title, titleAs, subTitle, actions, "data-testid": dataTestid, header, className },
  ref,
): React.ReactNode {
  const TitleComponent = titleAs ?? "h1";

  return (
    <section className={twResolve("relative flex w-full sm:p-4", className)} ref={ref} data-testid={dataTestid}>
      <div className="flex-1 bg-white sm:rounded-lg">
        <header className="mb-6 px-6 pt-4">
          <div
            className={twJoin(
              "flex flex-col flex-wrap justify-between gap-4 sm:flex-row sm:gap-8",
              header ? "mb-8" : undefined,
            )}
          >
            <div>
              <TitleComponent className="block text-headline2 md:text-headline1">{title}</TitleComponent>
              {subTitle == null ? null : <div className="block text-caption text-grey-700">{subTitle}</div>}
            </div>
            <div>{actions}</div>
          </div>
          {header}
        </header>
        <div className="px-6 pb-6">{children}</div>
      </div>
    </section>
  );
});

const NewPaperTheme = React.forwardRef<HTMLDivElement, NewPaperProps>(function Paper(
  { children, renderTitle, title, titleAs, subTitle, actions, "data-testid": dataTestid, header, warning, theme },
  ref,
): React.ReactNode {
  const TitleComponent = titleAs ?? "h1";

  return (
    <>
      {warning}
      <section className="relative mx-auto max-w-screen-3xl py-6" ref={ref} data-testid={dataTestid}>
        <header className="flex flex-wrap items-center justify-between gap-4 bg-white px-5 py-6 md:mx-8 lg:gap-8 xl:mx-10 xs:mx-5 xs:rounded-lg">
          <div className="max-w-full">
            <TitleComponent className="block max-w-full text-headline2 md:text-headline1">
              {renderTitle ? renderTitle(title) : title}
            </TitleComponent>
            {subTitle == null ? null : <div className="max-w-[840px] text-caption text-grey-700">{subTitle}</div>}
          </div>
          {actions && <div className="flex flex-wrap gap-4 self-start sm:self-center">{actions}</div>}
        </header>
        {header && (
          <div className="my-5 overflow-y-visible bg-white px-5 py-3 md:mx-8 xl:mx-10 xs:mx-5 xs:rounded-lg">
            {header}
          </div>
        )}
        {
          // eslint-disable-next-line no-nested-ternary
          theme === "constrained" ? (
            <div className="mt-5 max-w-[840px] bg-white px-5 py-4 md:mx-8 xl:mx-10 xs:mx-5 xs:rounded-lg">
              {children}
            </div>
          ) : theme === "wide" ? (
            <div className="mt-5 bg-white px-5 py-4 md:mx-8 xl:mx-10 xs:mx-5 xs:rounded-lg">{children}</div>
          ) : (
            <div
              className={twJoin(
                "mx-2 mt-5 border-t-0 border-aop-off-white md:mx-8 xl:mx-10 xs:mx-5",
                theme === "minimal-constrained" ? "max-w-[840px]" : undefined,
              )}
            >
              {children}
            </div>
          )
        }
      </section>
    </>
  );
});

const MinimalPaperTheme = React.forwardRef<HTMLDivElement, Omit<MinimalPaperProps, "theme">>(function Paper(
  { children, "data-testid": dataTestid },
  ref,
): React.ReactNode {
  return (
    <section
      ref={ref}
      data-testid={dataTestid}
      className="relative min-h-full border-1rem border-t-0 border-aop-off-white px-2 pt-5 md:px-4 lg:px-6"
    >
      {children}
    </section>
  );
});
