import type { ImageDto } from "api/types";

export const getAspectRatio = (image: ImageDto): number => {
  if (image.metadata.width && image.metadata.height) {
    return image.metadata.width / image.metadata.height;
  } else {
    return 16 / 9;
  }
};

export const formatVideoDuration = (durationInSeconds: number): string => {
  if (typeof durationInSeconds !== "number" || durationInSeconds < 0) {
    return "00:00";
  }

  let hours = Math.floor(durationInSeconds / 3600);
  let minutes = Math.floor((durationInSeconds % 3600) / 60);
  let seconds = Math.ceil(durationInSeconds % 60);

  // Adjust if rounding up seconds rolls over to the next unit
  if (seconds === 60) {
    seconds = 0;
    minutes += 1;
  }
  if (minutes === 60) {
    minutes = 0;
    hours += 1;
  }

  // Max duration is 99:59:59
  if (hours > 999) return "99:59:59";

  const formattedHours = hours > 0 ? String(hours).padStart(2, "0") + ":" : "";
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};
