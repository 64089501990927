import type { LanguageDto, TicketStatusDetailsDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormColorPicker } from "components/Form/FormColorPicker";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import type { FormTranslations } from "helpers/languages";
import { usePrevious } from "hooks/usePrevious";
import { getTicketStatusTranslation } from "modules/ticket-statuses/util/ticketStatusHelper";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  languages: LanguageDto[];
  isEditMode: boolean;
  defaultFormValues: Partial<FormValues>;
  isSubmitting: boolean;
  ticketStatuses: TicketStatusDetailsDto[];
  onSubmit: (data: FormValues) => void;
}

export function Layout({
  languages,
  ticketStatuses,
  isEditMode,
  defaultFormValues,
  onSubmit,
  isSubmitting,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({ defaultValues: defaultFormValues });
  const type = useWatch<FormValues, "type">({ control: formMethods.control, name: "type" });
  const previousType = usePrevious(type, undefined);

  const { setValue, getValues } = formMethods;
  useEffect(() => {
    if (type) {
      if (previousType && DEFAULT_COLORS[previousType] !== getValues("color")) {
        return;
      }

      const newColor = DEFAULT_COLORS[type];
      if (newColor) {
        setValue("color", newColor, { shouldDirty: false });
      }
    }
  }, [isEditMode, setValue, getValues, type, previousType]);

  return (
    <DocumentPaper
      theme="no-gaps"
      title={isEditMode ? t("page.ticket-status.edit.title") : t("page.ticket-status.create.title")}
    >
      <Form formMethods={formMethods} onSubmit={onSubmit} data-testid="ticket-status-form">
        <CreateOrEditForm
          isEditMode={isEditMode}
          languages={languages}
          ticketStatuses={ticketStatuses}
          isSubmitting={isSubmitting}
          defaultValues={defaultFormValues}
        />
      </Form>
    </DocumentPaper>
  );
}

function CreateOrEditForm({
  isEditMode,
  languages,
  isSubmitting,
  ticketStatuses,
  defaultValues,
}: {
  languages: LanguageDto[];
  isEditMode: boolean;
  isSubmitting: boolean;
  ticketStatuses: LayoutProps["ticketStatuses"];
  defaultValues: LayoutProps["defaultFormValues"];
}) {
  const { t } = useTranslation();

  const editTypeDisabled = isEditMode && ticketStatuses.filter((x) => x.type === defaultValues.type).length <= 1;

  return (
    <FormContent>
      {languages.map((lng) => (
        <FormField
          key={lng.id}
          htmlFor={`translations-${lng.id}`}
          label={`${t("page.ticket-status.form.name")} (${lng.poEditorCode})`}
          required
        >
          <FormInput<FormValues>
            className="max-w-sm"
            data-testid="ticket-status-name"
            id={`translations-${lng.id}`}
            name={`translations.${lng.id}`}
            rules={{
              required: {
                message: t("components.form.error.required", {
                  inputName: t("page.ticket-status.form.name"),
                }),
                value: true,
              },
              maxLength: {
                message: t("components.form.error.max-length", {
                  length: NAME_MAX_LENGTH,
                }),
                value: NAME_MAX_LENGTH,
              },
            }}
          />
        </FormField>
      ))}
      <FormField label={t("page.ticket-status.form.type")} required>
        <FormSelect<FormValues, FormValues["type"]>
          name="type"
          // Can't change type to rated (because we would need a rating)
          items={isEditMode && defaultValues.type !== "rated" ? TYPES.filter((x) => x !== "rated") : TYPES}
          placeholder={t("page.ticket-status.form.type.placeholder")}
          keySelector={(x) => x}
          disabled={editTypeDisabled}
          renderOption={(x) => getTicketStatusTranslation(t, x)}
          rules={{
            required: t("components.form.error.required", {
              inputName: t("page.ticket-status.form.type"),
            }),
          }}
        />
      </FormField>
      <FormField label={t("page.ticket-status.form.color")} htmlFor="color" required>
        <FormColorPicker<FormValues, "color"> name="color" id="color" />
      </FormField>
      <FormField
        label={t("page.ticket-status.form.default-in-status")}
        description={t("page.ticket-status.form.default-in-status.description")}
      >
        <FormCheckbox<FormValues> name="isDefault" label={t("page.ticket-status.form.default-in-status.label")} />
      </FormField>
      <FormField
        label={t("page.ticket-status.form.notification-interval")}
        description={t("page.ticket-status.form.notification-interval.description")}
      >
        <FormInput<FormValues> inputMode="numeric" type="number" name="notificationIntervalInHours" />
      </FormField>
      <Button type="submit" isLoading={isSubmitting}>
        {isEditMode ? t("page.ticket-status.edit.submit") : t("page.ticket-status.create.submit")}
      </Button>
    </FormContent>
  );
}

const DEFAULT_COLORS: Record<FormValues["type"], string> = {
  autoClosed: "#00FFFF",
  rejected: "#F90608",
  rated: "#3EC605",
  closed: "#3C9AFF",
  inProgress: "#FA7908",
  new: "#9E9E9E",
};

const TYPES = ["new", "inProgress", "closed", "rated", "rejected", "autoClosed"] as FormValues["type"][];

const NAME_MAX_LENGTH = 30;

export interface FormValues {
  translations: FormTranslations;
  type: TicketStatusDetailsDto["type"];
  isDefault: boolean;
  notificationIntervalInHours: string | undefined;
  color: string;
}
