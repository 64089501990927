import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type {
  ConnectedProjectDto,
  GenericAlertTriggeredDto,
  GenericAlertTriggeredDtoPaginationResultDto,
} from "api/types";
import iconMail01 from "assets/icons/mail-01.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ContentTabs } from "components/ContentTabs/ContentTabs";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  triggeredAlerts: GenericAlertTriggeredDtoPaginationResultDto;
  pageIndex: number;
  totalPages: number;
  projects: ConnectedProjectDto[];
  onPageChange: (page: number) => void;
  projectId?: string;
  previousProjectId?: string;
  onProjectChange: (tab: string | undefined) => void;
}

const MAX_PROJECT_TABS = 8;

export function Layout({
  triggeredAlerts,
  pageIndex,
  totalPages,
  projects,
  onPageChange,
  projectId,
  previousProjectId,
  onProjectChange,
}: LayoutProps): React.ReactNode {
  const connectedProjects = useConnectedProjects();
  const { t } = useTranslation();

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "alerts", status: "list" });

  const triggeredAlertsColumns = useMemo(() => {
    const helper = createColumnHelper<GenericAlertTriggeredDto>();

    return [
      helper.accessor("messageId", {
        header: t("page.alerts-overview.table.link"),
        cell: (cell) => {
          const projectId = cell.row.original.projectId;
          const content = cell.row.original.content;
          const slug = connectedProjects.data?.find((x) => x.id === projectId)?.slug;

          return (
            <div>
              <Anchor to={slug ? routes.messageFeed.details({ slug: slug, id: cell.getValue() }) : "#"}>
                <span className="relative block max-w-[400px] truncate">{content}</span>
              </Anchor>
            </div>
          );
        },
      }),
      helper.accessor("alertType", {
        header: t("page.alerts-overview.table.alert-type"),
        cell: (cell) => {
          function getTranslation() {
            switch (cell.getValue()) {
              case "commentKeyword":
                return t("page.alerts-overview.table.alert-type.comment-keyword");
              case "postKeyword":
                return t("page.alerts-overview.table.alert-type.post-keyword");
              case "likeThreshold":
                return t("page.alerts-overview.table.alert-type.likes-threshold");
              case "commentThreshold":
                return t("page.alerts-overview.table.alert-type.comments-threshold");
              default:
                return "-";
            }
          }

          return (
            <span>
              <span className="whitespace-nowrap">{getTranslation()}</span>
              {cell.row.original.matchedKeywords ? (
                <span className="ml-1 break-words text-caption text-grey-700">
                  ({cell.row.original.matchedKeywords})
                </span>
              ) : null}
            </span>
          );
        },
      }),
      helper.accessor("alertName", {
        header: t("page.alerts-overview.table.alert-name"),
      }),
      helper.accessor("projectName", {
        header: t("page.alerts-overview.table.project-name"),
      }),
      helper.accessor("sentAt", {
        header: t("page.alerts-overview.table.sent-at"),
        cell: (cell) => (
          <span className="flex items-center gap-2 whitespace-nowrap">
            <FormattedDate date={cell.getValue()} format="datetimeRelative" />
            {cell.row.original.emailSent ? (
              <span className="text-grey-700">
                <Icon name={iconMail01} size={16} />
              </span>
            ) : null}
          </span>
        ),
      }),
    ];
  }, [connectedProjects.data, t]);

  const commentKeywordsTableInstance = useReactTable({
    columns: triggeredAlertsColumns,
    data: triggeredAlerts.items,
    getCoreRowModel: getCoreRowModel(),
  });

  const tabs = useMemo(() => {
    const projectsWithPermission = projects.filter((x) => x.userRole !== "resident" && x.userRole !== "maintenance");

    return [
      {
        id: "all",
        name: "All",
      },
      ...(projectsWithPermission.length > MAX_PROJECT_TABS
        ? projectsWithPermission.filter((x) => x.id === projectId || x.id === previousProjectId)
        : projectsWithPermission.map((x) => ({
            id: x.id,
            name: x.name,
          }))),
    ];
  }, [projects, projectId, previousProjectId]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.alerts-overview.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      actions={
        <Button type="link" href={routes.alerts.settings()}>
          {t("page.alerts-overview.settings")}
        </Button>
      }
    >
      <ContentTabs
        activeTabId={projectId || "all"}
        tabs={tabs}
        onTabChange={(x) => onProjectChange(x === "all" ? undefined : x)}
      >
        {triggeredAlerts.items.length > 0 ? (
          <Table table={commentKeywordsTableInstance}>
            <Pagination count={totalPages} currentIndex={pageIndex} onChange={onPageChange} />
          </Table>
        ) : (
          <div className="px-4 py-6">
            <span className="text-caption">{t("page.alerts-overview.no-data")}</span>
          </div>
        )}
      </ContentTabs>
    </DocumentPaper>
  );
}
