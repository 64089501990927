import { ErrorPage } from "components/Error/ErrorPage";
import { useConfig } from "providers/ConfigProvider";
import type React from "react";

import { Layout } from "./layout";

export function FahHistory(): React.ReactNode {
  const isAccessFahHistoryAllowed = useConfig("isAccessFahHistoryAllowed");

  if (!isAccessFahHistoryAllowed) {
    return <ErrorPage status={404} />;
  }

  return <Layout />;
}
