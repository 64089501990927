import { useEffect, useRef } from "react";

function getStorageValue<T>(key: string, initialValue: T, session: boolean): T {
  try {
    const storageKey = formatStorageKey(key);
    const item = session ? window.sessionStorage.getItem(storageKey) : window.localStorage.getItem(storageKey);

    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.warn(`Error reading localStorage key '${key}':`, error);

    return initialValue;
  }
}

export function getSessionStorageValue<T>(key: string, initialValue: T): T {
  return getStorageValue(key, initialValue, true);
}

export function getLocalStorageValue<T>(key: string, initialValue: T): T {
  return getStorageValue(key, initialValue, false);
}

function updateStorage<T>(key: string, value: T, session: boolean): void {
  try {
    const storageKey = formatStorageKey(key);
    const valueString = JSON.stringify(value);
    if (session) {
      if (valueString) {
        window.sessionStorage.setItem(storageKey, valueString);
      } else {
        window.sessionStorage.removeItem(storageKey);
      }
    } else {
      if (valueString) {
        window.localStorage.setItem(storageKey, valueString);
      } else {
        window.localStorage.removeItem(storageKey);
      }
    }
  } catch (error) {
    console.warn(`Error setting storage key '${key}':`, error);
  }
}

export function updateLocalStorage<T>(key: string, value: T): void {
  return updateStorage(key, value, false);
}

export function updateSessionStorage<T>(key: string, value: T): void {
  return updateStorage(key, value, true);
}

function useUpdateStorage<T>(key: string, value: T, session: boolean): void {
  const didMountRef = useRef(false);

  useEffect(() => {
    // Don't update local storage on first mount, local storage value will not have changed
    if (didMountRef.current) {
      updateStorage(key, value, session);
    } else {
      didMountRef.current = true;
    }
  }, [key, value, session]);
}

export function useUpdateSessionStorage<T>(key: string, value: T): void {
  return useUpdateStorage(key, value, true);
}

export function clearLocalStorage(key: string): void {
  const storageKey = formatStorageKey(key);
  window.localStorage.removeItem(storageKey);
}

export function clearSessionStorage(key: string): void {
  const storageKey = formatStorageKey(key);
  window.sessionStorage.removeItem(storageKey);
}

export function useUpdateLocalStorage<T>(key: string, value: T): void {
  return useUpdateStorage(key, value, false);
}

function formatStorageKey(key: string): string {
  return `AoP.` + key;
}
