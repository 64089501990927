import type { FeelingAtHomeDataMonthlyDto } from "api/types";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { parseISO } from "date-fns";
import { createDateTickFormat } from "modules/analytics/components/utility/Axis";
import { ChartLegend } from "modules/analytics/components/utility/ChartLegend";
import { HoverLabel } from "modules/analytics/components/utility/HoverLabel";
import { dataColors } from "modules/analytics/theme";
import { formatChartDate } from "modules/analytics/util";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as V from "victory";

type FahWidgetTrendChartProps = {
  data: FeelingAtHomeDataMonthlyDto[];
};

const ANIMATION: V.AnimatePropTypeInterface = {
  onLoad: { duration: 50 }, // Needed to work around Victory chart animation initial delay issue
  duration: 1000,
  easing: "cubic",
};

const MAX_VISIBLE_Y = 10; // Only show Y axis tick labels up to this value
const MAX_Y = MAX_VISIBLE_Y + 2;
const MIN_Y = 0;
const MARGIN_Y = 2;

export const FahWidgetTrendChart = ({ data }: FahWidgetTrendChartProps): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const primaryChartData = useMemo(
    () =>
      data.map((stat) => ({
        x: formatChartDate({
          month: parseISO(stat.month).getMonth() + 1,
          year: parseISO(stat.month).getFullYear(),
        }),
        y: stat.totalFeelingAtHomeScore?.rating || null,
      })),
    [data],
  );

  const benchmarkChartData = useMemo(
    () =>
      data.map((stat) => ({
        x: formatChartDate({
          month: parseISO(stat.month).getMonth() + 1,
          year: parseISO(stat.month).getFullYear(),
        }),
        y: stat.benchmarkFeelingAtHomeScore?.rating || null,
      })),
    [data],
  );

  // Compute min and max Y based on min and max chart data Y value
  const maxBenchmarkValue = Math.max(...benchmarkChartData.map((dataPoint) => dataPoint.y ?? 0));
  const minBenchmarkValue = Math.min(...benchmarkChartData.map((dataPoint) => dataPoint.y ?? 10));
  const maxPrimaryValue = Math.max(...primaryChartData.map((dataPoint) => dataPoint.y ?? 0));
  const minPrimaryValue = Math.min(...primaryChartData.map((dataPoint) => dataPoint.y ?? 10));
  const minY = Math.floor(Math.min(minPrimaryValue, minBenchmarkValue));
  const maxY = Math.floor(Math.max(maxPrimaryValue, maxBenchmarkValue));

  return (
    <div className="relative flex flex-col gap-8">
      <p className="absolute left-1/2 top-4 z-10 w-full max-w-[80%] -translate-x-1/2 -translate-y-1/2 bg-white p-1 text-center text-caption text-grey-700 sm:ml-2 sm:text-body">
        {t("component.analytics-widget.fah-score.last-6-month.chart.description")}
      </p>

      <div ref={containerRef}>
        {/* Preserve component render order for correct paint order */}
        <V.VictoryChart
          theme={V.VictoryTheme.clean}
          domain={{ y: [Math.max(minY - MARGIN_Y, MIN_Y), Math.min(maxY + MARGIN_Y, MAX_Y)] }}
          containerComponent={<V.VictoryVoronoiContainer voronoiDimension="x" />}
          padding={{ top: 16, bottom: 32, left: 40, right: 20 }}
        >
          {/* X axis */}
          <V.VictoryAxis
            crossAxis
            tickFormat={createDateTickFormat(i18n, data.length, 1, "monthShort")}
            tickLabelComponent={<V.VictoryLabel className="text-overline text-grey-900" dy={4} />}
            style={{
              axis: {
                strokeWidth: 0.5,
                stroke: dataColors.axis,
              },
              grid: {
                strokeWidth: (tick: V.CallbackArgs) => {
                  if (tick.index === data.length - 1) {
                    return 0;
                  }

                  return 0.5;
                },
                stroke: dataColors.axis,
                strokeDasharray: "2, 2",
              },
              tickLabels: {
                fontWeight: (tick: V.CallbackArgs) => {
                  if (tick.index === data.length - 1) {
                    return 600;
                  }

                  return 400;
                },
              },
            }}
          />
          {/* Y axis */}
          <V.VictoryAxis
            dependentAxis
            tickLabelComponent={<V.VictoryLabel className="text-overline text-grey-900" />}
            style={{
              axis: {
                strokeWidth: 0.5,
                stroke: dataColors.axis,
                strokeDasharray: "2, 2",
              },
              grid: {
                strokeWidth: 0.5,
                stroke: dataColors.axis,
              },
              tickLabels: {
                opacity: ({ tick }: V.CallbackArgs) => {
                  return tick > MAX_VISIBLE_Y ? 0 : 1;
                },
              },
            }}
          />
          {/* Benchmark line chart */}
          <V.VictoryLine
            animate={ANIMATION}
            data={benchmarkChartData}
            style={{
              data: {
                zIndex: 0,
                strokeWidth: 4,
                stroke: dataColors.benchmark,
              },
            }}
          />
          {/* FaH area chart */}
          <V.VictoryArea
            animate={ANIMATION}
            data={primaryChartData}
            style={{
              data: {
                zIndex: 10,
                strokeWidth: 4,
                stroke: dataColors.primary,
                fill: "url(#area-background)",
              },
            }}
          />
          <V.VictoryGroup>
            {/* Data point highlight */}
            <V.VictoryScatter
              size={8}
              style={{
                data: {
                  fill: dataColors.primary,
                  opacity: ({ active }: V.CallbackArgs) => (active ? 0.3 : 0),
                  transition: "0.2s ease",
                },
              }}
              data={primaryChartData}
            />
            {/* Data point */}
            <V.VictoryScatter
              data={primaryChartData}
              size={4}
              style={{
                data: {
                  fill: "#fff",
                  strokeWidth: 1,
                  stroke: dataColors.primary,
                },
              }}
              labels={(d: { x: number; y: number }) => d.y}
              labelComponent={
                <V.VictoryTooltip
                  text=""
                  flyoutComponent={
                    <HoverLabel<(typeof primaryChartData)[0]>
                      offsetX={-8}
                      offsetY={20}
                      containerWidth={containerRef.current?.clientWidth ?? 0}
                    >
                      {(dataPoint, index) => (
                        <div key={index} className="flex flex-col gap-1">
                          <span className="text-caption-bold">
                            {t("component.analytics-widget.fah-score.last-6-month.chart.tooltip", {
                              score: dataPoint.y?.toFixed(1),
                            })}
                          </span>
                          <span className="text-caption">{formatDate(i18n, "monthYearShort", dataPoint.x)}</span>
                        </div>
                      )}
                    </HoverLabel>
                  }
                />
              }
            />
          </V.VictoryGroup>
          {/* Area chart gradient background */}
          <svg>
            <defs>
              <linearGradient id="area-background" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#0C275A3D" />
                <stop offset="100%" stopColor="#0C275A0A" />
              </linearGradient>
            </defs>
          </svg>
        </V.VictoryChart>
      </div>

      {/* Legend */}
      <ChartLegend
        items={[
          {
            label: t("component.analytics-widget.fah-score.last-6-month.chart.legend.primary"),
            color: dataColors.primary,
          },
          {
            label: t("component.analytics-widget.fah-score.last-6-month.chart.legend.benchmark"),
            color: dataColors.benchmark,
          },
        ]}
      />
    </div>
  );
};
