import type React from "react";

interface FahStatsCardProps {
  label: string;
  value: string | number;
}

export function FahStatsCard({ label, value }: FahStatsCardProps): React.ReactNode {
  return (
    <div className="flex min-w-40 flex-1 flex-col gap-2 rounded-lg bg-white p-4">
      <span className="whitespace-nowrap text-body-bold">{label}</span>
      <h3 className="text-headline2">{value}</h3>
    </div>
  );
}
