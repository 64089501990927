import type { VideoInputProps } from "components/VideoInput/VideoInput";
import { VideoInput } from "components/VideoInput/VideoInput";
import { useCombinedRefs } from "hooks/useCombinedRef";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import type { FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

interface FormVideoInputProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<VideoInputProps, "onChange" | "value"> {
  name: TName;
  rules?: RegisterOptions<TFormValues, TName>;
  className?: string;
  "data-testid"?: string;
  initialFileUrl?: string;
  nOfVideos?: number;
  theme?: VideoInputProps["theme"];
  texts?: { add: string };
  shouldProcessVideo?: boolean;
}

export const FormVideoInput = forwardRef(function FormVideoInput<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>(
  { "data-testid": dataTestid, name, rules, ...props }: FormVideoInputProps<TFormValues, TName>,
  ref: ForwardedRef<HTMLInputElement>,
): React.ReactNode {
  const {
    field,
    fieldState: { error },
  } = useController<TFormValues, TName>({
    name,
    rules,
    defaultValue: [] as any,
  });

  const combinedRef = useCombinedRefs(field.ref, ref);

  return (
    <FormErrorWrapper<TFormValues> name={name} className={props.className} data-testid={dataTestid}>
      <VideoInput
        {...props}
        {...field}
        ref={combinedRef}
        aria-invalid={error != null}
        value={field.value}
        onChange={field.onChange}
        onBlur={() => {
          field.onBlur();
          props.onBlur?.();
        }}
      />
    </FormErrorWrapper>
  );
}) as <TFormValues extends FieldValues, TName extends FieldPath<TFormValues> = FieldPath<TFormValues>>(
  p: FormVideoInputProps<TFormValues, TName> & { ref?: ForwardedRef<HTMLInputElement> },
) => React.ReactNode;
