import type { FeelingAtHomeDistributionDto } from "api/types";
import { twResolve } from "helpers/tw-resolve";
import type React from "react";
import { useTranslation } from "react-i18next";

interface FahWidgetDistributionTableProps {
  distributions: FeelingAtHomeDistributionDto[];
}

export function FahWidgetDistributionTable({ distributions }: FahWidgetDistributionTableProps): React.ReactNode {
  const { t } = useTranslation();

  const getCategoryLabel = (type: FeelingAtHomeDistributionDto["type"]) => {
    switch (type) {
      case "community":
        return t("common.entity.community");
      case "building":
        return t("common.entity.building");
      case "services":
        return t("common.entity.service.plural");
    }
  };

  const sortedDistributions = distributions.toSorted(
    (distributionA, distributionB) => distributionB.importance - distributionA.importance,
  );

  return (
    <table className="h-fit w-full max-w-[400px] border-separate border-spacing-y-4">
      <tbody>
        <tr className="[&>*:not(:first-child)]:text-right [&>*]:text-overline">
          <th />
          <th>{t("component.analytics-widget.fah-distribution.importance.label").toLowerCase()}</th>
          <th>{t("component.analytics-widget.fah-distribution.score.label").toLowerCase()}</th>
        </tr>
        {sortedDistributions.map((distribution) => {
          const labelImportance = distribution.importance
            ? `${(distribution.importance * 100).toFixed(0)}%`
            : t("common.label.not-applicable");
          const labelScore = distribution.score || "-";

          return (
            <tr
              className="align-text-bottom [&>*:not(:first-child)]:text-right [&>*]:leading-none"
              key={distribution.type}
            >
              <td className="text-headline3">{getCategoryLabel(distribution.type)}</td>
              <td
                className={twResolve(
                  "text-headline1-medium",
                  distribution.type === "community" && "text-indigo",
                  distribution.type === "building" && "text-mint",
                  distribution.type === "services" && "text-orange",
                )}
              >
                {labelImportance}
              </td>
              <td className="w-12 text-headline3">{labelScore}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
