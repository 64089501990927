import { Carousel as ArkCarousel, Portal as ArkPortal } from "@ark-ui/react";
import type { ImageDto } from "api/types";
import IconChevronLeft from "assets/icons/chevron-left.svg";
import IconChevronRight from "assets/icons/chevron-right.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { preloadImage } from "helpers/image";
import { useBool } from "hooks/useBool";
import { useHasMounted } from "hooks/useHasMounted";
import { useKey } from "hooks/useKey";
import { AnimatePresence, type AnimationProps, motion } from "motion/react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

type CarouselImage = {
  // Use type to guard in case of type mutation
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  url: ImageDto["url"] | FormImage["url"];
  description?: ImageDto["description"];
};

type CarouselProps = {
  images: CarouselImage[];
  defaultPage?: number;
  objectFit?: React.CSSProperties["objectFit"];
  carouselRef?: React.RefObject<HTMLDivElement>;
} & (
  | {
      styling: "overlay";
      allowZoom?: never;
    }
  | {
      styling?: "default";
      allowZoom?: boolean;
    }
);

export function Carousel({
  images,
  defaultPage,
  objectFit = "cover",
  allowZoom,
  styling = "default",
  carouselRef,
}: CarouselProps): React.ReactNode {
  const [activePage, setActivePage] = useState<number>();
  const hasMounted = useHasMounted();

  const [isZoomed, isZoomedHandlers] = useBool();

  // The ark UI carousel doesn't handle initial page well on PRODUCTION BUILDS ONLY, so we need to set it after mount
  useEffect(() => {
    if (!activePage) {
      setTimeout(() => {
        setActivePage((x) => x ?? defaultPage);
      }, 10);
    }
  }, [defaultPage, activePage]);

  const { t } = useTranslation();

  // Preload next slide
  useEffect(() => {
    const nextImage = images[(activePage || 0) + 1];

    if (nextImage) {
      preloadImage(nextImage.url);
    }
  }, [activePage, images]);

  useKey("Escape", isZoomedHandlers.setFalse);

  // Arrow key navigation only enabled for "overlay" styling
  useKey(
    "ArrowLeft",
    () => {
      setActivePage((activePage || 0) - 1);
    },
    activePage != null && activePage > 0 && isZoomed && styling === "overlay",
  );

  useKey(
    "ArrowRight",
    () => {
      setActivePage((activePage || 0) + 1);
    },
    activePage != null && activePage < images.length - 1 && isZoomed && styling === "overlay",
  );

  return (
    <>
      <ArkCarousel.Root
        className="relative size-full overflow-hidden"
        page={activePage}
        onPageChange={(details) => {
          // Check mounted because this fires on first render as 0 and we don't want to set activePage to 0
          if (details.page >= 0 && hasMounted) {
            setActivePage(details.page);
          }
        }}
      >
        <ArkCarousel.ItemGroup className="h-full" ref={carouselRef}>
          {images.map((image, index) => (
            <ArkCarousel.Item
              key={image.url}
              onClick={allowZoom ? isZoomedHandlers.setTrue : undefined}
              className={twJoin("relative bg-black", allowZoom && "cursor-zoom-in")}
              {...{ index }}
            >
              <img
                className="absolute inset-y-0 size-full"
                style={{ objectFit }}
                src={image.url}
                alt={image.description}
              />
            </ArkCarousel.Item>
          ))}
        </ArkCarousel.ItemGroup>
        <span className="pointer-events-none absolute bottom-0 left-0 z-0 h-16 w-full bg-gradient-to-t from-black/60 to-black/0" />

        <ArkCarousel.Control className="absolute left-0 top-1/2 flex w-full -translate-y-1/2 items-center justify-between px-2">
          <ArkCarousel.PrevTrigger asChild>
            <IconButton title={t("common.action.previous")} size="sm" withTooltip={false} styling="primary" isCircular>
              <Icon name={IconChevronLeft} />
            </IconButton>
          </ArkCarousel.PrevTrigger>
          <ArkCarousel.NextTrigger asChild>
            <IconButton title={t("common.action.next")} size="sm" withTooltip={false} styling="primary" isCircular>
              <Icon name={IconChevronRight} />
            </IconButton>
          </ArkCarousel.NextTrigger>
        </ArkCarousel.Control>

        <ArkCarousel.IndicatorGroup className="absolute bottom-0 left-1/2 z-10 flex -translate-x-1/2 items-center gap-2 px-2 py-3">
          {images.map((_, index) => (
            <ArkCarousel.Indicator onClick={() => setActivePage(index)} key={index} asChild {...{ index }}>
              <button
                type="button"
                className={twJoin(
                  "size-[10px] rounded-full transition",
                  index === activePage ? "bg-white" : "bg-black/60",
                )}
              />
            </ArkCarousel.Indicator>
          ))}
        </ArkCarousel.IndicatorGroup>
      </ArkCarousel.Root>
      {allowZoom && <ImageModal images={images} zoomedImageIndex={activePage} onClose={isZoomedHandlers.setFalse} />}
    </>
  );
}

interface ImageModalProps {
  zoomedImageIndex?: number;
  images: CarouselImage[];
  onClose?: () => void;
}

const commonAnimProps: AnimationProps = {
  initial: "initial",
  animate: "animate",
  exit: "initial",
  transition: { duration: 0.2 },
};

// In the same file, because it's a circular dependency (Carousel -> ImageModal -> Carousel)
export function ImageModal({ zoomedImageIndex, images, onClose }: ImageModalProps): React.ReactNode {
  const carouselRef = useRef<HTMLDivElement>(null);
  const zoomedImage = zoomedImageIndex != null && images[zoomedImageIndex];

  useEffect(() => {
    if (zoomedImage) {
      // Make sure the carousel allows using arrow keys when opened
      carouselRef.current?.focus();
    }
  }, [zoomedImage]);

  return (
    <ArkPortal>
      <AnimatePresence>
        {zoomedImage && (
          <motion.div className="fixed inset-0 z-50 flex min-h-screen scale-100 items-center justify-center overflow-y-auto">
            <motion.button
              className="absolute inset-0 z-0 cursor-zoom-out bg-black/80"
              type="button"
              onClick={onClose}
              {...commonAnimProps}
            />
            {images.length === 1 && (
              <motion.div
                {...commonAnimProps}
                className="relative h-4/5 w-full max-w-[80%] cursor-zoom-out overflow-hidden rounded-lg xl:max-w-screen-lg 2xl:max-w-screen-xl"
                onClick={onClose}
              >
                <img
                  className="size-full rounded-lg object-contain"
                  src={zoomedImage.url}
                  alt={("description" in zoomedImage && zoomedImage.description) || ""}
                />
              </motion.div>
            )}
            {images.length > 1 && (
              <motion.div
                className="pointer-events-none h-4/5 w-full max-w-[80%] xl:max-w-screen-lg 2xl:max-w-screen-xl"
                {...commonAnimProps}
              >
                <div className="pointer-events-auto size-full overflow-hidden rounded-lg">
                  <Carousel
                    carouselRef={carouselRef}
                    defaultPage={zoomedImageIndex}
                    styling="overlay"
                    objectFit="contain"
                    {...{ images }}
                  />
                </div>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </ArkPortal>
  );
}
