import { useInfiniteQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { QuestionGroupAnswerDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Table } from "components/Table/Table";
import { isDefined } from "helpers/util";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useAutomatedSurveyQueueQueries } from "queries/automatedSurveyQueues/queryOptions";
import type React from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface QuestionData {
  questionNum: number;
  ratingQuestion: string;
  openQuestion: string;
}

type QueueDetailsModalProps = ModalBaseProps & {
  questionId: string | undefined;
  questionData: QuestionData | null;
};

export function QueueDetailsModal({
  questionId,
  questionData,
  isOpened,
  onOpenChange,
}: QueueDetailsModalProps): React.ReactNode {
  const { id: queueId } = useParams<{ id: string }>();
  const { i18n, t } = useTranslation();
  const automatedSurveyQueueQueries = useAutomatedSurveyQueueQueries();

  const {
    data: answers,
    isPending: isPendingAnswers,
    hasNextPage: hasMoreAnswers,
    isFetchingNextPage: isLoadingMoreAnswers,
    fetchNextPage: fetchMoreAnswers,
    error: errorAnswers,
  } = useInfiniteQuery({
    ...automatedSurveyQueueQueries.getQuestionDetailsInfinite(queueId!, questionId!),
    enabled: !!queueId && !!questionId,
  });

  const questionDetails = useMemo(
    () => answers?.pages.flatMap((x) => x.questionGroupAnswers.items ?? []) ?? [],
    [answers],
  );
  const totalResponses = useMemo(() => answers?.pages[0].questionGroupAnswers.total, [answers]);
  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreAnswers && hasMoreAnswers) {
        void fetchMoreAnswers();
      }
    }, [fetchMoreAnswers, hasMoreAnswers, isLoadingMoreAnswers]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<QuestionGroupAnswerDto>();

    return [
      helper.accessor("rating", {
        header: t("component.surveys-question.details.table.header.rating"),
        cell: (cell) => (
          <span className="flex items-center gap-1 text-center">
            <span>{cell.getValue()}</span>
            <Icon name={iconStar01} className="shrink-0 text-yellow-500" />
          </span>
        ),
        maxSize: 32,
      }),
      helper.accessor("openAnswerText", {
        header: t("component.surveys-question.details.table.header.open"),
        cell: (cell) => <span className="block py-0.5">{cell.getValue()}</span>,
      }),
      helper.accessor("userName", {
        header: t("component.surveys-question.details.table.header.username"),
        cell: (cell) => <span className="block truncate">{cell.getValue()}</span>,
        maxSize: 100,
      }),
      helper.accessor("date", {
        header: t("component.surveys-question.details.table.header.date"),
        cell: (cell) => <span>{formatDate(i18n, "datetimeShort", cell.getValue()!)}</span>,
        maxSize: 80,
      }),
    ];
  }, [i18n, t]);

  const tableInstance = useReactTable<QuestionGroupAnswerDto>({
    columns,
    data: questionDetails,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!questionData || errorAnswers) {
    return null;
  }

  return (
    <Modal.Root
      size="fullscreen"
      title={`${questionData.questionNum}. ${questionData.ratingQuestion}`}
      description={questionData.openQuestion}
      {...{ isOpened, onOpenChange }}
    >
      {isPendingAnswers ? (
        <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
      ) : (
        <>
          <span className="text-caption">
            {t("component.surveys-question.details.responses", { count: totalResponses })}
          </span>
          {isDefined(totalResponses) && totalResponses > 0 && (
            <Table
              table={tableInstance}
              data-testid="survey-answer-list"
              getRowProps={() => ({
                "data-testid": "survey-answer-item",
              })}
            />
          )}
          {hasMoreAnswers && (
            <div className="p-4" ref={loaderRef}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </>
      )}
    </Modal.Root>
  );
}
