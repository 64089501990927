import { useQuery } from "@tanstack/react-query";
import { ErrorPage } from "components/Error/ErrorPage";
import { useSessionUser } from "hooks/Network/useSessionUser";
import type { DateRangeOption } from "modules/analytics/util";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { NonEmptyArray } from "types/utility-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const { t } = useTranslation();
  const { project } = useSessionUser();

  const dateRangeOptions = useMemo<NonEmptyArray<DateRangeOption>>(
    () => [
      {
        name: t("common.date-range.last-months", { count: 6 }),
        value: "last6Months",
      },
      {
        name: t("common.date-range.last-year"),
        value: "lastYear",
      },
    ],
    [t],
  );

  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);

  const queries = useAnalyticsQueries();
  const {
    data: adoptionData,
    isFetching: isFetchingAdoptionData,
    error: adoptionError,
  } = useQuery(queries.getAdoptionDetails(dateRange.value));

  const {
    data: vacantData,
    isFetching: isFetchingVacantData,
    error: vacantError,
  } = useQuery({
    ...queries.getVacantAddresses(dateRange.value),
    enabled: project.type === "addressBased",
  });

  const error = adoptionError || vacantError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    adoption: adoptionData,
    vacant: vacantData?.data ?? [],
    isFetchingAdoptionData,
    isFetchingVacantData,
    selectedDateRange: dateRange,
    dateRangeOptions,
    onDateRangeChange: setDateRange,
  });
}
