import iconBarChart01 from "assets/icons/bar-chart-01.svg";
import iconCalendar from "assets/icons/calendar.svg";
import iconCalendarHeart02 from "assets/icons/calendar-heart-02.svg";
import iconDotsGrid from "assets/icons/dots-grid.svg";
import iconFile02 from "assets/icons/file-02.svg";
import iconHome from "assets/icons/home-02.svg";
import iconInbox01 from "assets/icons/inbox-01.svg";
import iconMessageChatCircle from "assets/icons/message-chat-circle.svg";
import iconPieChart01 from "assets/icons/pie-chart-01.svg";
import iconSearchRefraction from "assets/icons/search-refraction.svg";
import iconSettings01 from "assets/icons/settings-01.svg";
import iconStars02 from "assets/icons/stars-02.svg";
import iconTool02 from "assets/icons/tool-02.svg";
import { Icon } from "components/Icon/Icon";
import type { SidebarBaseItemType, SidebarItemType } from "components/Sidebar/types";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { usePostHog } from "posthog-js/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import {
  canAccessAdminTickets,
  canAccessAnalytics,
  canAccessAssets,
  canAccessAutomatedSurveys,
  canAccessCalendar,
  canAccessChats,
  canAccessCommunityFeed,
  canAccessHelpCategories,
  canAccessHome,
  canAccessInterestGroups,
  canAccessManageAddresses,
  canAccessManageCompanies,
  canAccessManageNeighbours,
  canAccessPracticalAndMore,
  canAccessProjectAddressUpload,
  canAccessProjectBuildings,
  canAccessProjectCompanyUpload,
  canAccessProjectIntegrationSettings,
  canAccessProjectOnboadingScreens,
  canAccessProjectPlusButtonOptions,
  canAccessProjectRolesAndPermissions,
  canAccessProjectSettings,
  canAccessProjectStyling,
  canAccessProjectUserUpload,
  canAccessRegularSurveys,
  canAccessReservations,
  canAccessResidentTickets,
  canAccessServices,
} from "./permissions";

export const useProjectSidebarManager = (): {
  navigationItems: SidebarItemType[];
} => {
  const [amountUnreadMessages, setAmountUnreadMessages] = useState<{ [projectId: string]: number }>({});
  const projectId = useProjectId();

  const isDesktop = useScreenIsBiggerThan("md");
  const connectedProjects = useConnectedProjects();
  const hasPermission = usePermission();
  const slug = useSlug();
  const postHog = usePostHog();
  const [sessionUser] = useSessionUserOptional({ keepPreviousData: true });
  const { t } = useTranslation();

  const onNewChatMessage = useCallback(
    (...args: [{ unreadChats: number; fromConnectionStarted: boolean }]) => {
      if (args[0].unreadChats > 0) {
        setAmountUnreadMessages((x) => ({ ...x, [projectId]: args[0].unreadChats }));
      } else {
        setAmountUnreadMessages((x) => ({ ...x, [projectId]: 0 }));
      }
    },
    [projectId],
  );
  const { signalRConnection } = useSignalRHub("notification-hub", {
    query: `userId=${sessionUser?.id}`,
    disabled: !sessionUser,
  });
  useSignalRSubscription(signalRConnection, "UpdateUnreadChatsCount", onNewChatMessage);

  const project = sessionUser?.project;
  const canViewPortfolio = useMemo(
    () => connectedProjects.data?.some((x) => x.userRole !== "resident"),
    [connectedProjects.data],
  );

  const items: SidebarItemType[] = [];

  // Portfolio
  if (!isDesktop && canViewPortfolio) {
    items.push({
      key: "portfolio",
      label: t("navigation.portfolio.portfolio"),
      to: routes.portfolio.overview(),
      icon: <Icon name={iconDotsGrid} />,
      "data-testid": "navigation-project-portfolio",
      exact: true,
    });
  }

  if (hasPermission(canAccessHome)) {
    items.push({
      key: "home",
      label: t("navigation.home"),
      to: routes.home.home({ slug }),
      icon: <Icon name={iconHome} />,
      "data-testid": "navigation-home",
      exact: true,
    });
  }

  if (hasPermission(canAccessAnalytics)) {
    items.push({
      key: "analytics",
      label: t("navigation.analytics"),
      to: routes.analytics.overview({ slug }),
      icon: <Icon name={iconPieChart01} />,
      "data-testid": "navigation-analytics",
    });
  }

  if (
    hasPermission(canAccessManageNeighbours) ||
    hasPermission(canAccessManageAddresses) ||
    hasPermission(canAccessManageCompanies) ||
    hasPermission(canAccessInterestGroups) ||
    hasPermission(canAccessHelpCategories)
  ) {
    const subItemsDiscover: SidebarBaseItemType[] = [];
    if (hasPermission(canAccessManageNeighbours)) {
      subItemsDiscover.push({
        key: "neighbours",
        label: t("navigation.discover.neighbours"),
        to: routes.users.list({ slug }),
        onClick: () => postHog.capture("clicked_all_neighbours"),
        "data-testid": "navigation-neighbours",
      });
    }

    if (hasPermission(canAccessManageAddresses)) {
      subItemsDiscover.push({
        key: "addresses",
        label: t("navigation.discover.addresses"),
        to: routes.addresses.list({ slug }),
        "data-testid": "navigation-addresses",
      });
    }

    if (hasPermission(canAccessManageCompanies) && project && project.type === "companyBased") {
      subItemsDiscover.push({
        key: "companies",
        label: t("navigation.discover.companies"),
        to: routes.companies.list({ slug }),
        "data-testid": "navigation-companies",
      });
    }

    if (hasPermission(canAccessInterestGroups)) {
      subItemsDiscover.push({
        key: "interest-groups",
        label: t("navigation.discover.groups"),
        to: routes.interestGroups.list({ slug }),
        "data-testid": "navigation-link-interest-groups",
      });
    }

    if (hasPermission(canAccessHelpCategories)) {
      subItemsDiscover.push({
        key: "help-categories",
        label: t("navigation.discover.help"),
        to: routes.helpCategories.list({ slug }),
        "data-testid": "navigation-link-help-categories",
      });
    }

    items.push({
      key: "discover-group",
      label: t("navigation.discover"),
      icon: <Icon name={iconSearchRefraction} />,
      "data-testid": "navigation-discovery-group",
      type: "group",
      items: subItemsDiscover,
    });
  }

  if (hasPermission(canAccessCommunityFeed)) {
    items.push({
      key: "community-feed",
      label: t("navigation.community-feed"),
      to: routes.messageFeed.list({ slug }),
      icon: <Icon name={iconFile02} />,
      "data-testid": "navigation-community-feed",
      onClick: () => postHog.capture("clicked_community_feed"),
    });
  }

  if (hasPermission(canAccessChats)) {
    items.push({
      key: "chats",
      label: t("navigation.chats"),
      to: routes.chats.list({ slug }),
      icon: <Icon name={iconMessageChatCircle} />,
      "data-testid": "navigation-chats",
      isNewEventAvailable: amountUnreadMessages[projectId] > 0,
      newEventCount: amountUnreadMessages[projectId],
    });
  }

  if (hasPermission(canAccessCalendar) && sessionUser?.calendarVisibility !== "none") {
    items.push({
      key: "calendar",
      label: t("navigation.calendar"),
      to: routes.calendar.list({ slug }),
      icon: <Icon name={iconCalendar} />,
      "data-testid": "navigation-calendar",
      onClick: () => postHog.capture("clicked_calendar"),
    });
  }

  if (hasPermission(canAccessServices)) {
    items.push({
      key: "services",
      label: t("navigation.services"),
      to: routes.servicePartners.list({ slug }),
      icon: <Icon name={iconStars02} />,
      "data-testid": "navigation-service-list",
    });
  }

  if (hasPermission(canAccessAssets) || hasPermission(canAccessReservations)) {
    const subItemsBookings: SidebarBaseItemType[] = [];

    if (hasPermission(canAccessAssets)) {
      subItemsBookings.push({
        key: "assets",
        label: t("navigation.bookings.assets"),
        to: routes.bookings.list({ slug }),
        "data-testid": "navigation-assets",
      });
    }

    if (hasPermission(canAccessReservations)) {
      subItemsBookings.push({
        key: "reservations",
        label: t("navigation.bookings.reservations"),
        to: routes.reservations.list({ slug }),
        "data-testid": "navigation-reservations",
      });
    }

    items.push({
      key: "bookings-group",
      label: t("navigation.bookings"),
      icon: <Icon name={iconCalendarHeart02} />,
      type: "group",
      items: subItemsBookings,
      "data-testid": "navigation-bookings-group",
    });
  }

  if (hasPermission(canAccessResidentTickets)) {
    items.push({
      key: "resident-tickets",
      label: t("navigation.resident-tickets"),
      to: routes.tickets.list({ slug }),
      icon: <Icon name={iconTool02} />,
      "data-testid": "navigation-resident-tickets",
    });
  } else if (hasPermission(canAccessAdminTickets)) {
    items.push({
      key: "tickets",
      label: t("navigation.tickets"),
      to: routes.tickets.list({ slug }),
      icon: <Icon name={iconTool02} />,
      "data-testid": "navigation-tickets",
      onClick: () => postHog.capture("clicked_all_tickets"),
    });
  }

  if (hasPermission(canAccessPracticalAndMore)) {
    items.push({
      key: "documents",
      label: t("navigation.documents"),
      to: routes.documents.rootList({ slug }),
      icon: <Icon name={iconInbox01} />,
      "data-testid": "navigation-documents-root-list",
    });
  }

  if (hasPermission(canAccessRegularSurveys) || hasPermission(canAccessAutomatedSurveys)) {
    const subItemsSurveys: SidebarBaseItemType[] = [];

    if (hasPermission(canAccessRegularSurveys)) {
      subItemsSurveys.push({
        key: "surveys",
        label: t("navigation.surveys"),
        to: routes.surveys.list({ slug }),
        "data-testid": "navigation-surveys",
      });
    }

    if (hasPermission(canAccessAutomatedSurveys)) {
      subItemsSurveys.push({
        key: "automated-survey-queues",
        label: t("navigation.automated-survey-queues"),
        to: routes.automatedSurveyQueues.list({ slug }),
        "data-testid": "navigation-automated-survey-queues",
      });
    }

    items.push({
      key: "surveys-group",
      label: t("navigation.surveys-group"),
      icon: <Icon name={iconBarChart01} />,
      type: "group",
      items: subItemsSurveys,
      "data-testid": "navigation-surveys-group",
    });
  }

  if (
    hasPermission(canAccessProjectBuildings) ||
    hasPermission(canAccessProjectAddressUpload) ||
    hasPermission(canAccessProjectCompanyUpload) ||
    hasPermission(canAccessProjectUserUpload) ||
    hasPermission(canAccessProjectSettings) ||
    hasPermission(canAccessProjectStyling) ||
    hasPermission(canAccessProjectOnboadingScreens) ||
    hasPermission(canAccessProjectRolesAndPermissions) ||
    hasPermission(canAccessProjectPlusButtonOptions) ||
    hasPermission(canAccessProjectIntegrationSettings)
  ) {
    const subItemsAdmin: SidebarBaseItemType[] = [];

    if (hasPermission(canAccessProjectBuildings)) {
      subItemsAdmin.push({
        key: "buildings",
        label: t("navigation.admin.buildings"),
        to: routes.buildings.list({ slug }),
        "data-testid": "navigation-buildings",
      });
    }

    if (hasPermission(canAccessProjectAddressUpload)) {
      subItemsAdmin.push({
        key: "addresses",
        label: t("navigation.admin.addresses"),
        to: routes.admin.addressBulkUpload({ slug }),
        "data-testid": "navigation-address-bulk-upload",
      });
    }

    if (hasPermission(canAccessProjectCompanyUpload) && project && project.type === "companyBased") {
      subItemsAdmin.push({
        key: "companies",
        label: t("navigation.admin.companies"),
        to: routes.admin.companyBulkUpload({ slug }),
        "data-testid": "navigation-company-bulk-upload",
      });
    }

    if (hasPermission(canAccessProjectUserUpload)) {
      subItemsAdmin.push({
        key: "user-upload",
        label: t("navigation.platform.user-upload"),
        to: routes.admin.userBulkUpload({ slug }),
        "data-testid": "navigation-user-bulk-upload",
      });
    }

    if (hasPermission(canAccessProjectSettings)) {
      subItemsAdmin.push({
        key: "project-settings",
        label: t("navigation.admin.settings"),
        to: routes.projects.edit({ slug }),
        "data-testid": "navigation-project-settings",
      });
    }

    if (hasPermission(canAccessProjectStyling)) {
      subItemsAdmin.push({
        key: "project-styling",
        label: t("navigation.admin.styling"),
        to: routes.projects.styling({ slug }),
        "data-testid": "navigation-project-styling",
      });
    }

    if (hasPermission(canAccessProjectOnboadingScreens)) {
      subItemsAdmin.push({
        key: "onboarding-screens",
        label: t("navigation.admin.onboarding"),
        to: routes.onboardingScreens.list({ slug }),
        "data-testid": "navigation-onboarding-screens",
      });
    }

    if (hasPermission(canAccessProjectRolesAndPermissions)) {
      subItemsAdmin.push({
        key: "roles",
        label: t("navigation.admin.roles"),
        to: routes.roles.list({ slug }),
        "data-testid": "navigation-role-list",
      });
    }

    if (hasPermission(canAccessProjectPlusButtonOptions)) {
      subItemsAdmin.push({
        key: "plus-button",
        label: t("navigation.admin.plus-button"),
        to: routes.projects.plusButtonOptions({ slug }),
        "data-testid": "navigation-plus-button-options",
      });
    }

    if (hasPermission(canAccessProjectIntegrationSettings)) {
      subItemsAdmin.push({
        key: "integrations",
        label: t("navigation.admin.integrations"),
        to: routes.projects.integrations({ slug }),
        "data-testid": "navigation-integration-settings",
      });
    }

    items.push({
      key: "admin-group",
      label: t("navigation.admin"),
      icon: <Icon name={iconSettings01} />,
      "data-testid": "navigation-management-group",
      type: "group",
      items: subItemsAdmin,
    });
  }

  return {
    navigationItems: items,
  };
};
