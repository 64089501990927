export interface BookableDay {
  day: WeekDay;
  times: {
    startTime: number | null;
    endTime: number | null;
  }[];
  allDay: boolean;
  enabled: boolean;
}

export const defaultBookableDayStartTime = "08:00:00";
export const defaultBookableDayEndTime = "22:00:00";
export const defaultAmountSeats = 1;

export type Specification =
  | "sizeSpecification"
  | "pricePerHourSpecification"
  | "capacitySpecification"
  | "locationSpecification";

export const specificationOptions: Specification[] = [
  "sizeSpecification",
  "pricePerHourSpecification",
  "capacitySpecification",
  "locationSpecification",
];

type WeekDay = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export const daysOptions: WeekDay[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export type TimeSlot =
  | "fifteenMinutes"
  | "thirtyMinutes"
  | "thirtyFiveMinutes"
  | "oneHour"
  | "twoHours"
  | "threeHours"
  | "fourHours"
  | "allDay";

export const timeSlotOptions: TimeSlot[] = [
  "fifteenMinutes",
  "thirtyMinutes",
  "thirtyFiveMinutes",
  "oneHour",
  "twoHours",
  "threeHours",
  "fourHours",
  "allDay",
];
