import type { CommunityFeedAudienceGroupDto, MessageCreatedEntityDto } from "api/types";
import MailManImage from "assets/images/mail-man.svg?react";
import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { isDefined } from "helpers/util";
import { useSlug } from "hooks/useSlug";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

type AdminCreatePostSuccessModalProps = ModalBaseProps & {
  audienceReached: MessageCreatedEntityDto | null;
  wasPostedToGroup: boolean;
  groupPosted: CommunityFeedAudienceGroupDto | undefined;
};

export function AdminCreatePostSuccessModal({
  isOpened,
  onOpenChange,
  audienceReached,
  wasPostedToGroup,
  groupPosted,
}: AdminCreatePostSuccessModalProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const slug = useSlug();

  const isAnyAddressReached = isDefined(audienceReached?.addressCount) && audienceReached.addressCount > 0;
  const isAnyUnknownAddressReached =
    isDefined(audienceReached?.unknownAddressCount) && audienceReached.unknownAddressCount > 0;
  const isAnyResidentReached =
    isDefined(audienceReached?.audienceResidentsCount) && audienceReached.audienceResidentsCount > 0;

  return (
    <Modal.Root
      title={t("page.admin-create-post.success-modal.title")}
      description={t("page.admin-create-post.success-modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col items-center gap-4">
        <MailManImage />
        {isAnyResidentReached && (
          <div className="flex flex-col items-center gap-1">
            <span className="text-body">
              {t("page.admin-create-post.success-modal.audience-reached.description.delivered-to")}
            </span>
            {/* Amount of addresses */}
            {isAnyAddressReached && (
              <span className="text-body">
                <Trans
                  i18nKey="page.admin-create.post.success-modal.audience-reached.description.addresses"
                  values={{
                    addressCount: audienceReached.addressCount,
                  }}
                  count={audienceReached.addressCount}
                  components={{
                    b: <b />,
                  }}
                />
              </span>
            )}
            {/* Amount of unknown addresses */}
            {isAnyUnknownAddressReached && (
              <span className="text-body">
                <Trans
                  i18nKey="page.admin-create.post.success-modal.audience-reached.description.unknown-addresses"
                  values={{
                    unknownAddressCount: audienceReached.unknownAddressCount,
                  }}
                  count={audienceReached.unknownAddressCount}
                  components={{
                    b: <b />,
                  }}
                />
              </span>
            )}
            {/* Amount of residents */}
            <span className="text-body">
              <Trans
                i18nKey="page.admin-create-post.success-modal.audience-reached.description.residents"
                values={{
                  residentCount: audienceReached.audienceResidentsCount,
                }}
                count={audienceReached.audienceResidentsCount}
                components={{
                  b: <b />,
                }}
              />
            </span>
          </div>
        )}
        {!isAnyResidentReached && (
          <span className="text-center text-body">
            {t("page.admin-create-post.success-modal.audience-reached.description.residents.none")}
          </span>
        )}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="tertiary"> {t("page.admin-create-post.success-modal.button.another-message")}</Button>
          </Modal.Close>
          <Button
            styling="primary"
            onClick={() =>
              wasPostedToGroup
                ? void navigate(
                    groupPosted?.type === "helpCategory"
                      ? routes.helpCategories.details({ slug, id: groupPosted.id })
                      : routes.interestGroups.details({ slug, id: groupPosted!.id }),
                  )
                : void navigate(routes.messageFeed.list({ slug }))
            }
          >
            {wasPostedToGroup
              ? t("page.admin-create-post.success-modal.button.group-done")
              : t("page.admin-create-post.success-modal.button.done")}
          </Button>
        </Modal.Actions>
      </div>
    </Modal.Root>
  );
}
