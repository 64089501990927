import { useEffect, useRef } from "react";

export function usePrevious<TValue>(value: TValue, defaultValue: TValue): TValue {
  const ref = useRef<TValue>(defaultValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
