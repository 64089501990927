import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { DocumentsFolderRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { toTranslationsRequest, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { CreateOrEditFormFields } from "./";
import type { LayoutProps } from "./Layout";
import { getDefaultFormValues } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const location = useLocation();
  const { folderId: parentFolderId } = useParams<{ folderId: string }>();
  const isEditMode = matchPath(routes.documents.folderEdit.PATH, location.pathname) != null;
  const navigate = useNavigate();
  const { mutate: createFolder, isPending: isCreating } = useMutation({
    mutationFn: api.postDocumentsFolderV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.folder.action.create.notification.success"),
      });
      void navigate(routes.documents.documentsList({ slug, folderId: parentFolderId! }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.folder.action.create.notification.failure"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, parentFolderId!) });
    },
  });
  const { mutate: editFolder, isPending: isEditing } = useMutation({
    mutationFn: ({ id, body }: { id: string; body: DocumentsFolderRequest }) => api.putDocumentsFolderV1(id, body),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.folder.action.edit.notification.success"),
      });
      void navigate(routes.documents.documentsList({ slug, folderId: parentFolderId! }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.folder.action.edit.notification.failure"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, parentFolderId!) });
    },
  });
  const {
    data: folderDetails,
    isFetching: folderDetailsIsFetching,
    error: folderDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_LIST_DETAILS(projectId, parentFolderId!),
    queryFn: () => api.getDocumentsFolderDetailsV1(parentFolderId!),
    enabled: isEditMode,
    select: commonAPIDataSelector,
  });
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();
  const defaultFormValues = getDefaultFormValues(parentFolderId!, languages, folderDetails);
  function onSubmit(data: CreateOrEditFormFields) {
    const request = {
      ...data,
      nameTranslations: toTranslationsRequest(data.nameTranslations),
    };

    if (isEditMode) {
      return editFolder({
        id: parentFolderId!,
        body: request,
      });
    }

    return createFolder(request);
  }

  const error = folderDetailsError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (folderDetailsIsFetching || languagesIsFetching) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      {props.children({
        onSubmit,
        languages,
        defaultFormValues,
        isEditMode,
        isSubmitting: isCreating || isEditing,
      })}
    </>
  );
}
