import { TagSelect, type TagSelectProps } from "components/TagSelect/TagSelect";
import type React from "react";
import {
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions,
  useController,
} from "react-hook-form";

import { FormErrorWrapper } from "./FormErrorWrapper";

export interface FormTagSelectProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<TagSelectProps, "values" | "onChange"> {
  name: TName;
  defaultValue?: FieldPathValue<TFormValues, TName>;
  rules?: RegisterOptions<TFormValues, TName>;
  "data-testid"?: string;
}

export function FormTagSelect<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({
  name,
  defaultValue,
  rules,
  "data-testid": dataTestid,
  ...props
}: FormTagSelectProps<TFormValues, TName>): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, rules, defaultValue });

  return (
    <FormErrorWrapper<TFormValues> name={name} data-testid={dataTestid}>
      <TagSelect onChange={field.onChange} values={field.value} {...props} />
    </FormErrorWrapper>
  );
}
