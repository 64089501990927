import { useEffect, useRef } from "react";

export function useHasMounted(): boolean {
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return hasMounted.current;
}
