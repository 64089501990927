import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { DonutChart } from "modules/analytics/components/DonutChart";
import { NoData } from "modules/analytics/components/NoData";
import { Stat } from "modules/analytics/components/Stat";
import { ChartLegend } from "modules/analytics/components/utility/ChartLegend";
import { dataColors } from "modules/analytics/theme";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

const CHART_SIZE = 220;

export function PlatformAdoptionCard(): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  const analyticsQueries = useAnalyticsQueries();
  const { data } = useSuspenseQuery(analyticsQueries.getAdoption());

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.adoption.title")}
        tooltip={t("page.analytics-overview.chart.adoption.title-tooltip")}
      />
      {!data ? (
        <NoData />
      ) : (
        <div className="flex flex-col items-center gap-12 pt-7 xl:flex-row xl:justify-between">
          <div className="flex flex-col items-center gap-4 px-6">
            <DonutChart size={CHART_SIZE} value={data.adoptionRatePercentage} />
            <ChartLegend
              items={[
                {
                  label: t("page.analytics-overview.chart.adoption.legend.activated", {
                    count: data.invitedAddressesActivated,
                  }),
                  color: dataColors.primary,
                  tooltip: t("page.analytics-overview.chart.adoption.legend.tooltip"),
                },
                {
                  label: t("page.analytics-overview.chart.adoption.legend.not-activated", {
                    count: data.invitedAddressesNotActivated,
                  }),
                  color: dataColors.background.chart,
                  tooltip: t("page.analytics-overview.chart.adoption.legend.tooltip"),
                },
              ]}
              layout="vertical"
            />
          </div>
          <div className="-mt-10 grid w-full grow grid-cols-2 gap-3">
            <Stat
              title={t("page.analytics-overview.chart.adoption.item.addresses-total")}
              value={data.totalAddresses}
            />
            <Stat
              title={t("page.analytics-overview.chart.adoption.item.unknown")}
              value={data.unknownStatusAddresses}
            />
            <Stat
              title={t("page.analytics-overview.chart.adoption.item.addresses-invited")}
              value={data.totalAddressesInvited}
            />
            <Stat title={t("page.analytics-overview.chart.adoption.item.users-total")} value={data.totalResidents} />
          </div>
        </div>
      )}
      <Button type="link" styling="tertiary" className="self-end" href={routes.analytics.adoptionDetails({ slug })}>
        {t("page.analytics-details.link")}
      </Button>
    </Card>
  );
}

export function PlatformAdoptionCompanyCard(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_PLATFORM_COMPANY_ADOPTION(projectId),
    queryFn: () => api.getAnalyticsCompanyAdoptionRateV1(),
    select: commonAPIDataSelector,
  });

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.company-adoption.title")}
        tooltip={t("page.analytics-overview.chart.company-adoption.title-tooltip")}
      />
      {!data ? (
        <NoData />
      ) : (
        <div className="flex flex-col items-center gap-12 pt-7 xl:flex-row xl:justify-between">
          <div className="flex flex-col items-center gap-4 px-6">
            <DonutChart size={CHART_SIZE} value={data.adoptionRatePercentage} />
            <ChartLegend
              items={[
                {
                  label: t("page.analytics-overview.chart.company-adoption.legend.activated", {
                    count: data.totalCompaniesWithUsers,
                  }),
                  color: dataColors.primary,
                  tooltip: t("page.analytics-overview.chart.company-adoption.legend.tooltip"),
                },
                {
                  label: t("page.analytics-overview.chart.company-adoption.legend.not-activated", {
                    count: data.totalCompanies - data.totalCompaniesWithUsers,
                  }),
                  color: dataColors.background.chart,
                  tooltip: t("page.analytics-overview.chart.company-adoption.legend.tooltip"),
                },
              ]}
              layout="vertical"
            />
          </div>
          <div className="-mt-10 grid w-full grow grid-cols-2 gap-3">
            <Stat
              title={t("page.analytics-overview.chart.company-adoption.item.companies-total")}
              value={data.totalCompanies}
            />
            <Stat
              title={t("page.analytics-overview.chart.company-adoption.item.unknown")}
              value={data.totalCompanies - data.totalCompaniesWithUsers}
            />
            <Stat
              title={t("page.analytics-overview.chart.company-adoption.item.users-total")}
              value={data.totalResidents}
            />
          </div>
        </div>
      )}
      <Button type="link" styling="tertiary" className="self-end" href={routes.analytics.adoptionDetails({ slug })}>
        {t("page.analytics-details.link")}
      </Button>
    </Card>
  );
}
