import type {
  BookableAssetDeletedResult,
  BookableAssetUpdateBookingsToBeCancelledDto,
  BookableAssetUpdateBookingsToBeCancelledRequest,
  ConstraintListItemDto,
  DocumentDto,
  ImageDto,
  LanguageDto,
  TranslateRequest,
  TranslateResult,
} from "api/types";
import iconClockCheck from "assets/icons/clock-check.svg";
import priceIcon from "assets/icons/currency-euro.svg";
import iconGlobe02 from "assets/icons/globe-02.svg";
import areaIcon from "assets/icons/layer-single.svg";
import locationIcon from "assets/icons/marker-pin-01.svg";
import capacityIcon from "assets/icons/users-01.svg";
import iconX from "assets/icons/x.svg";
import { AudienceSelector } from "components/AudienceSelector/AudienceSelector";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormScheduleInput } from "components/Form/FormScheduleInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Notice } from "components/Notice/Notice";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { addDays, addMinutes, differenceInMinutes, startOfDay } from "date-fns";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import { getFlagIcon } from "helpers/flags";
import type { FormTranslations } from "helpers/languages";
import {
  createDocumentSizeRule,
  createDocumentTypeRule,
  createImageSizeRule,
  createImageTypeRule,
  createRequiredStringRule,
} from "helpers/rules";
import { isDefined } from "helpers/util";
import { isValidEmail } from "helpers/validation";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { isEmpty, isEqual, xorWith } from "lodash-es";
import type { BookableDay, Specification, TimeSlot } from "modules/bookings/constants";
import { timeSlotOptions } from "modules/bookings/constants";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import { AssetBookableDaySelector } from "./components/AssetBookableDaySelector";
import { ExtraSpecificationsModal } from "./components/ExtraSpecificationsModal";
import type { AssetObserver } from "./Loader";
import { formatBookableDaysPayload } from "./Manager";

export interface LayoutProps {
  languages: LanguageDto[];
  assetObservers: AssetObserver[];
  onTranslate: (payload: TranslateRequest) => Promise<TranslateResult[] | null>;
  isTranslating: boolean;
  getBookingsToBeCancelled: (payload: {
    id: string;
    data: BookableAssetUpdateBookingsToBeCancelledRequest;
  }) => Promise<BookableAssetUpdateBookingsToBeCancelledDto>;
  onSubmit: (data: CreateOrEditAssetFormValues) => Promise<void>;
  isSubmitting: boolean;
  isEditMode: boolean;
  canEditTimeslot: boolean;
  futureBookings: number;
  onDelete: (id: string) => Promise<BookableAssetDeletedResult>;
  defaultValues: CreateOrEditAssetFormValues;
  assetId?: string;
  isPublished: boolean;
}

export interface CreateOrEditAssetFormValues {
  audience: ConstraintListItemDto[];
  nameTranslations: FormTranslations;
  descriptionTranslations: FormTranslations;
  images: ImageDto[];
  specifications: Specification[];
  sizeSpecification?: string;
  pricePerHourSpecification?: string;
  capacitySpecification?: string;
  locationSpecification?: string;
  regulationsApplicable: boolean;
  regulationDocument?: DocumentDto[];
  assetObserver: AssetObserver;
  bookableDays: BookableDay[];
  timeslot?: TimeSlot;
  canBookMultipleSlots: boolean;
  notificationEnabled: boolean;
  notificationEmail: string;
  canBookSameDay: boolean;
  maxDaysInAdvance?: string;
  hoursForMutations?: string;
  requireBookingReason: boolean;
  publishAt: DatePickerValue;
  unpublishAt: DatePickerValue;
  availableFrom: DatePickerValue;
  seats?: number;
}

const MIN_LENGTH = {
  NAME: 5,
  DESCRIPTION: 5,
};
const MAX_LENGTH = {
  NAME: 255,
  DESCRIPTION: 25000,
};
const MAX_ADVANCE_BOOKING_DAYS = 365;
const MIN_ADVANCE_BOOKING_DAYS = 0;
const MIN_EDIT_BOOKING_HOURS = 0;
const MIN_AMOUNT_SEATS = 1;

export const Layout = ({
  languages,
  assetObservers,
  onTranslate,
  isTranslating,
  getBookingsToBeCancelled,
  onSubmit,
  isSubmitting,
  isEditMode,
  canEditTimeslot,
  futureBookings,
  onDelete,
  defaultValues,
  assetId,
  isPublished,
}: LayoutProps): React.ReactNode => {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isExtraSpecificationsModalOpened, extraSpecificationsModalOpenHandler] = useBool(false);
  const [isCancelModalOpen, cancelModalHandler] = useBool(false);
  const [bookingsToBeCancelled, setBookingsToBeCancelled] = useState<number | null>(null);
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-asset-modal");
  const sessionUser = useSessionUser();

  const form = useForm<CreateOrEditAssetFormValues>({
    defaultValues,
  });

  const availableSpecifications = useWatch({ control: form.control, name: "specifications" });
  const regulationsApplicable = useWatch({ control: form.control, name: "regulationsApplicable" });
  const bookableDays = useWatch({ control: form.control, name: "bookableDays" });
  const notificationEnabled = useWatch({ control: form.control, name: "notificationEnabled" });
  const assetObserver = useWatch({ control: form.control, name: "assetObserver" });
  const audience = useWatch({ control: form.control, name: "audience" });
  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);
  const seats = useWatch({ control: form.control, name: "seats" });

  useEffect(() => {
    form.register("bookableDays", {
      validate: {
        required: (bookableDays) => {
          if (bookableDays.filter((x) => x.enabled).length === 0) {
            return t("components.form.error.required", {
              inputName: t("page.bookings.create-or-edit.form.section.booking-details.bookable-days.name"),
            });
          }

          return undefined;
        },
        everyBookableTimeFilled: (bookableDays) => {
          const availableBookableDays = bookableDays.filter((x) => x.enabled);

          for (const bookableDay of availableBookableDays) {
            const isEveryBookableTimeFilled = bookableDay.times.every((bookableTime) => {
              return isDefined(bookableTime.startTime) && isDefined(bookableTime.endTime);
            });

            if (!isEveryBookableTimeFilled) {
              return "";
            }
          }

          return undefined;
        },
        noOverlappingtimes: (bookableDays) => {
          const availableBookableDays = bookableDays.filter((x) => x.enabled);

          for (const bookableDay of availableBookableDays) {
            const isAnyOverlappingBookableTime = bookableDay.times.some((bookableTimeA, indexA) => {
              if (!isDefined(bookableTimeA.startTime) || !isDefined(bookableTimeA.endTime)) {
                return false;
              }

              const startTimeA = bookableTimeA.startTime;
              const endTimeA = bookableTimeA.endTime;

              return bookableDay.times.some((bookableTimeB, indexB) => {
                if (indexA === indexB) {
                  return false;
                }

                if (!isDefined(bookableTimeB.startTime) || !isDefined(bookableTimeB.endTime)) {
                  return false;
                }

                const startTimeB = bookableTimeB.startTime;
                const endTimeB = bookableTimeB.endTime;

                return (
                  (startTimeA >= startTimeB && startTimeA < endTimeB) ||
                  (endTimeA > startTimeB && endTimeA <= endTimeB) ||
                  (startTimeA <= startTimeB && endTimeA >= endTimeB)
                );
              });
            });

            if (isAnyOverlappingBookableTime) {
              return t(
                "page.bookings.create-or-edit.form.section.booking-details.bookable-days.error.overlapping-time-periods",
              );
            }
          }

          return undefined;
        },
        startTimeSoonerThanEndTime: (bookableDays) => {
          const availableBookableDays = bookableDays.filter((x) => x.enabled);

          for (const bookableDay of availableBookableDays) {
            const isAnyStartTimeSoonerThanEndTime = bookableDay.times.some((bookableTime) => {
              return (
                isDefined(bookableTime.startTime) &&
                isDefined(bookableTime.endTime) &&
                bookableTime.startTime >= bookableTime.endTime
              );
            });

            if (isAnyStartTimeSoonerThanEndTime) {
              return t(
                "page.bookings.create-or-edit.form.section.booking-details.bookable-days.error.start-time-sooner-than-end-time",
              );
            }
          }

          return undefined;
        },
      },
    });
  }, [form, t]);

  useEffect(() => {
    if (assetObserver.type === "projectConnection") {
      form.setValue("audience", []);
    }
  }, [assetObserver, form]);

  useEffect(() => {
    if (seats && seats > 1) {
      form.setValue("canBookMultipleSlots", false);
    }
  }, [seats, form]);

  const translate = async (field: "name" | "description", languageId: LanguageDto["id"]) => {
    const value = form.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await onTranslate({
      languages: languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        form.setValue(`${field}Translations.${translation.languages}`, translation.text);
      }
    }
  };

  const onAddSpecifications = (specifications: Specification[]) => {
    form.setValue("specifications", specifications);
  };

  const deleteSpecification = (specification: Specification) => {
    form.setValue(
      "specifications",
      availableSpecifications.filter((x) => x !== specification),
    );
    form.setValue(specification, undefined);
  };

  const areBookableTimesDifferent = (timesA: BookableDay["times"], timesB: BookableDay["times"]) => {
    return isEmpty(xorWith(timesA, timesB, isEqual));
  };

  const onEditSubmit = async (data: CreateOrEditAssetFormValues) => {
    const prevBookableDays = defaultValues.bookableDays;
    const newBookableDays = [...data.bookableDays];

    let isEdited = false;

    const timeOfDefaultAvailableFrom = defaultValues.availableFrom === "" ? 0 : defaultValues.availableFrom.getTime();
    const timeOfDataAvailableFrom = data.availableFrom === "" ? 0 : data.availableFrom.getTime();
    const timeOfDefaultUnpublishAt = defaultValues.unpublishAt === "" ? 0 : defaultValues.unpublishAt.getTime();
    const timeOfDataUnpublishAt = data.unpublishAt === "" ? 0 : data.unpublishAt.getTime();

    if (timeOfDefaultAvailableFrom != timeOfDataAvailableFrom) {
      isEdited = true;
    }

    if (timeOfDefaultUnpublishAt != timeOfDataUnpublishAt) {
      isEdited = true;
    }

    for (let i = 0; i < prevBookableDays.length; i++) {
      if (
        prevBookableDays[i].allDay !== newBookableDays[i].allDay ||
        prevBookableDays[i].enabled !== newBookableDays[i].enabled ||
        areBookableTimesDifferent(prevBookableDays[i].times, newBookableDays[i].times)
      ) {
        isEdited = true;
        break;
      }
    }

    if (!isEqual(defaultValues.audience, data.audience)) {
      isEdited = true;
    }

    let affectedBookings: BookableAssetUpdateBookingsToBeCancelledDto | undefined;
    if (isEdited) {
      affectedBookings = await getBookingsToBeCancelled({
        id: assetId!,
        data: {
          audience: data.audience,
          availableFrom: data.availableFrom ? data.availableFrom.toISOString() : undefined,
          unpublishAt: data.unpublishAt ? data.unpublishAt.toISOString() : undefined,
          bookableDays: formatBookableDaysPayload(newBookableDays),
        },
      });
    }

    if (affectedBookings && affectedBookings.bookingsToBeCancelled > 0) {
      setBookingsToBeCancelled(affectedBookings.bookingsToBeCancelled);

      return;
    }

    await onSubmit(data);
  };

  const onChangeTime = (
    bookableDayIndex: number,
    bookableTimeIndex: number,
    value: DatePickerValue,
    type: "start" | "end",
  ) => {
    if (!value) {
      return;
    }

    const startOfDayDate = startOfDay(value);
    if (type === "end") {
      let formattedValue = value;
      // Handle "00:00:00" end of day time
      if (differenceInMinutes(value, startOfDayDate) === 0) {
        formattedValue = addDays(value, 1);
      }

      form.setValue(
        `bookableDays.${bookableDayIndex}.times.${bookableTimeIndex}.endTime`,
        differenceInMinutes(formattedValue, startOfDayDate),
      );
    } else {
      form.setValue(
        `bookableDays.${bookableDayIndex}.times.${bookableTimeIndex}.startTime`,
        differenceInMinutes(value, startOfDayDate),
      );
    }
  };

  const getTimeslotOptionLabel = (timeslot: TimeSlot | undefined) => {
    switch (timeslot) {
      case "fifteenMinutes":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.fifteen-min");
      case "thirtyMinutes":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.thirty-min");
      case "thirtyFiveMinutes":
        return t(
          "page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.thirty-five-min",
        );
      case "oneHour":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.one-hour");
      case "twoHours":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.two-hour");
      case "threeHours":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.three-hour");
      case "fourHours":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.four-hour");
      case "allDay":
        return t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.full-day");
    }
  };

  return (
    <DocumentPaper
      theme="constrained"
      title={
        isEditMode
          ? t("page.bookings.create-or-edit-asset.edit.title")
          : t("page.bookings.create-or-edit-asset.create.title")
      }
    >
      <Form formMethods={form} onSubmit={isEditMode ? onEditSubmit : onSubmit}>
        <FormContent maxWidth="4xl">
          {/* Basic info section */}
          <span className="text-headline3 leading-old-headline4">
            {t("page.bookings.create-or-edit.form.section.asset-details")}
          </span>
          {languages.map((lng) => {
            const nameId = `name-${lng.id}`;
            const descriptionId = `description-${lng.id}`;

            return (
              <Fragment key={lng.id}>
                <FormField
                  htmlFor={nameId}
                  label={t("page.bookings.create-or-edit.form.section.asset-details.name.label", {
                    language: lng.poEditorCode.toUpperCase(),
                  })}
                  actions={
                    <Button
                      styling="ghostPrimary"
                      onClick={() => translate("name", lng.id)}
                      icon={<Icon name={iconGlobe02} />}
                    >
                      {t("page.bookings.create-or-edit.form.section.asset-details.translation")}
                    </Button>
                  }
                  required
                >
                  <FormInput<CreateOrEditAssetFormValues>
                    name={`nameTranslations.${lng.id}`}
                    id={nameId}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(
                          t,
                          "page.bookings.create-or-edit.form.section.asset-details.name.label",
                          {
                            language: lng.poEditorCode.toUpperCase(),
                          },
                        ),
                      },
                      minLength: {
                        message: t("components.form.error.min-length", {
                          length: MIN_LENGTH.NAME,
                        }),
                        value: MIN_LENGTH.NAME,
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", {
                          length: MAX_LENGTH.NAME,
                        }),
                        value: MAX_LENGTH.NAME,
                      },
                    }}
                    prefix={getFlagIcon({ languageId: lng.id })}
                    placeholder={t("page.bookings.create-or-edit.form.section.asset-details.name.placeholder")}
                    disabled={isTranslating}
                  />
                </FormField>
                <FormField
                  htmlFor={descriptionId}
                  label={t("page.bookings.create-or-edit.form.section.asset-details.description.label", {
                    language: lng.poEditorCode.toUpperCase(),
                  })}
                  actions={
                    <Button
                      styling="ghostPrimary"
                      onClick={() => translate("description", lng.id)}
                      icon={<Icon name={iconGlobe02} size={16} />}
                    >
                      {t("page.bookings.create-or-edit.form.section.asset-details.translation")}
                    </Button>
                  }
                  required
                >
                  <FormTextArea<CreateOrEditAssetFormValues>
                    name={`descriptionTranslations.${lng.id}`}
                    id={descriptionId}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(
                          t,
                          "page.bookings.create-or-edit.form.section.asset-details.description.label",
                          {
                            language: lng.poEditorCode.toUpperCase(),
                          },
                        ),
                      },
                      minLength: {
                        message: t("components.form.error.min-length", {
                          length: MIN_LENGTH.DESCRIPTION,
                        }),
                        value: MIN_LENGTH.DESCRIPTION,
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", {
                          length: MAX_LENGTH.DESCRIPTION,
                        }),
                        value: MAX_LENGTH.DESCRIPTION,
                      },
                    }}
                    icon={getFlagIcon({ languageId: lng.id })}
                    placeholder={t("page.bookings.create-or-edit.form.section.asset-details.description.placeholder")}
                    disabled={isTranslating}
                  />
                </FormField>
              </Fragment>
            );
          })}

          {/* Media section */}
          <FormField
            htmlFor="imageFile"
            label={t("page.bookings.create-or-edit.form.section.asset-details.images.label")}
            required
          >
            <FormImageInput<CreateOrEditAssetFormValues, "images">
              name="images"
              nOfImages={5}
              rules={{
                required: t("components.form.error.required", {
                  inputName: t("page.bookings.create-or-edit.form.section.asset-details.images.label"),
                }),
                validate: {
                  size: createImageSizeRule({ t }),
                  type: createImageTypeRule({ t }),
                },
              }}
            />
          </FormField>

          {/* Extra specification section */}
          <FormField label={t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.label")}>
            <div className="flex flex-col gap-4">
              {availableSpecifications.includes("sizeSpecification") && (
                <div className="flex items-center gap-2">
                  <Icon name={areaIcon} className="text-grey-600" />
                  <FormInput<CreateOrEditAssetFormValues>
                    data-testid="size-spec-input"
                    name="sizeSpecification"
                    type="number"
                    inputMode="numeric"
                    min={0}
                    placeholder={t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.size.placeholder",
                    )}
                    className="min-w-12 max-w-28"
                  />
                  <label>
                    {t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.size.label")}
                  </label>
                  <IconButton
                    title={t("common.action.delete")}
                    onClick={() => deleteSpecification("sizeSpecification")}
                  >
                    <Icon name={iconX} />
                  </IconButton>
                </div>
              )}
              {availableSpecifications.includes("pricePerHourSpecification") ? (
                <div className="flex items-center gap-2">
                  <Icon name={priceIcon} className="text-grey-600" />
                  <FormInput<CreateOrEditAssetFormValues>
                    data-testid="price-spec-input"
                    name="pricePerHourSpecification"
                    type="number"
                    inputMode="numeric"
                    min={0}
                    placeholder={t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.price.placeholder",
                    )}
                    className="min-w-12 max-w-28"
                  />
                  <label>
                    {t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.price.label")}
                  </label>
                  <IconButton
                    title={t("common.action.delete")}
                    onClick={() => deleteSpecification("pricePerHourSpecification")}
                  >
                    <Icon name={iconX} />
                  </IconButton>
                </div>
              ) : null}
              {availableSpecifications.includes("capacitySpecification") ? (
                <div className="flex items-center gap-2">
                  <Icon name={capacityIcon} className="text-grey-600" />
                  <FormInput<CreateOrEditAssetFormValues>
                    data-testid="capacity-spec-input"
                    name="capacitySpecification"
                    type="number"
                    inputMode="numeric"
                    min={1}
                    placeholder={t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.capacity.placeholder",
                    )}
                    className="min-w-12 max-w-28"
                  />
                  <label>
                    {t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.capacity.label")}
                  </label>
                  <IconButton
                    title={t("common.action.delete")}
                    onClick={() => deleteSpecification("capacitySpecification")}
                  >
                    <Icon name={iconX} />
                  </IconButton>
                </div>
              ) : null}
              {availableSpecifications.includes("locationSpecification") ? (
                <div className="flex items-center gap-2">
                  <Icon name={locationIcon} className="text-grey-600" />
                  <FormInput<CreateOrEditAssetFormValues>
                    data-testid="location-spec-input"
                    name="locationSpecification"
                    placeholder={t(
                      "page.bookings.create-or-edit.form.section.asset-details.extra-specifications.location.placeholder",
                    )}
                    className="w-full"
                  />
                  <IconButton
                    title={t("common.action.delete")}
                    onClick={() => deleteSpecification("locationSpecification")}
                  >
                    <Icon name={iconX} />
                  </IconButton>
                </div>
              ) : null}
              <Button
                data-testid="add-specification-btn"
                styling="secondary"
                onClick={extraSpecificationsModalOpenHandler.setTrue}
                disabled={availableSpecifications.length === 4}
              >
                {t("page.bookings.create-or-edit.form.section.asset-details.extra-specifications.add")}
              </Button>
            </div>
          </FormField>

          {/* Asset regulations section */}
          <span className="text-headline3 leading-old-headline4">
            {t("page.bookings.create-or-edit.form.section.asset-regulations")}
          </span>
          <FormCheckbox<CreateOrEditAssetFormValues, "regulationsApplicable">
            data-testid="regulations-checkbox"
            name="regulationsApplicable"
            label={t("page.bookings.create-or-edit.form.section.asset-regulations.regulations-applicable.label")}
          />
          {regulationsApplicable && (
            <FormDocumentInput<CreateOrEditAssetFormValues, "regulationDocument">
              name="regulationDocument"
              accept={ALLOWED_FILE_TYPES.DOCUMENT}
              rules={{
                required: t("components.form.error.required", {
                  inputName: t("page.bookings.create-or-edit.form.section.asset-regulations.document.name"),
                }),
                validate: {
                  size: createDocumentSizeRule({
                    t,
                  }),
                  type: createDocumentTypeRule({
                    t,
                  }),
                },
              }}
            />
          )}

          {/* Asset details section */}
          <span className="text-headline3 leading-old-headline4">
            {t("page.bookings.create-or-edit.form.section.booking-details")}
          </span>
          <div className="flex flex-wrap items-center gap-x-2 gap-y-0.5">
            <label>{t("page.bookings.create-or-edit.form.section.booking-details.amount-seats.label.1")}</label>
            <FormInput<CreateOrEditAssetFormValues, "seats">
              name="seats"
              data-testid="amount-seats-input"
              placeholder={(defaultValues.seats || MIN_AMOUNT_SEATS).toString()}
              type="number"
              inputMode="numeric"
              min={defaultValues.seats || MIN_AMOUNT_SEATS}
              rules={{
                valueAsNumber: true,
                validate: {
                  greaterOrEqualThanPreviousValue(value) {
                    if (!value || !defaultValues.seats) {
                      return;
                    }

                    return value < defaultValues.seats
                      ? t(
                          "page.bookings.create-or-edit.form.section.booking-details.amount-seats.error.greater-or-equal-than-previous-value",
                        )
                      : undefined;
                  },
                  min(value) {
                    if (!value) {
                      return;
                    }

                    return value < MIN_AMOUNT_SEATS
                      ? t("page.bookings.create-or-edit.form.section.booking-details.amount-seats.error.min")
                      : undefined;
                  },
                },
              }}
              className="min-w-12 max-w-20"
            />
            <label>{t("page.bookings.create-or-edit.form.section.booking-details.amount-seats.label.2")}</label>
          </div>
          <div className="flex w-full flex-col gap-2">
            <FormField label={t("page.bookings.create-or-edit.form.section.booking-details.audience.label")} required>
              <FormSelect<CreateOrEditAssetFormValues, CreateOrEditAssetFormValues["assetObserver"]>
                name="assetObserver"
                items={assetObservers}
                renderOption={(item) => item.name}
                keySelector={(item) => item.id}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.bookings.create-or-edit.form.section.booking-details.audience.name"),
                  }),
                }}
                disabled={isEditMode || assetObservers.length === 1}
                placeholder={t("page.bookings.create-or-edit.form.section.booking-details.audience.placeholder")}
              />
            </FormField>
            {sessionUser.isAdmin && assetObserver.type === "project" && (
              <AudienceSelector
                defaultAudience={audience}
                editWarningMessage={t("component.audience-selector.edit-warning.asset")}
                onSaveAudience={(newAudience) => form.setValue("audience", newAudience)}
                {...{ isEditMode }}
              />
            )}
          </div>

          <div className="flex w-full flex-col gap-6">
            {/* Asset bookable schedule section */}
            <FormField
              label={t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.label")}
              tooltip={
                canEditTimeslot
                  ? ""
                  : t(
                      "page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.timeslot.disabled-tooltip",
                    )
              }
              required
            >
              <FormSelect<CreateOrEditAssetFormValues, CreateOrEditAssetFormValues["timeslot"]>
                name="timeslot"
                items={timeSlotOptions}
                renderOption={getTimeslotOptionLabel}
                keySelector={(x) => x || "none"}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.name"),
                  }),
                }}
                placeholder={t(
                  "page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.placeholder",
                )}
                disabled={!canEditTimeslot}
              />
            </FormField>
            <Notice
              icon={iconClockCheck}
              message={t("page.bookings.create-or-edit.form.section.booking-details.bookable-timeslots.remark")}
            />
          </div>

          <FormField
            label={t("page.bookings.create-or-edit.form.section.booking-details.bookable-days.label")}
            required
          >
            <FormErrorWrapper<CreateOrEditAssetFormValues> name="bookableDays" encircle>
              <div className="flex flex-col gap-3 md:gap-5">
                {bookableDays.map((bookableDay, bookableDayIndex) => {
                  return (
                    <AssetBookableDaySelector
                      key={bookableDay.day}
                      bookableDay={bookableDay}
                      bookableDayIndex={bookableDayIndex}
                      onChangeStartTime={(bookableTimeIndex, value) =>
                        onChangeTime(bookableDayIndex, bookableTimeIndex, value, "start")
                      }
                      onChangeEndTime={(bookableTimeIndex, value) =>
                        onChangeTime(bookableDayIndex, bookableTimeIndex, value, "end")
                      }
                    />
                  );
                })}
              </div>
            </FormErrorWrapper>
          </FormField>

          {/* Booking notifications section */}
          <span className="text-headline3 leading-old-headline4">
            {t("page.bookings.create-or-edit.form.section.booking-notifications")}
          </span>
          <div className="flex items-start gap-2 md:items-center">
            <div className="mt-2px md:mt-0">
              <FormCheckbox<CreateOrEditAssetFormValues, "notificationEnabled">
                name="notificationEnabled"
                label=""
                id="notificationEnabled"
              />
            </div>
            <div className="flex flex-wrap items-center gap-x-2 gap-y-0.5">
              <label htmlFor="notificationEnabled">
                {t("page.bookings.create-or-edit.form.section.booking-notifications.send-email.1")}
              </label>
              <FormInput<CreateOrEditAssetFormValues, "notificationEmail">
                name="notificationEmail"
                placeholder={t("page.bookings.create-or-edit.form.section.booking-notifications.email.placeholder")}
                type="email"
                inputMode="email"
                rules={{
                  validate: {
                    required: () => {
                      const values = form.getValues();
                      if (!values.notificationEnabled) {
                        return;
                      }

                      if (!values.notificationEmail) {
                        return t("components.form.error.required", {
                          inputName: t("page.bookings.create-or-edit.form.section.booking-notifications.email.name"),
                        });
                      }
                    },
                    isValid(value) {
                      if (!value) {
                        return;
                      }

                      value = value.trim();

                      return isValidEmail(value) ? undefined : t("components.form.error.invalid-email-address");
                    },
                  },
                }}
                disabled={!notificationEnabled}
                className="min-w-32 max-w-60 grow"
              />
              <label>{t("page.bookings.create-or-edit.form.section.booking-notifications.send-email.2")}</label>
            </div>
          </div>

          {/* Booking restrictions section */}
          <span className="text-headline3 leading-old-headline4">
            {t("page.bookings.create-or-edit.form.section.booking-restrictions")}
          </span>
          <FormCheckbox<CreateOrEditAssetFormValues, "canBookSameDay">
            name="canBookSameDay"
            label={t("page.bookings.create-or-edit.form.section.booking-restrictions.book-same.day.label")}
          />
          <span className="flex gap-2">
            <FormCheckbox<CreateOrEditAssetFormValues, "canBookMultipleSlots">
              name="canBookMultipleSlots"
              id="canBookMultipleSlots"
              disabled={!!seats && seats > 1}
              label={t("page.bookings.create-or-edit.form.section.booking-details.booking-restriction.label")}
            />
            <InfoIcon
              tooltip={t("page.bookings.create-or-edit.form.section.booking-details.booking-restriction.tooltip")}
            />
          </span>
          <div className="flex flex-wrap items-center gap-x-2 gap-y-0.5">
            <label>{t("page.bookings.create-or-edit.form.section.booking-restrictions.advance-booking.label.1")}</label>
            <FormInput<CreateOrEditAssetFormValues, "maxDaysInAdvance">
              name="maxDaysInAdvance"
              placeholder={t(
                "page.bookings.create-or-edit.form.section.booking-restrictions.advance-booking.placeholder",
              )}
              type="number"
              inputMode="numeric"
              min={MIN_ADVANCE_BOOKING_DAYS}
              max={MAX_ADVANCE_BOOKING_DAYS}
              rules={{
                valueAsNumber: true,
                validate: {
                  min(value) {
                    if (!value) {
                      return;
                    }

                    return Number(value) < MIN_ADVANCE_BOOKING_DAYS
                      ? t("page.bookings.create-or-edit.form.section.booking-restrictions.advance-booking.error.min")
                      : undefined;
                  },
                  max(value) {
                    if (!value) {
                      return;
                    }

                    return Number(value) > MAX_ADVANCE_BOOKING_DAYS
                      ? t("page.bookings.create-or-edit.form.section.booking-restrictions.advance-booking.error.max")
                      : undefined;
                  },
                },
              }}
              className="min-w-12 max-w-20"
            />
            <label>{t("page.bookings.create-or-edit.form.section.booking-restrictions.advance-booking.label.2")}</label>
          </div>
          <div className="flex flex-wrap items-center gap-x-2 gap-y-0.5">
            <label>{t("page.bookings.create-or-edit.form.section.booking-restrictions.edit-booking.label.1")}</label>
            <FormInput<CreateOrEditAssetFormValues, "hoursForMutations">
              name="hoursForMutations"
              placeholder={t("page.bookings.create-or-edit.form.section.booking-restrictions.edit-booking.placeholder")}
              type="number"
              inputMode="numeric"
              min={MIN_EDIT_BOOKING_HOURS}
              rules={{
                validate: {
                  min(value) {
                    if (!value) {
                      return;
                    }

                    return Number(value) < MIN_EDIT_BOOKING_HOURS
                      ? t("page.bookings.create-or-edit.form.section.booking-restrictions.edit-booking.error.min")
                      : undefined;
                  },
                },
              }}
              className="min-w-12 max-w-20"
            />
            <label>{t("page.bookings.create-or-edit.form.section.booking-restrictions.edit-booking.label.2")}</label>
          </div>
          <div className="flex flex-col flex-wrap justify-center gap-x-2 gap-y-0.5">
            <label>{t("page.bookings.create-or-edit.form.section.booking-restrictions.available-from.label")}</label>
            <FormScheduleInput<CreateOrEditAssetFormValues, "availableFrom">
              name="availableFrom"
              min={minScheduled}
              canEdit
              labelWhenNoValue={t(
                "page.bookings.create-or-edit.form.section.booking-restrictions.available-from.placeholder",
              )}
              rules={{
                validate: {
                  laterThanPublishDate: (date) => {
                    if (!date) {
                      return undefined;
                    }

                    const publishAt = form.getValues("publishAt");
                    if (!publishAt) {
                      return undefined;
                    }

                    return date < publishAt
                      ? t(
                          "page.bookings.create-or-edit.form.section.booking-restrictions.available-from.validation.must-be-later-than-publish",
                        )
                      : undefined;
                  },
                },
              }}
            />
          </div>
          <div className="flex flex-col flex-wrap justify-center gap-x-2 gap-y-0.5">
            <label>{t("page.bookings.create-or-edit.form.section.booking-restrictions.unpublish.label")}</label>
            <FormScheduleInput<CreateOrEditAssetFormValues, "unpublishAt">
              name="unpublishAt"
              min={minScheduled}
              canEdit
              labelWhenNoValue={t(
                "page.bookings.create-or-edit.form.section.booking-restrictions.unpublish.placeholder",
              )}
              rules={{
                validate: {
                  laterThanMin: (date) => {
                    if (!date) {
                      return undefined;
                    }

                    return date < minScheduled
                      ? t(
                          "page.bookings.create-or-edit.form.section.booking-restrictions.unpublish.validation.must-be-in-future",
                        )
                      : undefined;
                  },
                  laterThanPublishDate: (date) => {
                    if (!date) {
                      return undefined;
                    }

                    const publishAt = form.getValues("publishAt");
                    if (!publishAt) {
                      return undefined;
                    }

                    return date < publishAt
                      ? t(
                          "page.bookings.create-or-edit.form.section.booking-restrictions.unpublish.validation.must-be-later-than-publish",
                        )
                      : undefined;
                  },
                  laterThanAvailableFrom: (date) => {
                    if (!date) {
                      return undefined;
                    }

                    const availableFrom = form.getValues("availableFrom");
                    if (!availableFrom) {
                      return undefined;
                    }

                    return date < availableFrom
                      ? t(
                          "page.bookings.create-or-edit.form.section.booking-restrictions.unpublish.validation.must-be-later-than-available-from",
                        )
                      : undefined;
                  },
                  canBookSameDayMinDaysRestriction: (date) => {
                    if (!date) {
                      return undefined;
                    }

                    const CreateOrEditAssetFormValues = form.getValues();
                    if (!CreateOrEditAssetFormValues.canBookSameDay) {
                      return undefined;
                    }

                    const availableFrom = CreateOrEditAssetFormValues.availableFrom;
                    const publishAt = CreateOrEditAssetFormValues.publishAt;
                    if (!availableFrom || !publishAt) {
                      return undefined;
                    }

                    if (date < addDays(availableFrom, 2) || date < addDays(publishAt, 2)) {
                      return t(
                        "page.bookings.create-or-edit.form.section.booking-restrictions.unpublish.validation.min-days-when-not-book-same-day",
                      );
                    }
                  },
                },
              }}
            />
          </div>
          <FormCheckbox<CreateOrEditAssetFormValues, "requireBookingReason">
            name="requireBookingReason"
            label={t("page.bookings.create-or-edit.form.section.booking-restrictions.booking-reason.label")}
          />
        </FormContent>

        {/* Publish settings section */}
        {!isPublished && (
          <FormContent maxWidth="sm">
            {/* Publish Section */}
            <span className="text-headline3 leading-old-headline4">
              {t("page.bookings.create-or-edit.form.section.publish")}
            </span>
            <FormScheduleInput
              name="publishAt"
              min={minScheduled}
              canEdit={!isEditMode || !isPublished}
              rules={{
                validate: {
                  laterThanMin: (date) => {
                    if (!date) {
                      return undefined;
                    }

                    return date < minScheduled
                      ? t("page.bookings.create-or-edit.form.section.publish.publish.validation.must-be-in-future")
                      : undefined;
                  },
                },
              }}
            />
          </FormContent>
        )}

        {/* Actions */}
        <div className="flex justify-between gap-2">
          {isEditMode && (
            <Button styling="danger" onClick={() => openDeleteModal(assetId!)}>
              {t("common.action.delete")}
            </Button>
          )}
          <div className="flex gap-2">
            <Button styling="secondary" disabled={isSubmitting} onClick={cancelModalHandler.setTrue}>
              {t("common.action.cancel")}
            </Button>
            <Button styling="primary" data-testid="submit-asset-btn" type="submit" isLoading={isSubmitting}>
              {t("common.action.save")}
            </Button>
          </div>
        </div>
      </Form>
      <ExtraSpecificationsModal
        isOpened={isExtraSpecificationsModalOpened}
        onOpenChange={extraSpecificationsModalOpenHandler.set}
        availableSpecifications={availableSpecifications}
        onAddSpecifications={onAddSpecifications}
      />
      <ConfirmModal
        isOpened={isCancelModalOpen}
        data-testid="cancel-modal"
        title={t("page.bookings.create-or-edit.cancel-modal.title")}
        description={t("page.bookings.create-or-edit.cancel-modal.description")}
        shouldCloseOnEsc
        onOpenChange={cancelModalHandler.set}
        onReject={cancelModalHandler.setFalse}
        rejectBtnProps={{
          text: t("common.action.cancel"),
        }}
        onResolve={() => void navigate(routes.bookings.list({ slug }))}
        resolveBtnProps={{
          text: t("common.action.confirm"),
        }}
        isLoading={false}
      />
      <ConfirmModal
        isOpened={bookingsToBeCancelled !== null}
        title={t("page.bookings.create-or-edit.edit-open-time-modal.title")}
        description={t("page.bookings.create-or-edit.edit-open-time-modal.description", {
          count: bookingsToBeCancelled!,
        })}
        isLoading={false}
        theme="danger"
        onOpenChange={(state) => {
          if (!state) {
            setBookingsToBeCancelled(null);
          }
        }}
        onReject={() => setBookingsToBeCancelled(null)}
        rejectBtnProps={{
          "data-testid": "confirm-edit-open-time-cancel",
        }}
        onResolve={async () => {
          setBookingsToBeCancelled(null);
          await onSubmit(form.getValues());
        }}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "confirm-edit-open-time-confirm",
        }}
        shouldCloseOnEsc
        data-testid="confirm-edit-open-time-modal"
      />
      <DeleteModal
        title={t("page.bookings.delete-asset.modal.title")}
        description={
          futureBookings === 0
            ? t("page.bookings.delete-asset.modal.description.no-bookings")
            : t("page.bookings.delete-asset.modal.description", { count: futureBookings })
        }
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
};
