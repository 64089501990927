import * as React from "react";
import type { FlyoutProps } from "victory";
import * as V from "victory";

import { chartTheme, dataColors } from "../theme";

interface Props {
  size: number;
  slices: { amount: number; label: string; hoverLabel?: React.ReactNode }[];
  hoverLabel?: (data: { label: string; amount: number }) => React.ReactNode;
}

const colors = [
  dataColors.primary,
  dataColors.background.chart,
  dataColors.benchmark,
  dataColors.tickColor,
  dataColors.background.tooltip,
  dataColors.benchmark,
  dataColors.benchmarkDark,
];

export function PieChart({ slices, size, hoverLabel }: Props): React.ReactNode {
  return (
    <div className="flex flex-wrap gap-4">
      <div
        className="relative"
        style={{
          width: size + "px",
        }}
      >
        <V.VictoryPie
          theme={chartTheme}
          width={size}
          height={size}
          radius={size / 3}
          style={{
            data: {
              fill: (d) => d.datum.color,
            },
          }}
          data={slices
            .map((slice, i) => ({ color: colors[i % colors.length], y: slice.amount, x: slice.label }))
            .filter((d) => d.y > 0)}
          labelComponent={
            <CustomLabel
              tooltip={
                hoverLabel
                  ? {
                      text: "",
                      flyoutComponent: (
                        <PieChartHoverLabel
                          chartSize={size}
                          render={(x) => hoverLabel({ label: x.x || "", amount: x.y || 0 })}
                        />
                      ),
                    }
                  : undefined
              }
            />
          }
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        {slices.map((slice, i) => (
          <div key={`slice_${i}`} className="flex w-full items-center justify-start gap-2">
            <div className="mt-0.5 size-3 rounded-sm" style={{ backgroundColor: colors[i % colors.length] }} />
            <span className="text-body text-black">{slice.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function PieChartHoverLabel({
  chartSize,
  render,
  ...props
}: FlyoutProps & {
  chartSize: number;
  render: (value: { y?: number; x?: string }) => React.ReactNode;
}): React.ReactNode {
  const { x, y } = props;

  return (
    <foreignObject
      x={
        // eslint-disable-next-line no-nested-ternary
        x ? (x > chartSize / 2 ? x / 1.3 : x * 1.3) : undefined
      }
      y={
        // eslint-disable-next-line no-nested-ternary
        y ? (y > chartSize / 2 ? y / 1.3 : y * 1.3) : undefined
      }
      width="400"
      height="200"
      style={{ overflow: "visible" }}
    >
      <div className="relative inline-block rounded-2px bg-grey-900 p-2 text-caption text-white">
        {render(props.datum || {})}
      </div>
    </foreignObject>
  );
}

class CustomLabel extends React.Component<{ tooltip?: V.VictoryTooltipProps }> {
  static defaultEvents = V.VictoryTooltip.defaultEvents;

  render() {
    const { tooltip, ...props } = this.props;

    return <g>{this.props.tooltip ? <V.VictoryTooltip {...props} {...this.props.tooltip} /> : null}</g>;
  }
}
