import iconNarrowDown from "assets/icons/arrow-narrow-down.svg";
import iconNarrowRight from "assets/icons/arrow-narrow-right.svg";
import iconArrowsUp from "assets/icons/arrows-up.svg";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useTranslation } from "react-i18next";

type FahWidgetScoreReferenceLabelProps = {
  currValue: number;
  referenceValue: number;
};

export const FahWidgetScoreReferenceLabel = ({
  currValue,
  referenceValue,
}: FahWidgetScoreReferenceLabelProps): React.ReactNode => {
  const { t } = useTranslation();

  if (!currValue && !referenceValue) {
    return (
      <Label theme="blue" isUppercase={false}>
        <Icon name={iconNarrowRight} />
        {t("common.label.not-applicable")}
      </Label>
    );
  }

  const difference = currValue - referenceValue;
  let label = t("component.analytics-widget.fah-score.trend.neutral.description");
  let icon: string = iconNarrowRight;
  let theme: "green" | "yellow" | "blue" = "blue";
  if (difference > 0) {
    icon = iconArrowsUp;
    theme = "green";
    label = t("component.analytics-widget.fah-score.trend.increased.description", {
      difference: difference.toFixed(1),
    });
  } else if (difference < 0) {
    icon = iconNarrowDown;
    theme = "yellow";
    label = t("component.analytics-widget.fah-score.trend.decreased.description", {
      difference: difference.toFixed(1),
    });
  }

  return (
    <Label isUppercase={false} {...{ theme }}>
      <div className="flex items-center gap-2 text-center">
        <Icon name={icon} />
        {label}
      </div>
    </Label>
  );
};
