import type { ImageDto } from "api/types";

import { useApiClient } from "./useApi";

type ImageSize = "sm" | "md" | "lg" | "intrinsic";

export function useImageResolver(): (image: ImageDto, size: "sm" | "md" | "lg" | "intrinsic") => string {
  const api = useApiClient();

  return (image, size) => `${api.baseUrl}/api/v1/images/${image.id}/${imageSizeMap[size]}`;
}

const imageSizeMap: Record<ImageSize, string> = {
  sm: "90x90",
  md: "180x180",
  lg: "360x360",
  intrinsic: "large",
};
