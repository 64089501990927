import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import type React from "react";

type VideoModalProps = ModalBaseProps & {
  videoName: string | undefined;
  videoSrc: string;
};

export function VideoModal({ isOpened, onOpenChange, videoName, videoSrc }: VideoModalProps): React.ReactNode {
  return (
    <Modal.Root title={videoName} size="lg" {...{ isOpened, onOpenChange }}>
      <video className="mx-auto aspect-video w-full rounded-md bg-black object-contain" src={videoSrc} controls />
    </Modal.Root>
  );
}
