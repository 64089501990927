import * as V from "victory";

import { chartTheme, dataColors } from "../theme";

interface Props {
  size: number;
  value: number;
  referenceValue?: number;
}

export function DonutChart({ value, referenceValue, size }: React.PropsWithChildren<Props>): React.ReactNode {
  return (
    <div className="relative">
      <V.VictoryGroup width={size} height={size}>
        <V.VictoryPie
          theme={chartTheme}
          style={{ data: { fill: ({ datum }: any) => datum.color } }}
          radius={size / 2}
          innerRadius={size / 2.8}
          data={getSlices(value, referenceValue)}
          labels={[]}
        />
      </V.VictoryGroup>
      <div className="absolute left-1/2 top-1/2 max-w-full -translate-x-1/2 -translate-y-1/2">
        <span className="text-headline1 text-aop-dark-blue-500">{value}%</span>
      </div>
    </div>
  );
}

function getSlices(primary: Props["value"], benchmark: Props["referenceValue"]) {
  const primarySlice = {
    y: primary,
    color: dataColors.primary,
  };

  if (benchmark && benchmark > primary) {
    return [
      primarySlice,
      {
        y: benchmark - primary,
        color: dataColors.benchmark,
      },
      {
        y: 100 - benchmark,
        color: dataColors.background.chart,
      },
    ];
  } else {
    return [
      primarySlice,
      {
        y: 100 - primary,
        color: dataColors.background.chart,
      },
    ];
  }
}
