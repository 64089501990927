export type TicketSorting =
  | "unread"
  | "newest"
  | "oldest"
  | "leastRecentActivity"
  | "mostRecentActivity"
  | "mostRecentReminder"
  | undefined;

export type TicketTabs = "new" | "inProgress" | "closed" | "reminder";
export type ResidentTicketTabs = "open" | "closed" | "recent";

export const NEW_TICKET_TAB = "new" as const satisfies TicketTabs;
export const IN_PROGRESS_TICKET_TAB = "inProgress" as const satisfies TicketTabs;
export const CLOSED_TICKET_TAB = "closed" as const satisfies TicketTabs;
export const REMINDER_TICKET_TAB = "reminder" as const satisfies TicketTabs;

export type TicketFilterParams =
  | "StatusIds"
  | "CategoryIds"
  | "Ratings"
  | "AssigneeIds"
  | "AddressTypes"
  | "UnreadOnly"
  | "Filter"
  | "Search"
  | "ResidentFocusMode";

export type PortfolioTicketFilterParams =
  | "Ratings"
  | "UnreadOnly"
  | "Search"
  | "Filter"
  | "ResidentFocusMode"
  | "ProjectIds"
  | "AssignedToEmails";

export type TicketFilterTypes = string | string[] | number[] | boolean | undefined;

export const ticketRatingValues = [-1, 1, 2, 3, 4, 5];
export const ticketVisibilityValues = ["private", "collective"];
export const ticketSortingValues = [
  "unread",
  "newest",
  "oldest",
  "leastRecentActivity",
  "mostRecentActivity",
  "mostRecentReminder",
] as const satisfies readonly TicketSorting[];

export type TicketHistoryType = "users" | "addresses";

export const RESIDENT_OPEN_TICKET_TAB = "open" as const satisfies ResidentTicketTabs;
export const RESIDENT_CLOSED_TICKET_TAB = "closed" as const satisfies ResidentTicketTabs;
export const RESIDENT_RECENT_TICKET_TAB = "recent" as const satisfies ResidentTicketTabs;

export type ResidentTicketFilterParams =
  | "Search"
  | "StatusIds"
  | "CategoryIds"
  | "ReporterIds"
  | "RepairRequestFilterType";

export type ResidentTicketFilterTypes = string | string[] | undefined;
export type RepairRequestFilterTypes = "both" | "onlyCollective" | "onlyPrivate";
export const residentTicketTypeValues: ResidentTicketFilterTypes[] = ["both", "onlyCollective", "onlyPrivate"];
type ReporterFilterTypes = "mine" | "all";
export const reporterTypeValues: ReporterFilterTypes[] = ["mine", "all"];
