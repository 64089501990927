import type { FeelingAtHomeSurveyQuestionDto } from "api/types";
import { Button } from "components/Button/Button";
import { useTranslation } from "react-i18next";

import {
  SurveyQuestionGroupAnswerCardHeader,
  SurveyQuestionGroupAnswerCardLastResponseSection,
  SurveyQuestionGroupAnswerCardRating,
} from "./SurveyQuestionGroupAnswerCard";

interface SurveyQuestionGroupAnswerCardProps {
  order: number;
  question: FeelingAtHomeSurveyQuestionDto;
  onClickViewDetails: () => void;
}

export function FeelingAtHomeSurveyQuestionGroupCard({
  order,
  question,
  onClickViewDetails,
}: SurveyQuestionGroupAnswerCardProps): React.ReactNode {
  const { t } = useTranslation();

  const avgRating = question.responses.reduce((acc, curr) => acc + curr.rating, 0) / question.responses.length;

  return (
    <div className="flex w-full flex-col justify-between gap-8 rounded-lg bg-white p-4 md:p-6">
      <SurveyQuestionGroupAnswerCardHeader
        questionTitle={question.ratingQuestionText}
        category={question.category}
        {...{ order }}
      />

      <div className="flex w-full flex-col items-center justify-center gap-2 px-10 md:flex-row md:gap-10">
        <SurveyQuestionGroupAnswerCardRating rating={avgRating} />
      </div>

      <div className="flex w-full items-center justify-between gap-6">
        <SurveyQuestionGroupAnswerCardLastResponseSection
          questionTitle={question.openQuestionText}
          lastResponse={question.responses[0].answer}
        />

        <Button styling="tertiary" size="sm" onClick={onClickViewDetails}>
          {t("component.surveys-question-group-answer.button.details")}
        </Button>
      </div>
    </div>
  );
}
