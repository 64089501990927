import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { FileUploadButton } from "components/Button/FileUploadButton";
import { IconButton } from "components/Button/IconButton";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { parseFileContent } from "helpers/file-parse";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnDropFiles } from "hooks/useOnDropFiles";
import { useOnPaste } from "hooks/useOnPaste";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DataTable } from "../components/DataTable";
import type { Data } from "../helpers/parser";
import type { Column } from "../helpers/parser";
import { parseData } from "../helpers/parser";
import { downloadExcel, parseExcel } from "../helpers/sheet";
import { validate } from "../helpers/validation";

export function AddressBulkUpload(): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const api = useApi();
  const [isUploading, setIsUploading] = useState(false);
  const [isParsingFile, setIsParsingFile] = useState(false);
  const [data, setData] = useState<Data<NonNullable<typeof columns>>>();
  const { mutateAsync: uploadAddressAsync } = useMutation({ mutationFn: api.postAddressesV1 });
  const {
    data: buildings,
    isPending: isLoadingBuildings,
    error: buildingsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BUILDING_LIST(projectId),
    queryFn: () => api.getBuildingsV1(),
    select: (x) => commonAPIDataSelector(x).items,
  });

  const columns = useMemo(() => {
    if (!isDefined(buildings)) {
      return undefined;
    }

    const columns = [
      {
        name: "Building",
        alias: ["gebouw", "gebouw naam", "building name"],
        rules: {
          required: t("page.address-bulk-upload.validation.building.required"),
          oneOf: {
            error: t("page.address-bulk-upload.validation.building.not-one-of"),
            values: buildings.map((x) => x.name),
          },
        },
      },
      {
        name: "Street",
        alias: ["straatnaam", "straat", "street name", "streetname"],
        rules: { required: t("page.address-bulk-upload.validation.street-name.required") },
      },
      {
        name: "House number",
        alias: ["huisnummer", "housenumber"],
        rules: {
          required: t("page.address-bulk-upload.validation.house-number.required"),
          match: {
            error: t("page.address-bulk-upload.validation.house-number.is-not-number"),
            regex: /^\d+$/,
          },
        },
      },
      {
        name: "House number suffix",
        alias: ["house number suffix", "huisnummertoevoeging"],
      },
      {
        name: "Postal code",
        alias: ["postcode", "zipcode", "zip"],
        rules: { required: t("page.address-bulk-upload.validation.postal-code.required") },
      },
      {
        name: "City",
        alias: ["stad", "stad naam", "city name", "plaats", "plaatsnaam"],
        rules: { required: t("page.address-bulk-upload.validation.city.required") },
      },
      { name: "Type", alias: ["woning type", "address type", "eigenaar"] },
      { name: "Floor", alias: ["verdieping", "floor", "level"] },
    ] as const satisfies readonly Column[];

    return columns;
  }, [t, buildings]);

  async function upload() {
    if (!data) {
      throw new Error("Data missing");
    }

    if (!dataState?.valid) {
      throw new Error("Data is invalid");
    }

    try {
      setIsUploading(true);

      for (const row of data) {
        const idx = data.indexOf(row);
        updateValue(idx, "uploadStatus", "UPLOADING");
        const rowData = row as { [k in NonNullable<typeof columns>[number]["name"]]: string };
        try {
          const houseNumber = Number(rowData["House number"]);
          if (isNaN(houseNumber)) {
            throw new Error("Invalid house number");
          }

          await uploadAddressAsync({
            buildingId: mapBuildingValue(rowData.Building)!,
            city: rowData.City,
            floor: rowData.Floor || undefined,
            housenumber: Number(rowData["House number"]),
            housenumberSuffix: rowData["House number suffix"] || undefined,
            postalCode: rowData["Postal code"],
            streetName: rowData.Street,
            type: rowData.Type || undefined,
          });
          updateValue(idx, "uploadStatus", "SUCCESS");
        } catch (error) {
          updateValue(idx, "uploadStatus", "ERROR");
        }
      }
    } finally {
      setIsUploading(false);
    }
  }

  function mapBuildingValue(buildingName: string) {
    if (!buildings) {
      throw new Error("No buildings available yet");
    }

    for (const building of buildings) {
      if (buildingName.toLowerCase() === building.name.toLowerCase()) {
        return building.id;
      }
    }
  }

  function updateValue(row: number, column: string, value: string) {
    setData((oldData) => {
      if (!oldData) {
        throw new Error("No existing data");
      }

      const newData = [...oldData];
      const rowNumber = Number(row);
      const oldRow = oldData[rowNumber];
      const newRow = { ...oldRow, [column]: value };
      newData.splice(rowNumber, 1, newRow);

      return newData;
    });
  }

  function removeRow(row: number) {
    setData((oldData) => {
      if (!oldData) {
        return;
      }

      const newData = [...oldData];
      newData.splice(row, 1);

      // No more data remaining, reset form
      if (newData.length === 0) {
        return undefined;
      }

      return newData;
    });
  }

  const loadCsv = useCallback(
    async (...args: Parameters<typeof parseExcel>) => {
      try {
        setIsParsingFile(true);
        const rows = await parseExcel(...args);
        setData(parseData(rows as object[], columns!));
      } finally {
        setIsParsingFile(false);
      }
    },
    [columns],
  );

  useOnDropFiles(
    useCallback(
      async (files) => {
        const file = files?.[0].getAsFile();
        if (file) {
          try {
            const content = await parseFileContent(file);
            await loadCsv(content);
          } catch (error) {
            alert(error);
          }
        }
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  useOnPaste(
    useCallback(
      (data) => {
        void loadCsv(data).catch((error) => alert(`Could not paste data: ${error}`));
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const file = target.files?.[0];

    if (file) {
      try {
        const contents = await parseFileContent(file);
        await loadCsv(contents);
      } catch (error) {
        alert(error);
        target.value = "";
      }
    }
  };

  const dataState = useMemo(() => (data && columns ? validate(columns, data) : undefined), [data, columns]);

  const isLoading = isLoadingBuildings;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = buildingsError;
  if (!columns || error) {
    return <ErrorPage error={error ?? t("page.address-bulk-upload.error.no-columns")} />;
  }

  return (
    <DocumentPaper
      title={t("page.address-bulk-upload.title")}
      theme="minimal"
      actions={
        data ? (
          <div className="flex h-10 items-center">
            <IconButton
              disabled={isUploading}
              title={t("page.address-bulk-upload.reset")}
              onClick={() => setData(undefined)}
            >
              <Icon name={iconX} />
            </IconButton>
          </div>
        ) : (
          <FileUploadButton
            disabled={isUploading}
            id="address_list_btn"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onUploadFile}
          >
            {t("page.address-bulk-upload.button")}
          </FileUploadButton>
        )
      }
    >
      <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
        {data && (
          <div className="flex flex-col gap-8">
            <DataTable
              data={data}
              columns={columns}
              dataState={dataState!}
              onRemoveRow={removeRow}
              onUpdateValue={updateValue}
              disabled={isUploading}
              data-testid="address-data-table"
            />
            <Button
              data-testid="submit-btn"
              onClick={upload}
              isLoading={isUploading}
              disabled={!dataState?.valid || data.every((x) => x.uploadStatus === "SUCCESS")}
            >
              {t("page.address-bulk-upload.submit")}
            </Button>
          </div>
        )}
        {!data && isParsingFile && <FullSizeLoader withPadding />}
        {!data && !isParsingFile && (
          <>
            <p className="max-w-prose whitespace-pre-wrap">{t("page.address-bulk-upload.prose")}</p>
            <Button
              styling="secondary"
              onClick={() =>
                downloadExcel(`address-upload-${sessionUser.project.name}`, [
                  columns.map((x) => x.name),
                  ...buildings.map((x) => [x.name]),
                ])
              }
            >
              {t("page.address-bulk-upload.example-file")}
            </Button>
          </>
        )}
      </div>
    </DocumentPaper>
  );
}
