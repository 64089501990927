import type { SelfUpdateRequest } from "api/types";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { createImageSizeRule, createImageTypeRule, createRequiredStringRule } from "helpers/rules";
import { isValidPhoneNumber } from "helpers/validation";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { userMutations } from "queries/users/mutations";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const MAX_CHARACTERS = 255;

export function ResidentEditSelfPage(): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isCancelModalOpen, cancelModalHandler] = useBool(false);
  const form = useForm<FormValues>({
    defaultValues: {
      firstName: sessionUser.firstName,
      lastName: sessionUser.lastName,
      mobileNumber: sessionUser.phoneNumber,
      email: sessionUser.email,
      occupation: sessionUser.occupation,
      avatar: sessionUser.avatar ? [sessionUser.avatar] : [],
      biography: sessionUser.biography,
    },
  });
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const showFlashToast = useFlashToast();

  const { mutate, isPending: isSubmitting } = userMutations.usePutSelfMutation({
    onError() {
      showFlashToast({ title: t("page.self-edit.submit.error"), type: "error" });
    },
    onSuccess() {
      showFlashToast({ title: t("page.self-edit.submit.success"), type: "success" });
      goBack();
    },
  });

  const project = sessionUser.project;

  async function handleSave(formValues: FormValues) {
    const payload: SelfUpdateRequest = {
      languageId: sessionUser.language.id,
      hideEmail: sessionUser.emailHidden,
      hidePhoneNumber: sessionUser.phoneNumberHidden,
      hideHouseNumber: sessionUser.hideHouseNumber,
      enableChat: sessionUser.chatEnabled,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      biography: formValues.biography,
      phoneNumber: formValues.mobileNumber,
      occupation: formValues.occupation,
    };

    if (formValues.avatar?.[0]) {
      const avatarImage = await uploadFormImage(formValues.avatar?.[0]);

      payload.avatarId = avatarImage?.id;
    }

    mutate(payload);
  }

  function goBack() {
    cancelModalHandler.setFalse();
    void navigate(-1);
  }

  return (
    <DocumentPaper theme="constrained" title={t("page.self-edit.title")}>
      <Form className="flex flex-col" formMethods={form} onSubmit={handleSave}>
        <FormContent>
          <FormField label={t("page.self-edit.form.avatar.label")}>
            <FormImageInput<FormValues, "avatar">
              ref={fileInputRef}
              name="avatar"
              rules={{
                validate: {
                  size: createImageSizeRule({ t }),
                  type: createImageTypeRule({ t }),
                },
              }}
              isAvatarInput
              cropper
            />
          </FormField>

          <FormField label={t("page.self-edit.form.first-name.label")} required>
            <FormInput<FormValues>
              name="firstName"
              placeholder={t("page.self-edit.form.first-name.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.self-edit.form.first-name.label"),
                },
                maxLength: {
                  message: t("components.form.error.max-length", { length: MAX_CHARACTERS }),
                  value: MAX_CHARACTERS,
                },
              }}
            />
          </FormField>
          <FormField label={t("page.self-edit.form.last-name.label")} required>
            <FormInput<FormValues>
              name="lastName"
              placeholder={t("page.self-edit.form.last-name.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.self-edit.form.last-name.label"),
                },
                maxLength: {
                  message: t("components.form.error.max-length", { length: MAX_CHARACTERS }),
                  value: MAX_CHARACTERS,
                },
              }}
            />
          </FormField>
          {!sessionUser.company && (
            <FormField label={t("page.self-edit.form.address")} description={t("page.self-edit.form.address.note")}>
              <p>{sessionUser.address?.location ?? "-"}</p>
            </FormField>
          )}
          {sessionUser.company && (
            <FormField label={t("page.self-edit.form.company")} description={t("page.self-edit.form.company.note")}>
              <p>{sessionUser.company?.name}</p>
            </FormField>
          )}
          {project.type === "companyBased" && (
            <FormField label={t("page.self-edit.form.occupation.label")}>
              <FormInput<FormValues, "occupation">
                name="occupation"
                placeholder={t("page.self-edit.form.occupation.placeholder")}
                type="occupation"
                rules={{
                  maxLength: {
                    message: t("components.form.error.max-length", { length: MAX_CHARACTERS }),
                    value: MAX_CHARACTERS,
                  },
                }}
              />
            </FormField>
          )}
          <FormField label={t("page.self-edit.form.biography.label")}>
            <FormTextArea<FormValues> name="biography" placeholder={t("page.self-edit.form.biography.placeholder")} />
          </FormField>
          <FormField label={t("page.self-edit.form.mobile.label")} description={t("page.self-edit.form.mobile.note")}>
            <FormInput<FormValues, "mobileNumber">
              name="mobileNumber"
              placeholder={t("page.self-edit.form.mobile.placeholder")}
              type="tel"
              inputMode="tel"
              rules={{
                validate: {
                  isValid(value) {
                    value = value?.trim();

                    if (!value) {
                      return;
                    }

                    return isValidPhoneNumber(value) ? undefined : t("components.form.error.invalid-phone-number");
                  },
                },
              }}
            />
          </FormField>
          <FormField
            label={t("page.self-edit.form.email.label")}
            description={t("page.self-edit.form.email.note")}
            required
          >
            <FormInput<FormValues, "email">
              name="email"
              placeholder={t("page.self-edit.form.email.placeholder")}
              type="email"
              inputMode="email"
              disabled
            />
          </FormField>
          <div className="flex gap-6 self-end">
            <Button
              data-testid="self-edit-cancel-btn"
              styling="secondary"
              onClick={() => {
                if (Object.keys(form.formState.dirtyFields).length > 0) {
                  cancelModalHandler.setTrue();
                } else {
                  goBack();
                }
              }}
            >
              {t("common.action.cancel")}
            </Button>
            <Button type="submit" isLoading={isSubmitting || isUploadingImage} data-testid="self-edit-submit-btn">
              {t("page.self-edit.form.submit")}
            </Button>
          </div>
        </FormContent>
      </Form>
      <ConfirmModal
        title={t("page.self-edit.cancel-modal.title")}
        description={t("page.self-edit.cancel-modal.description")}
        isLoading={false}
        onReject={cancelModalHandler.setFalse}
        onOpenChange={cancelModalHandler.set}
        rejectBtnProps={{
          "data-testid": "cancel-modal-cancel",
        }}
        onResolve={goBack}
        resolveBtnProps={{
          text: t("common.action.ok"),
          "data-testid": "cancel-modal-confirm",
        }}
        isOpened={isCancelModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-modal"
      />
    </DocumentPaper>
  );
}

interface FormValues {
  avatar: FormImage[];
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  occupation?: string;
  biography: string;
}
